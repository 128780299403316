import { Injectable } from '@angular/core'
import { CreateDevicePostData, Device, DeviceInfo, DeviceMetaData } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { DeviceManagementHttpService } from '../backend/device-management-http.service'

@Injectable({
  providedIn: 'root',
})
export class DeviceStore {
  private organisationSwitchBegunSubscription: Subscription
  private organisationsListedSubscription: Subscription
  private organisationSwitchedSubscription: Subscription

  private _devices$: BehaviorSubject<DeviceInfo[]> = new BehaviorSubject([])
  public readonly devices$: Observable<DeviceInfo[]> = this._devices$.asObservable()

  public storeInitialised$ = new BehaviorSubject<boolean>(true)
  private devicesInitialised = false

  constructor(
    private proficloud: ProficloudService,
    private deviceManagementHttp: DeviceManagementHttpService
  ) {
    this.organisationSwitchBegunSubscription = this.proficloud.organisationSwitchBegun$.subscribe(() => {
      // TODO
    })

    this.organisationsListedSubscription = this.proficloud.organisationsListed$.subscribe(() => {
      if (!this.devicesInitialised) {
        this.loadDevices(this.proficloud.currentOrganisation.organizationId)
      }
    })

    this.organisationSwitchedSubscription = this.proficloud.organisationSwitched$.subscribe((organisation) => {
      // Maybe
      this.loadDevices(organisation.organizationId)
    })
  }

  public refreshDevices(organisationId: string) {
    this.getOrganisationDevices(organisationId).subscribe((res) => {
      this._devices$.next(res.content)
    })
  }

  private loadDevices(organisationId: string) {
    this.getOrganisationDevices(organisationId).subscribe((res) => {
      this._devices$.next(res.content)
      this.devicesInitialised = true
      this.storeInitialised$.next(false)
    })
  }

  private wrapSuccessWithDevicesRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.loadDevices(this.proficloud.currentOrganisation.organizationId)
          res.next(response)
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  private getOrganisationDevices(organisationId: string) {
    return this.deviceManagementHttp.getOrganisationDevices(organisationId)
  }

  // Note: We might want to fetch this and then push it down a stream but I'm not sure if that makes sense given that it could be for multiple different devices
  public getDeviceLogs(fromDate: Date, toDate: Date, device: DeviceInfo) {
    return this.deviceManagementHttp.fetchDeviceLogs(fromDate, toDate, device)
  }

  public addDeviceLogEntry(device: DeviceInfo, entry: { message: string } = { message: 'Missing message' }) {
    return this.deviceManagementHttp.addDeviceLogEntry(device, entry)
  }

  public createDevice(createData: CreateDevicePostData, virtual: boolean) {
    return this.wrapSuccessWithDevicesRefresh(this.deviceManagementHttp.createDevice(createData, virtual))
  }

  public deleteDevice(device: Device) {
    return this.wrapSuccessWithDevicesRefresh(this.deviceManagementHttp.deleteDevice(device))
  }

  public updateDeviceDetail(endpointId: string, deviceFields: DeviceMetaData) {
    return this.wrapSuccessWithDevicesRefresh(this.deviceManagementHttp.updateDeviceDetail(endpointId, deviceFields))
  }

  public getAllDevicesMetaData() {
    return this.deviceManagementHttp.getAllDevicesMetaData()
  }

  public getDeviceServicesInfo() {
    return this.deviceManagementHttp.getDeviceServicesInfo()
  }

  public getDeviceTypeInfo() {
    return this.deviceManagementHttp.getDeviceTypeInfo()
  }

  public getLastDeviceHealthStatus(endpointIds: string, metricName: string) {
    return this.deviceManagementHttp.getLastDeviceHealthStatus(endpointIds, metricName)
  }
}
