import { DecoratedClass } from '../services/tracing/tracing.interfaces'

/**
 * The @in decorator - record all component inputs
 *
 * Writes an object with {propertyName, options} to the class' componentInputs[] array.
 */
export function input(options?: any) {
  return function innerInput(target: DecoratedClass, propertyName: string) {
    if (!target.componentInputs) {
      target.componentInputs = []
    }
    target.componentInputs.push({ propertyName, options })
  }
}
