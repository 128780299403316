<div class="button-dropdown-container">
  <pc-button
    [size]="size === 'minimal' ? 'minimal' : 'small'"
    [wide]="wide"
    variant="secondary"
    [inputID]="inputID"
    [noUppercase]="true"
    (btnClick)="toggleExpansion()"
    (clickOutside)="clickedOutside()">
    <flex-row>
      <!-- This is where the left icon would go, as in the DMS filter -->
      @if (!characterLimit) {
        <div style="flex-grow: 1; text-align: center">
          {{ selectedItem?.label || placeholder }}
        </div>
      } @else {
        <div style="flex-grow: 1; text-align: center">
          {{ selectedItem?.label || placeholder | middleTruncate: [characterLimit] }}
        </div>
      }
      <app-icon [name]="optionsVisible ? 'chevronUp' : 'chevronDown'" style="width: 20px" color="var(--primary)"> </app-icon>
    </flex-row>
  </pc-button>

  @if (optionsVisible) {
    <div [class]="size === 'minimal' ? 'sort-options-minimal' : 'sort-options'">
      <app-proficloud-drop-down
        id="options"
        [left]="!wideRight"
        [wideRight]="wideRight"
        [size]="size"
        [items]="dropdownOptions"
        (onItemClicked)="onSelect($event)">
      </app-proficloud-drop-down>
    </div>
  }
</div>
