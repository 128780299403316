export const tracingSettings = {
  key: 'tracingSettings',
  location: '/src/app/modules/shared/services/tracing/tracing.settings.ts',
  classSettings: {
    TracingService: {
      mute: true,
    },
    AnimationService: {
      mute: false,
    },
    UiService: {
      mute: true,
    },
    ReactiveLoggingService: {
      mute: true,
    },
    CssDirective: {
      mute: true,
    },
    MonacoService: {
      mute: false,
    },
    ProficloudService: {
      mute: false,
      color: '#d04a08',
    },
    AppComponent: {
      mute: false,
      color: '#fceb4b',
    },
    MonacoEditorComponent: {
      mute: false,
      color: '#afa57c',
    },
    CoreService: {
      mute: false,
      color: '#f6557f',
    },
    DeviceListComponent: {
      mute: false,
      color: '#29b312',
    },
    HyperService: {
      mute: true,
      color: '#c9a2f2',
    },
    ReactiveDataEditorComponent: {
      mute: false,
      color: '#2dbdf2',
    },
    HyperInputComponent: {
      mute: false,
      color: '#bf2d84',
    },
    AppService: {
      mute: false,
      color: '#744996',
    },
    AddDeviceModalComponent: {
      mute: false,
      color: '#e7fa3c',
    },
    AuthGuardService: {
      mute: false,
      color: '#38a694',
    },
    AuthenticationScreenComponent: {
      mute: false,
      color: '#393cdd',
    },
    ReactiveToastComponent: {
      mute: false,
      color: '#0fcee2',
    },
    ProficloudLogoComponent: {
      mute: false,
      color: '#6d6bc4',
    },
  },
}
