@if (app.environment.frontend !== 'production' || config.showInProduction) {
  <flex-row class="informationBanner">
    <flex-col>
      <div class="title">{{ config.title | translate }}</div>
      <div class="message">{{ config?.message ?? '' | translate }}</div>
    </flex-col>
    <flex-col style="position: absolute; padding-left: max(40.1%, 550px); padding-top: 11px">
      @if (config.buttonVisibility) {
        <pc-button variant="secondary" size="small" [text]="config?.buttonText ?? '' | translate" (btnClick)="openLink()">weiter</pc-button>
      }
    </flex-col>
    <flex-col style="display: flex; margin-left: auto">
      @if (config.closeable) {
        <app-icon name="cross" class="closeIcon" [color]="'var(--white)'" (click)="closeClick.next(); $event.stopPropagation()"></app-icon>
      }
    </flex-col>
  </flex-row>
}
