@if (form | inputIsActive: config) {
  <mat-form-field class="input-textarea" [class.input-textarea--fit-width]="variant === 'fit-width'" [class.input-textarea--small]="size === 'small'">
    <mat-label>{{ placeholder }}</mat-label>
    <textarea
      [id]="inputID"
      matInput
      placeholder="{{ placeholder }}"
      rows="5"
      style="resize: none; height: 100px"
      (input)="inputFieldChanged($event)"
      [formControl]="control"></textarea>
    @if (control?.errors && control?.touched) {
      <app-icon class="input-textarea__icon" [class.input-textarea__icon--small]="size === 'small'" [color]="'var(--error-red)'" name="warning" matSuffix>
      </app-icon>
    }
    @if (!control?.errors && control?.touched) {
      <app-icon
        class="input-textarea__icon input-textarea__icon--reset"
        [class.input-textarea__icon--small]="size === 'small'"
        color="var(--primary)"
        name="cross"
        matSuffix
        (click)="resetInput()"></app-icon>
    }
  </mat-form-field>
}
