import { Component, EventEmitter, Input, Output } from '@angular/core'
import { InformationBannerConfig } from '@services/proficloud.interfaces'
import { AppService } from 'src/app/app.service'

@Component({
  selector: 'pc-information-banner',
  templateUrl: './proficloud-information-banner.component.html',
  styleUrls: ['./proficloud-information-banner.component.scss'],
})
export class ProficloudInformationBannerComponent {
  @Input() config: InformationBannerConfig

  @Output() closeClick = new EventEmitter<void>()

  constructor(public app: AppService) {}

  openLink() {
    if (this.config.buttonLink) {
      window.location.href = this.config.buttonLink
    }
  }
}
