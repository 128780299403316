import { Component, Input } from '@angular/core'

@Component({
  selector: 'pc-warning-banner',
  templateUrl: './pcwarning-banner.component.html',
  styleUrls: ['./pcwarning-banner.component.scss'],
})
export class PCWarningBannerComponent {
  @Input() text = ''
}
