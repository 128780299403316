<flex-col class="container">
  <div class="topBar">
    <!-- Logo -->
    <div class="topBarLeft">
      <app-proficloud-logo></app-proficloud-logo>
    </div>
  </div>

  <div class="bottom">
    <flex-col class="form">
      <div class="title">{{ 'SHARED.RESET_PASSWORD_TITLE' | translate }}</div>
      <div class="subTitle">{{ 'SHARED.RESET_PASSWORD_SUBTITLE' | translate }}</div>
      <flex-col class="inputs">
        <form [formGroup]="resetPasswordForm">
          @for (field of resetPasswordFields; track field) {
            <div class="input">
              @if (field.type === 'text') {
                <pc-input-textfield [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-textfield>
              }
              @if (field.type === 'password') {
                <pc-input-password [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-password>
              }
            </div>
          }
          @if (resetPasswordForm.get('new-password')?.errors || resetPasswordForm.get('confirm-password')?.errors) {
            @if (resetPasswordForm.get('new-password')?.errors?.lowerCaseError) {
              <div class="resetpassword-message-error">
                {{ resetPasswordForm.get('new-password')?.errors?.lowerCaseError }}
              </div>
            }
            @if (resetPasswordForm.get('new-password')?.errors?.upperCaseError) {
              <div class="resetpassword-message-error">
                {{ resetPasswordForm.get('new-password')?.errors?.upperCaseError }}
              </div>
            }
            @if (resetPasswordForm.get('new-password')?.errors?.numberError) {
              <div class="resetpassword-message-error">
                {{ resetPasswordForm.get('new-password')?.errors?.numberError }}
              </div>
            }
            @if (resetPasswordForm.get('new-password')?.errors?.specialCharacterError) {
              <div class="resetpassword-message-error">
                {{ resetPasswordForm.get('new-password')?.errors?.specialCharacterError }}
              </div>
            }
            @if (resetPasswordForm.get('new-password')?.errors?.minlength) {
              <div class="resetpassword-message-error">
                {{ 'SHARED.PASSWORD_POLICY_TOO_SHORT' | translate }}
              </div>
            }
            @if (resetPasswordForm.get('new-password')?.errors?.maxlength) {
              <div class="resetpassword-message-error">
                {{ 'SHARED.PASSWORD_POLICY_TOO_LONG' | translate }}
              </div>
            }
            @if (resetPasswordForm.get('confirm-password')?.errors?.passwordNotMatchingError) {
              <div class="resetpassword-message-error">
                {{ resetPasswordForm.get('confirm-password')?.errors?.passwordNotMatchingError }}
              </div>
            }
          }
          <div style="width: 100%; margin: 10px 0px">
            <pc-button
              [disabled]="!resetPasswordForm.valid"
              [wide]="true"
              [text]="'SHARED.RESET_PASSWORD_ACTION' | translate"
              (btnClick)="submit()"></pc-button>
          </div>
        </form>
      </flex-col>
    </flex-col>
  </div>
</flex-col>
