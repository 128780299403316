import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(values: any[], ...args: string[][]): any[] {
    args.forEach((arg) => {
      if (arg[0] === 'strContains') {
        if (arg[1] && arg[2]) {
          values = values.filter((v) => v[arg[1]].indexOf(arg[2]) >= 0)
        }
      }

      if (arg[0] === 'isTrue') {
        if (arg[1]) {
          values = values.filter((v) => v[arg[1]])
        }
      }

      if (arg[0] === 'isFalse') {
        if (arg[1]) {
          values = values.filter((v) => !v[arg[1]])
        }
      }

      if (arg[0] === 'propertyEquals') {
        const val = arg[1]
        const property = arg[2]
        if (property.indexOf('.') > 0) {
          // We have a nested object
          values = values.filter((v) => {
            const parts = property.split('.')
            let item
            while (parts.length > 1) {
              item = v[parts[0]]
              parts.splice(0, 1)
            }
            return item[parts[0]] === val
          })
        } else {
          values = values.filter((v) => {
            return v[property] === val
          })
        }
      }
    })

    return values
  }
}
