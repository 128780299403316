<flex-row class="container">
  <label class="switch" [ngClass]="className">
    <input type="checkbox" [(ngModel)]="checked" (change)="toggleHandler(index, checked, key)" [disabled]="disabled" />
    <div
      class="bf"
      [style.background-color]="checked ? colour : 'var(--background-content)'"
      [style.border]="originalColour ? '3px solid ' + originalColour : ''"
      [style.top]="originalColour ? '-6px' : ''">
      &nbsp;
    </div>
    <div class="slider round"></div>
  </label>
  <div class="content">
    <ng-content></ng-content>
  </div>
</flex-row>
