import { Pipe, PipeTransform } from '@angular/core'
// translation
import isoCodeList from 'src/assets/i18n/isocodelist.json'
import { IsoLangs, langKey } from '../services/i18n/translation.service'

@Pipe({
  name: 'languageName',
})
export class TranslIsocodePipe implements PipeTransform {
  transform(isoCode: string): string {
    for (const locale in isoCodeList.isoLangs) {
      if (locale === isoCode) {
        return (isoCodeList.isoLangs as IsoLangs)[locale as langKey].nativeName
      }
    }

    return 'undefined'
  }
}
