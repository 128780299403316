import { animate, sequence, state, style, transition, trigger } from '@angular/animations'

export const DropDownAnimation = trigger('dropDownMenu', [
  transition(':enter', [style({ height: 0, overflow: 'hidden' }), sequence([animate('50ms', style({ height: '*' }))])]),
  transition(':leave', [style({ height: '*', overflow: 'hidden' }), sequence([animate('50ms', style({ height: 0 }))])]),
])

export const RotateAnimation = trigger('rotatedState', [
  state('default', style({ transform: 'rotate(180deg)' })),
  state('rotated', style({ transform: 'rotate(0deg)' })),
  transition('rotated => default', animate('50ms ease-out')),
  transition('default => rotated', animate('50ms ease-in')),
])

export const RotateAnimationUpward = trigger('rotatedUpwardState', [
  state('default', style({ transform: 'rotate(0deg)' })),
  state('rotated', style({ transform: 'rotate(180deg)' })),
  transition('rotated => default', animate('50ms ease-out')),
  transition('default => rotated', animate('50ms ease-in')),
])
