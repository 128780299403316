import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core'
import { UiService } from '@services/ui/ui.service'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('clickOutside')
  clickOutside: EventEmitter<any> = new EventEmitter()

  // There are some pesky elements which the browser feels are not on the DOM anywhere...
  // For example the Material Calendar year picker items
  ignoreClasses = ['mat-calendar-body-cell-content']

  constructor(
    private elementRef: ElementRef,
    private ui: UiService
  ) {
    // If another menu has been opened then force close this one
    this.ui.menuOpened$.subscribe((openedElement) => {
      if (openedElement.nativeElement !== this.elementRef.nativeElement) {
        this.clickOutside.next(null)
      }
    })
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(targetElement: HTMLElement) {
    if (this.ignoreClasses.some((c) => targetElement.classList.contains(c))) {
      return
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement)
    if (!clickedInside) {
      this.clickOutside.next(null)
    }
  }
}
