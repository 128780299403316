// angular
import { HttpClient } from '@angular/common/http'
import { environment } from './../../../../../environments/environment'

import { Injectable } from '@angular/core'
import {
  ClassDoc,
  DataMessage,
  DecoratedClass,
  FunctionEndMessage,
  FunctionErrorMessage,
  FunctionFrame,
  FunctionInfoMessage,
  FunctionStartMessage,
  FunctionSuccessMessage,
  LiveCSS,
  StreamMessage,
  ToastMessage,
} from './tracing.interfaces'
// rx
import { BehaviorSubject, merge, Observable, ReplaySubject, Subject, Subscribable } from 'rxjs'
import { map, scan, tap, withLatestFrom } from 'rxjs/operators'

// app
import { doc } from '../../decorators/doc-decorator'
import { traced } from '../../decorators/trace-decorator'
import { ChangeEvent } from './../../decorators/on-change-funtion'
import { AnimationOutput } from './../animation/animation.service'
import { UtilityService as utility } from './../utility/utility.service'
import { tracingData } from './tracing.data'

import { onChange } from '../../decorators/on-change-funtion'
import { tracked, TrackInfo } from '../../decorators/track-decorator'
import { watched } from '../../decorators/watch-stream-decorator'
import { ExtendedWindow } from '../../shared.interfaces'
import { WatchStreamInfo } from './../../decorators/watch-stream-decorator'
import { tracingSettings } from './tracing.settings'

export class TrackedData {
  original: any

  proxy: any
}

export class RegisteredData {
  proxy: any

  original: any

  propertyName: string

  context: DecoratedClass

  editing: boolean
}

export class RegisteredAnimation {
  stream$: Subscribable<AnimationOutput>

  trigger: string

  definition: object

  context: any
}

export interface AnimationStartEvent {
  eventCategory: string
  animation: RegisteredAnimation
  context: DecoratedClass
}

@Injectable({
  providedIn: 'root',
})
export class TracingService {
  classDoc: ClassDoc = {
    name: 'TracingService',
    location: '/src/app/modules/shared/services/tracing/tracing.service.ts',
    why: `Tracing shit down rocks the fuck`,
  }

  onChange = onChange

  /**
   * ---------------------------------------------
   *  |                                          |
   *  |                   DATA                   |
   *  |                                          |
   *  --------------------------------------------
   */

  represent$ = new Subject()

  @watched({
    when: 'A data has been registered!',
    meta: true,
  })
  dataTracked$ = new ReplaySubject()

  allTrackedData$ = this.dataTracked$.pipe(scan((dataAcc: TrackedData[], trackedData: TrackedData) => [...dataAcc, trackedData], []))

  // experiment: Debug Component
  @watched({
    when: `The debug component is set from somewhere.`,
  })
  debugComponent$ = new BehaviorSubject<DecoratedClass | null>(null)

  // root frame
  stack: FunctionFrame = {
    indent: 1,
    functionName: 'META ROOT FRAME',
    definition: 'so meta - nothing to see here',
    context: this,
    startDateTime: performance.now(),
    startPerformanceTime: performance.now(),
    arguments: [],
    children: [],
    infos: [],
    warnings: [],
  }

  currentFrame: FunctionFrame = this.stack

  stats = {
    totalCallCount: 0,
    totalCallDuration: 0,
  }

  // registered classes! meta Data!
  editingInstances: DecoratedClass[] = []

  // new
  // registered classes! meta Data!
  registeredInstances: DecoratedClass[] = []

  registeredData: RegisteredData[] = []

  registeredAnimations: RegisteredAnimation[] = []

  // editable data, from cmd+click
  liveCssData: LiveCSS[] = []

  // all registered data, services, and components
  namedData: any = {}

  namedServices: any = {}

  namedComponents: any = {}

  namedDirectives: any = {}

  namedPipes: any = {}

  @tracked({
    what: `Generic styles for all your tracing needs`,
    persist: {
      precedence: { dev: 'localstorage', prod: 'code' },
    },
  })
  tracingData: any = tracingData

  @tracked({
    what: `trace settings: what is muted or not`,
    persist: {
      precedence: { dev: 'localstorage', prod: 'code' },
    },
  })
  tracingSettings = tracingSettings

  /**
   * ---------------------------------------------
   *  |                                          |
   *  |          $$$$  Streams......             |
   *  |                                          |
   *  --------------------------------------------
   */

  @watched({
    when: 'whenever a DOM mutation happened',
    meta: true,
  })
  domMutation$ = new Subject()

  /**
   *
   *     The holy trinity
   *
   */

  // streams firing
  stream$ = new Subject<StreamMessage>()

  // animations starting
  animation$ = new Subject<AnimationStartEvent>()

  // data changes
  @watched({
    when: 'when ever data changes and the proxy fires us a message',
    meta: true,
  })
  data$ = new ReplaySubject<DataMessage>()

  // very low level
  @watched({
    when: `an object setter is called`,
    meta: true,
  })
  objectSet$ = new Subject()

  @watched({
    when: `whenever a watch meta-stream fires`,
    silent: true,
  })
  metaStream$ = new Subject<StreamMessage>()

  /**
   * Function Execution
   */

  @watched({
    when: `whenever a function context comes in`,
    meta: true,
  })
  functionContextStart$ = new Subject<FunctionStartMessage>()

  @watched({
    when: `whenever the current frame is changed (either functionContextStart, or functionContextEnd)`,
    meta: true,
  })
  currentFrame$ = new BehaviorSubject<FunctionFrame>(this.currentFrame)

  @watched({
    when: `whenever the functionStart$ is pushed, by decorator`,
    meta: true,
  })
  functionStart$ = new Subject<FunctionStartMessage>()

  @watched({
    when: `whenever the functionInfo$ is pushed, by runFunction()`,
    meta: true,
  })
  functionInfo$ = new Subject<FunctionInfoMessage>()

  @watched({
    when: `whenever the functionWarning$ is pushed, by runFunction()`,
    meta: true,
  })
  functionWarning$ = new Subject<FunctionInfoMessage>()

  @watched({
    when: `whenever the functionSuccess$ is pushed, by runFunction()`,
    meta: true,
  })
  functionSuccess$ = new Subject<FunctionSuccessMessage>()

  @watched({
    when: `whenever the functionError$ is pushed, by runFunction()`,
    meta: true,
  })
  functionError$ = new Subject<FunctionErrorMessage>()

  @watched({
    when: `whenever the functionEnd$ is pushed, by be decorator()`,
    meta: true,
  })
  functionEnd$ = new Subject<FunctionEndMessage>()

  @watched({
    when: `whenever the current frame is changed (either functionContext, or functionEnd)`,
    meta: true,
  })
  functionContextEnd$ = new Subject<FunctionEndMessage>()

  @watched({
    when: `a function-context message comes in`,
    meta: true,
    effect: `
      - creates a new FunctionFrame from the incoming message
      - adds the new frame to the current frame's children (at last position?)
      - promotes the new frame to the current frame
    `,
  })
  functionContextStartTap$ = this.functionContextStart$.pipe(
    // create a new frame

    tap((executionContext) => {
      const newFunctionFrame: FunctionFrame = {
        functionName: executionContext.functionName,
        indent: this.currentFrame.indent + 1,
        definition: executionContext.definition,
        arguments: executionContext.arguments,
        context: executionContext.context,
        startDateTime: new Date().getTime(),
        startPerformanceTime: performance.now(),
        children: [],
        parent: this.currentFrame,
        infos: [],
        warnings: [],
      }
      // add it to the current frame's children
      this.currentFrame.children.unshift(newFunctionFrame)
      // promote it to be boss!
      this.currentFrame = newFunctionFrame
      // also broadcast the current frame
      this.currentFrame$.next(this.currentFrame)
    })
  )

  @watched({
    when: `a function-context-end message comes in`,
    effect: `
      - adds timing information and result onto the current frame
      - promotes the parent frame to be the current frame again (switch back up)
    `,
    meta: true,
  })
  functionContextEndTap$ = this.functionContextEnd$.pipe(
    withLatestFrom(this.currentFrame$),

    // finish the frame

    tap(([message, frame]) => {
      // do some timing
      frame.endPerformanceTime = performance.now()
      frame.endDateTime = new Date().getTime()
      frame.durationMS = frame.endPerformanceTime - frame.startPerformanceTime

      // record the result
      frame.result = message.result

      // switch back up
      if (frame.parent) {
        // promote parent frame to be current frame
        // drill-sargent here
        this.currentFrame = frame.parent
        // broadcast new current frame
        this.currentFrame$.next(frame.parent)
      }
    })
  )

  /**
   *
   * Histories
   *
   */
  dataHistory$: Observable<DataMessage[]> = this.data$.pipe(
    scan((eventAccumulator: DataMessage[], newEvent: DataMessage) => [...eventAccumulator, newEvent], [])
  )

  dataCombo$: Observable<DataMessage[]> = this.data$.pipe(
    scan((acc: any, val) => {
      acc.push(val)
      return acc.slice(-2)
    }, [])
  )

  @watched()
  dataTriplet$: Observable<DataMessage[]> = this.data$.pipe(
    scan((acc: any, val) => {
      acc.push(val)
      return acc.slice(-3)
    }, [])
  )

  frameHistory$: Observable<FunctionFrame[]> = this.currentFrame$.pipe(
    scan((eventAccumulator: FunctionFrame[], newFrame: FunctionFrame) => [...eventAccumulator, newFrame], [])
  )

  // Toast
  toast$ = new Subject<ToastMessage>()

  toastHistory$ = this.toast$.pipe(scan((toastAcc: ToastMessage[], newToast: ToastMessage) => [...toastAcc, newToast], []))

  mergedFunctionStreams = [
    this.functionContextStart$,
    this.functionStart$,
    this.functionInfo$,
    this.functionWarning$,
    this.functionSuccess$,
    this.functionError$,
    this.functionEnd$,
    this.functionContextEnd$,
  ]

  simpleMergedFunctionStreams = [this.functionStart$, this.functionInfo$, this.functionWarning$, this.functionError$]

  simpleFunction$ = merge(...this.simpleMergedFunctionStreams).pipe(
    map((event: any) => {
      return {
        ...event,
        eventCategory: 'function',
      }
    })
  )

  // merged function stream //

  function$ = merge(...this.mergedFunctionStreams).pipe(
    map((event: any) => {
      return {
        ...event,
        eventCategory: 'function',
      }
    })
  )

  /* allAppEvents$: Observable<[any, FunctionFrame]> = merge(
    this.function$,
    this.data$,
    this.stream$,
  ).pipe(
    withLatestFrom(this.currentFrame$)
  )

  userFilteredEvents$ = this.allAppEvents$.pipe()

  appHistory$ = this.allAppEvents$.pipe(
    scan(((acc: any, [event, frame]) => [...acc, { event, frame }].slice(-15)), [])
  )

  simpleAppEvents$: Observable<[any, FunctionFrame]> = merge(
    this.simpleFunction$,
    this.data$,
    this.stream$,
  ).pipe(
    withLatestFrom(this.currentFrame$)
  )

  simpleAppHistory$ = this.simpleAppEvents$.pipe(
    scan(((acc: any, [event, frame]) => [...acc, { event, frame }].slice(-15)), [])
  ) */

  /* @watched({
    when: 'And event comes in that I might be interesting',
    meta: true,
  })
  myAppHistory$ = this.simpleAppEvents$.pipe(
    filter(([event, frame]) => {

      if (!event.context) {
        // check when this happens
        console.warn('this message has no context, skipping history', event)
        return false
      }

      const className = event.context.constructor.name

      // check if we need to add settings
      if (!this.tracingSettings.classSettings[className]) {
        this.tracingSettings.classSettings[className] = {
          mute: false,
          color: this.stringToColorHex(className)
        }
      }

      // check if class is muted by settings
      const classIsMuted = this.tracingSettings.classSettings[className].mute

      // check if class is in debug mode
      const instanceIsInDebugMode = event.context.classDoc.debug


      // muted does not get recorded, UNLESS in debug mode
      if (classIsMuted && !instanceIsInDebugMode) {
        return false
      }

      // we do make an entry in the app history
      return true
    }),
    tap(([event, frame]) => {
      // this.logHistory.unshift({ event, frame })
    }),
  ) */

  /**
   * Tracing Ready
   */
  @watched({
    when: 'Tracing is ready - after logging has reported back',
    meta: true,
  })
  tracingReady$ = new ReplaySubject<void>()

  @watched({
    when: 'Reactive Logging services has reported back',
    meta: true,
  })
  loggingReady$ = new Subject()

  //////////////////////////////////////
  //                                  //
  //         Concstruction            //
  //                                  //
  //////////////////////////////////////

  constructor(public http: HttpClient) {
    this.preConstructTracingService()
    this.constructTracingService()
  }

  // DO NOT TRACE THIS
  preConstructTracingService() {
    // register in global scope
    const extendedWindow = window as unknown as ExtendedWindow
    extendedWindow.tracingService = this
  }

  @traced({
    why: `The tracing service is the single service that exists above everything else.
          It needs to be instantiated first. Angular understands our dependency graph and take care of the
          constructing services in the correct order automatically.
  `,
  })
  constructTracingService() {
    // self register!
    this.registerInstance(this)

    this.info(this, 'Tracing Service has been construted')
  }

  /**
   * --------------------------------------------
   *  |                                          |
   *  |          ####  FUNCTIONS   ####          |
   *  |                                          |
   *  -------------------------------------------
   */

  /**
   * META. go through @watched stream
   * We listen in by subscribing and passing to universal stream$
   * THAT IS ALL.
   */

  @traced({
    why: `The @watchStream decorator left information in the watchedStreams array.
        Here, we pick it up, and subscribe to each stream.
     `,
  })
  registerStreams(context: any) {
    // go through each @watchStream$ decorated things
    ;(context.watchedStreams ? context.watchedStreams : []).forEach((streamInfo: WatchStreamInfo) => {
      const stream = context[streamInfo.propertyName]
      if (!stream) {
        this.alert(`${streamInfo.propertyName} is not defined - we can only watch streams that are already defined`)
        return
      }

      /**
       * Real outer stream (like meta stream herself, need to be quiet)
       */
      if (streamInfo.options && streamInfo.options.silent) {
        this.info(this, `skipped stream subscription for silent ${streamInfo.propertyName}`)
        return
      }

      stream.subscribe((streamMessage: any) => {
        const isMeta = streamInfo.options && streamInfo.options.meta

        /**
         * Simply subscribe to the stream. When it fires,
         * make it known by broadcasting to global stream$ stream
         * (TO REVIEW)
         */

        const message: StreamMessage = {
          eventCategory: 'stream',
          message: streamMessage,
          type: 'fired',
          context,
          stream: stream,
          streamName: streamInfo.propertyName,
          streamOptions: streamInfo.options,
          isMeta: isMeta || false,
        }

        // send  stream message
        if (isMeta) {
          // console.warn('meta stream')
          this.metaStream$.next(message)
        } else {
          if (streamInfo.options && streamInfo.options.silent) {
            return
          } else {
            this.stream$.next(message)
          }
        }
      })
      this.info(this, `tracing subscribed to ${context.constructor.name} ${streamInfo.propertyName}.`)
    })
  }

  /**
   *
   *
   *              DATA PERSISTENCE
   *
   *
   */

  @traced({
    why: `Classes have been decorated with a @tracked decorator AND persist {info}
      Here, we use that information to
      - load the correct data (e.g from localstorage)
      - subscribe to changes and persist them, debounced
    `,
  })
  registerPersistence(context: any) {
    ;(context.trackedData ? context.trackedData : []).forEach(
      (infoObject: { options: { persist: { precedence: { prod: string; dev: string } } }; propertyName: string }) => {
        /**
         * Applying loading stragegy based on @persisted(options)
         */

        if (!infoObject.options || !infoObject.options.persist) {
          return
        }

        // apply a default strategy. here: localstorage wins
        const useLocalstorage =
          (environment.production && infoObject.options.persist.precedence.prod === 'localstorage') ||
          (!environment.production && infoObject.options.persist.precedence.dev === 'localstorage')
        if (useLocalstorage) {
          /**
           *    Load from localstorage
           */
          const codeData = context[infoObject.propertyName]
          const storedData = this.loadObjectFromLocalstorage(infoObject.propertyName)
          if (storedData) {
            this.info(this, 'applied localstorage-loaded data.', storedData)

            utility.pathifyObject(storedData)

            context[infoObject.propertyName] = storedData
          } else {
            utility.pathifyObject(codeData)
            this.warn(this, `${infoObject.propertyName} not found data in localstorage, setting from code (pathified)`)
            this.saveObjectLocalstorage(infoObject.propertyName, codeData)
          }
        }

        // persist on debounced changes
        const original = context[infoObject.propertyName]
        this.data$
          .pipe
          // debounceTime(2000)
          ()
          .subscribe((change: DataMessage) => {
            if (change && original === change.original && useLocalstorage) {
              this.info(this, `persisting '${infoObject.propertyName}'`)
              this.saveObjectLocalstorage(infoObject.propertyName, original)
            }
          })
      }
    )
  }

  @traced({
    meta: true,
    why: `
    We found beautiful @track decorators. Those properties, we:
    - replace with a tracked PROXY
    - fire a message whenever the objects data changed
 `,
  })
  registerData(context: any) {
    ;(context.trackedData ? context.trackedData : []).forEach((trackInfo: TrackInfo) => {
      const original = context[trackInfo.propertyName]

      this.pathifyObject(original)

      const objectFromPath = (path: any[], obj: any) => {
        return path.reduce((prev: { [x: string]: any }, curr: string | number) => {
          return prev ? prev[curr] : null
        }, obj || self)
      }

      const proxy = onChange(original, (changeEvent: ChangeEvent) => {
        const pathArray = changeEvent.path.split('.')

        // get your parent! if you have one
        let parent = proxy // in case we are root
        // if we're not root, get your parent
        if (pathArray.length > 1) {
          const parentPath = pathArray.slice(0, -1)
          parent = objectFromPath(parentPath, proxy)
        }

        let extraNature: DataMessage['extraNature'] = 'nothing special'

        if (changeEvent.nature === 'value changed' && changeEvent.previousValue === undefined) {
          extraNature = 'property added'
        }

        const message: DataMessage = {
          eventCategory: 'data',
          type: 'data',
          original,
          rootProxy: proxy,
          parent,
          parentDotPath: 'todo...',
          context,
          name: trackInfo.propertyName,
          path: changeEvent.path,
          newValue: changeEvent.newValue,
          previousValue: changeEvent.previousValue,
          nature: changeEvent.nature,
          extraNature: extraNature,
          propertyName: changeEvent.propertyName,
          performanceTime: performance.now(),
          dateTime: new Date().getTime(),
          fullPath: [trackInfo.propertyName, changeEvent.path].join('.'),
        }

        // broadcast data change message
        ;(window as ExtendedWindow).tracingService?.data$.next(message)
      })
      // replace the original object with the new proxy!
      context[trackInfo.propertyName] = proxy

      this.registeredData.push({ proxy, original, propertyName: trackInfo.propertyName, context, editing: false })
      this.namedData[trackInfo.propertyName] = proxy

      this.info(this, `registered data: ${context.constructor.name}.${trackInfo.propertyName}`)
    })
  }

  // register animations
  @traced({
    why: `When there is an animation going on, we want to know about it.
      We add it to our bookkeeping, and
      imporantly: fire the system bus animation$ -->
    `,
  })
  registerAnimation(animationInfo: RegisteredAnimation) {
    this.registeredAnimations.push(animationInfo)
    this.animation$.next({
      eventCategory: 'animation',
      animation: animationInfo,
      context: animationInfo.context,
    })
  }

  /*  colorizeClass(context: { classDoc: { name: string; filenameColor: any; contrastColor: string } }) {
    // process class doc.
    const fileName = context.classDoc.name
    // eslint-disable-next-line max-len
    const filenameColor = this.tracingData.classColors[fileName]
      ? this.tracingData.classColors[fileName].background
      : this.stringToColorHex(context.classDoc.name)
    const contrastColor = utility.lightOrDark(filenameColor) === 'light' ? 'black' : 'white'

    context.classDoc.filenameColor = filenameColor
    context.classDoc.contrastColor = contrastColor
  }*/

  /**
   * THE CENTRAL PIECE OF ARCHITECTURE.
   */

  @traced({
    meta: true,
    why: `
      We want to integrated each class member decorated with either of:
      @tracked()
      @traced()
      @watched()
      into our piping.
  `,
  })
  registerInstance(context: DecoratedClass) {
    this.registerStreams(context)
    this.registerPersistence(context)
    this.registerData(context)

    // add class to your registered classes
    this.registeredInstances.push(context)

    const className = context.constructor.name

    // add to named services (object of singletons)
    if (className.includes('Service')) {
      this.namedServices[className] = context
    }

    // add to respective named directives array
    if (className.includes('Directive')) {
      if (!this.namedDirectives[className]) {
        this.namedDirectives[className] = []
      }
      this.namedDirectives[className].push(context)
    }

    // add to respective named components array
    if (className.includes('Component')) {
      if (!this.namedComponents[className]) {
        this.namedComponents[className] = []
      }
      this.namedComponents[className].push(context)
    }

    // add to named pipes
    if (className.includes('Pipe')) {
      this.namedPipes[className] = context
    }

    this.info(this, `registered ${className}`)

    if (className === 'ReactiveLoggingService') {
      this.tracingReady$.next()
    }
  }

  /**
   * INFOS are coming in here. Pass them down the function$ stream
   */
  @doc({
    why: `For developer ergonomics, we provide a info function.
      It pimps and passes an info message to function$
    `,
  })
  info(context: DecoratedClass, ...messages: (string | number)[]) {
    if (!context) {
    }
    const message: FunctionInfoMessage = {
      context,
      type: 'info',
      messages: messages,
    }
    this.functionInfo$.next(message)
  }

  @doc({
    why: `For developer ergonomics, we provide a .warn function.
    Pass them down the functionWarning$ stream
    `,
  })
  warn(context: DecoratedClass, ...messages: string[]) {
    const message: FunctionInfoMessage = {
      context,
      type: 'warning',
      messages: messages,
    }
    this.functionWarning$.next(message)
  }

  /**
   * This Function gets called in place of the original
   *
   * Made so by  decorator
   *
   */
  @doc({
    why: `we want to get a better understanding of our internals.
          All function are intercepted by the trace decorator, which calls this method`,
    how: `we break up the process of calling something to before, during, and after,
          we broadcast each step
    `,
  })
  runFunction(functionName: string, definition: string, args: any[], context: DecoratedClass) {
    /**
     * EXECUTE
     */
    try {
      // SUCCESS case

      // broadcast the result to functionSuccess$
      // this.functionSuccess$.next({ result, context, functionName, type: 'success' })

      // return the result
      return (definition as any).apply(context, args)
    } catch (error) {
      // ERROR case

      // broadcast the error to functionError$
      // this.functionError$.next({ error, type: 'error', context, functionName })
      throw error
    }
  }

  @doc({
    why: `we want to distinguish between different filenames, visually`,
    how: `determine a color based on the strings characters`,
    what: `do some hex magic`,
    todo: `move to a utils place`,
  })
  stringToColorHex(str: string) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let j = 0; j < 3; j++) {
      // eslint-disable-next-line no-bitwise
      const value = (hash >> (j * 8)) & 0xff
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }

  /**
   * Cool idea but not used!
   */
  addDebuggerStatementToFunction(methodDefinitionString: string) {
    const debuggerString = methodDefinitionString.replace('{', '{debugger; ')
    // const debuggerFunction = new Function(`return function ${debuggerString}`)()
    // result = debuggerFunction.apply(context, args)
    return debuggerString
  }

  @traced({
    why: 'anything that can go wrong, will go wrong!',
    how: 'create an alert when something goes very wrong',
    todo: 'nicer warning then alert!',
  })
  shouldNotHappen(message: string) {
    alert('should not happen: ' + message)
  }

  @traced({
    why: `We can record toDos in our code. Here we log them out.`,
  })
  todo(todo: any) {
    this.info(this, `TODO: ${todo}`)
  }

  error(...message: any[]) {
    console.error(...message)
  }

  alert(message: string) {
    alert('alert ' + message)
  }

  @traced({ why: 'wanna dance' })
  dance() {
    this.warn(this, 'oh wow, i am not sure if i am ready!')
    this.move('left')
  }

  @traced({ why: '...moving is fun!' })
  move(direction: string) {
    this.info(this, 'I am moving', direction)
    this.turn(20)
    return 'done moving!'
  }

  @traced({ why: 'so is turning...!' })
  turn(degrees: number) {
    this.info(this, 'turning', degrees, 'degrees')
    // this.kiss()
    return 'turning done'
  }

  @traced({ why: 'last step' })
  kiss() {
    throw new Error('not yet')
  }

  // utils
  isString(value: any) {
    return typeof value === 'string' || value instanceof String
  }

  isObject(value: { constructor: ObjectConstructor }) {
    return value && typeof value === 'object' && value.constructor === Object
  }

  isNumber(value: any) {
    return typeof value === 'number' && isFinite(value)
  }

  // settings and such
  @traced({
    why: `When user hits enter, clear console and set call stats to 0 again`,
  })
  resetStats() {
    console.clear()
    this.stats = {
      totalCallCount: 0,
      totalCallDuration: 0,
    }
  }

  /**
   * LOCAL STORAGE
   */

  @traced({
    why: 'sometime we want our code-data to take precedence',
  })
  removeKeyFromLocalstorage(key: string) {
    localStorage.removeItem(key)
  }

  @traced({
    why: `We want settings like what gets logged to persist.`,
  })
  loadObjectFromLocalstorage(key: string) {
    const storedJson = localStorage.getItem(key)
    if (storedJson) {
      this.info(this, `loaded '${key}' from localstorage`)
      try {
        return JSON.parse(storedJson)
      } catch (e) {
        console.log('fucker', key, storedJson)
        console.warn('could not parse json', e, storedJson)
      }
    } else {
      this.warn(this, `localstorage has no such key: ${key}`)
    }
  }

  @traced({
    why: `Let's write stuff to localstorage`,
  })
  saveObjectLocalstorage(key: string, object: any) {
    if (!object) {
      this.warn(this, 'will not write undefined to localstorage!')
      return
    }
    localStorage.setItem(key, JSON.stringify(object))
    this.info(this, `persisted ${key} to localstorage`)
  }

  @traced({
    why: 'we want to have the state of our data in localstorage writte to file',
  })
  saveObjectToFile(object: any) {
    this.http.post('http://localhost:8080/commit', object).subscribe({
      next: (res) => {
        console.log(res)
      },
      error: () => {
        this.info(this, 'error persisting changes to disk.')
      },
    })
  }

  @traced({
    why: `We want to keep our data in localstorage and on file in sync.
      This utility allows us to save everything in localstorage to file.
    `,
  })
  saveAllRegisteredDataToFile() {
    this.registeredData
      .filter((data) => data.original.key && data.original.location)
      .forEach((data) => {
        this.saveObjectToFile(data.original)
      })
  }

  @traced({
    why: `when dealing with object, knowing where you are (a.k.a. being able
      to address any sub-object) is a great feature.`,
  })
  pathifyObject(object: any) {
    if (!object) {
      alert('cannot pathify non object')
    }

    this.addPathToObjectArrayItems(object)
    this.addPathToObjectObjects(object)

    return object
  }

  addPathToObjectArrayItems(object: any) {
    if (!object) {
      object = { some: [{ thing: 'such object' }], else: [{ more: 'stuff' }] }
    }

    const objectPath = object.path || [object.key]
    const arrays = this.getObjectArrays(object)
    arrays.forEach((arrayInfo) => {
      const arrayKey = arrayInfo.key
      arrayInfo.array.forEach((item, index) => {
        // if the item is an object, it can hold a path
        if (this.isObject(item)) {
          item.path = [...objectPath, arrayKey, index]
          // repeate for children
          this.addPathToObjectArrayItems(item)
          this.addPathToObjectObjects(item)
        }
      })
    })
    // debug.info('added path, now', object)
  }

  addPathToObjectObjects(object: any) {
    if (!object) {
      object = { some: { thing: 'cool' } }
    }

    const objectPath = object.path || [object.key]
    const objectInfos = this.getObjectObjects(object)
    objectInfos.forEach((objectInfo) => {
      const objectKey = objectInfo.key
      objectInfo.object.path = [...objectPath, objectKey]
      // repeat for children
      this.addPathToObjectArrayItems(objectInfo.object)
      this.addPathToObjectObjects(objectInfo.object)
    })
  }

  getObjectObjects(object: { [x: string]: any }) {
    if (!object) {
      alert('no object for object objects')
    }

    const objectObjects: { key: string; object: any }[] = []
    Object.keys(object).forEach((key) => {
      const item = object[key]
      if (this.isObject(item)) {
        objectObjects.push({ key: key, object: item })
      }
    })
    return objectObjects
  }

  getObjectArrays(object: { [x: string]: any }): { key: string; array: any[] }[] {
    if (!object) {
      alert('no object!')
    }

    const objectArrays: { key: string; array: any }[] = []

    Object.keys(object).forEach((key) => {
      const item = object[key]
      if (this.isArray(item)) {
        objectArrays.push({ key: key, array: item })
      }
    })
    return objectArrays
  }

  isArray(value: any) {
    return value && typeof value === 'object' && value.constructor === Array
  }

  isFunction(value: any) {
    return typeof value === 'function'
  }

  isNull(value: any) {
    return value === null
  }

  isUndefined(value: any) {
    return typeof value === 'undefined'
  }

  isBoolean(value: any) {
    return typeof value === 'boolean'
  }

  isError(value: any) {
    return value instanceof Error && typeof value.message !== 'undefined'
  }

  isDate(value: any) {
    return value instanceof Date
  }

  isSymbol(value: any) {
    return typeof value === 'symbol'
  }

  isClass(value: any) {
    if (!value || !value.constructor) {
      return
    }
    return value.constructor.toString().startsWith('class ')
  }

  // util, not a good place here, move up
  unCamelCase(str: string) {
    if (!str) {
      return 'nothing to un camel'
    }
    str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2')
    str = str.toLowerCase()
    return str
  }

  toJSON() {
    return { name: this.classDoc.name }
  }

  /**
   * NOT IN USE but awesome!!
   *
   * you have ['tracingStyles', 'header']
   *
   * and you get the object back from us!!
   *
   */
  getObjectFromDataPath(dataWithPath: { path: string | any[] }) {
    const data = this.registeredData.find((d) => {
      return d.propertyName === dataWithPath.path[0]
    })
    if (!data) {
      console.warn('could not find data for', dataWithPath.path)
      alert('could not find data')
      return
    }
    const childPath = dataWithPath.path.slice(1)
    return utility.objectFromPath(childPath, data.proxy)
  }

  @doc({
    why: `we have something in our hands and want to know if it's a dot.string`,
    cavas: `only cares if beginning piece is correct`,
  })
  isDotstring(value: string) {
    return /[a-zA-Z]+\.[a-zA-Z]+/.test(value) && !value.includes('/')
  }

  getMatchingKeys(partial: string, lookupObject: {}) {
    return Object.keys(lookupObject)
      .filter((k) => k.toLowerCase().startsWith(partial.toLowerCase()))
      .filter((k) => !k.includes('__'))
      .filter((k) => k !== 'path')
  }

  /**
   *   HOUSEKEEPING
   */

  @traced()
  unsubscribeComponentStreams(instance: DecoratedClass) {
    const streamNames = Object.getOwnPropertyNames(instance).filter((name) => name.endsWith('$'))
    streamNames.forEach((name) => {
      ;((instance as any)[name] as Subject<any>).unsubscribe()
    })
  }

  /**
   * Data undo.
   */
  @traced()
  undoDataEvent(dataMessage: DataMessage) {
    if (dataMessage.nature === 'value changed') {
      dataMessage.parent[dataMessage.propertyName] = dataMessage.previousValue
    }
  }
}
