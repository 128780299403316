import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { ProficloudInputConfig, SelectValue } from '@services/proficloud.interfaces'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-select',
  templateUrl: './pc-input-select.component.html',
  styleUrls: ['./pc-input-select.component.scss'],
})
export class PcInputSelectComponent extends PcInputBaseComponent implements OnInit {
  @Input()
  override form: UntypedFormGroup

  @Input()
  override config: ProficloudInputConfig

  @Output()
  fieldInput = new EventEmitter<string>()

  @Input()
  index: number

  @Input()
  standAlone: boolean

  @ViewChild('inputValue')
  inputElement: ElementRef

  hasFocus: boolean

  ngOnInit() {
    if (!this.config.inputId) {
      this.config.inputId = Math.round(Math.random() * 1000 + performance.now()) + ''
    }
  }

  // TODO: Processor leak - we need to track changes of config selectValues and then prune the list
  visibleSelectOptions(selectValues: SelectValue[]) {
    if (selectValues) {
      return selectValues.filter((v) => {
        if (typeof v.visible === 'function') {
          return v.visible()
        } else if (v.visible !== undefined) {
          return v.visible
        } else {
          return true
        }
      })
    } else {
      return []
    }
  }

  isMulti(config: ProficloudInputConfig) {
    return typeof config.multi === 'function' ? config.multi() : config.multi
  }
}
