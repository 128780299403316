import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { IamService } from '@services/iam.service'
import { ProficloudService } from '@services/proficloud.service'

@Component({
  selector: 'app-invitation-callback',
  templateUrl: './invitation-callback.component.html',
  styleUrls: ['./invitation-callback.component.scss'],
})
export class InvitationCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public proficloud: ProficloudService,
    private iam: IamService,
    public router: Router
  ) {}

  ngOnInit(): void {
    const invitationId = this.route.snapshot.queryParamMap.get('invitationId')
    const organizationId = this.route.snapshot.queryParamMap.get('organizationId')

    if (invitationId && organizationId) {
      this.iam.acceptInvitation(organizationId, invitationId)
    }
  }
}
