import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { IamService } from '@services/iam.service'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { AppService } from 'src/app/app.service'
import { PcStatusOverlayService } from '../../services/pc-status-overlay/pc-status-overlay.service'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  invitationToken: string

  forgotPasswordControl = new UntypedFormControl('', [Validators.required, Validators.email])

  forgotPasswordForm: UntypedFormGroup

  constructor(
    public app: AppService,
    public proficloud: ProficloudService,
    private iam: IamService,
    public router: Router,
    private statusOverlay: PcStatusOverlayService,
    public ui: UiService
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = new UntypedFormGroup({ email: this.forgotPasswordControl })
  }

  submit() {
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.requestPasswordResetBusy)

    this.iam.requestPasswordReset(this.forgotPasswordForm.controls['email'].value).subscribe({
      next: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.requestPasswordResetSuccess)
        setTimeout(() => {
          this.statusOverlay.resetStatus()
          this.router.navigate(['/'])
        }, 2000)
      },
      error: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.requestPasswordResetError)
      },
    })
  }
}
