import { DecoratedClass } from './../services/tracing/tracing.interfaces'

export interface WatchStreamInfo {
  propertyName: string
  options?: WatchStreamOptions
}

export interface WatchStreamOptions {
  when?: string
  what?: string // in case of a tap
  meta?: true // undefined by default, which counts as false
  output?: string
  effect?: string
  visualElement?: any
  silent?: boolean
  [key: string]: any
}

/**
 *      @WATCHED   decorator - for all your stream observing needs.
 *
 *      Writes an object with {propertyName, options} to the class' watchedStreams[] array.
 *
 *      The observable will be automatically subscribed to
 *      and forwarded to tracing.streams$
 *
 */

export function watched(options?: WatchStreamOptions) {
  return function innerWatchStream(target: DecoratedClass, propertyName: string) {
    if (!target.watchedStreams) {
      target.watchedStreams = []
    }
    target.watchedStreams.push({ propertyName, options })
  }
}
