import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-chiplist',
  templateUrl: './pc-input-chiplist.component.html',
  styleUrls: ['./pc-input-chiplist.component.scss'],
})
export class PCInputChiplistComponent extends PcInputBaseComponent {
  @Output() valueChanged: EventEmitter<string[]> = new EventEmitter()

  @Input() size: 'regular' | 'small' = 'regular'

  @Input() variant: 'fit-width'

  @Input() control: UntypedFormControl

  @Input({ required: true }) placeholder: string

  @Input() label: string

  @Input() errorHint: string

  @Input() search: boolean

  @Input() inputID: string

  @Input() inputValue: string[] = []

  touched: boolean

  separatorKeysCodes: number[] = [ENTER, COMMA]

  public resetInput() {
    this.control.setValue('')
  }

  public addTag(input: HTMLInputElement, value: string) {
    if ((value || '').trim()) {
      ;(this.inputValue as string[]).push(value.trim())
      this.valueChanged.emit(this.inputValue)
    }

    if (input) {
      input.value = ''
    }

    this.control.setValue(null)
  }

  public removeTag(tag: string) {
    const index = this.inputValue.indexOf(tag)
    this.inputValue.splice(index, 1)
  }

  // https://proficloud.atlassian.net/browse/DMS-568
  public onChipBlur(event: FocusEvent) {
    const value = (event.target as HTMLInputElement).value
    if (value.length > 0) {
      this.addTag(event.target as HTMLInputElement, value)
    }
  }
}
