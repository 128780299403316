export const appData = {
  key: 'appData',
  location: '/src/app/app.data.ts',
  universe: {
    display: 'flex',
    background: '#222',
    content: {
      width: '100%',
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'visible',
    },
    width: '100%',
    justifyContent: 'center',
  },
  leftPanel: {
    width: '500px',
    background: '#fcfcfc',
    'overflow-x': 'scroll',
    borderRight: '1px solid #ddd',
    state: 'collapsed',
    height: '100vh',
    boxSizing: 'border-box',
    dataHeader: {
      background: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      title: {
        padding: '0',
        fontSize: '18px',
        borderTop: '0px solid #ccc',
        flexGrow: '1',
      },
      width: '100%',
      padding: '20px',
      boxSizing: 'border-box',
      borderTop: '1px solid #eee',
    },
    link: {
      background: '#111',
      color: '#ddd',
      display: 'inline-block',
      margin: '5px',
      padding: '10px 15px',
      textDecoration: 'none',
      paper: '_',
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      background: '#f5f5f5',
      padding: '20px',
      alignItems: 'center',
      fontSize: '18px',
      fontWeight: '500',
      borderBottom: '1px solid #ddd',
    },
  },
  hoverPanel: {
    position: 'fixed',
    right: '20px',
    background: 'rgba(255, 255, 255, 0.8)',
    bottom: '20px',
    padding: '20px',
    state: 'expanded',
  },
  outlet: {
    background: 'transparent',
    flexGrow: '1',
    'width_%': '100',
    position: 'relative',
    // fontFamily: 'Lato'
  },
  controls: {
    button: {
      background: 'white',
      border: '1px solid #aaa',
      padding: '10px 20px',
      fontSize: '14px',
      cursor: 'pointer',
    },
  },
  toastPanel: {
    position: 'fixed',
    right: '400px',
    top: '0',
    width: '300px',
    background: 'rgba(0, 0, 0 ,0.6',
    padding: '20px 40px',
    state: 'expanded',
    overflow: 'hidden',
  },
  sidePanel: {
    height: '100vh',
    boxSizing: 'border-box',
  },
  rightPanel: {
    state: 'collapsed',
    background: 'transparent',
    paddingLeft: '0px',
  },
  cssSelector: {
    background: 'white',
    color: '#333',
    padding: '20px 20px',
    width: '100%',
    'box-sizing': 'border-box',
    border: '3px solid white',
    margin: '0',
    fontWeight: '400',
  },
  cssBorder: {
    background: 'darkred',
    thick: '5',
    color: '#333',
    zIndex: '1000',
  },
  bottomPanel: {
    position: 'fixed',
    bottom: '0px',
    padding: '0px',
    background: 'lime',
    height: '0px',
    width: '100%',
    center: {
      position: 'absolute',
      bottom: '0',
      height: '40vh',
      width: '100%',
      background: 'lime',
      overflowY: 'visible',
    },
    state: 'collapsed',
    editorContainer: {
      height: '40vh',
      paddingTop: '40px',
      background: '#222',
      not: 'wording',
      overflowX: 'hidden',
      overflowY: 'visible',
    },
    justifyContent: 'center',
  },
  hyperStyles: {
    dark: {
      inputCanvas: {
        background: '#333',
      },
      inputText: {
        color: 'white',
        fontSize_px: '16',
      },
      inputInput: {
        caretColor: 'yellow',
      },
    },
  },
}
