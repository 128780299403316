// angular
import { Injectable } from '@angular/core'
// app
import { ActivatedRoute, Router } from '@angular/router'
import { appData } from './app.data'
import { ContentService } from './modules/proficloud/services/content/content.service'
import { Environments } from './modules/proficloud/services/proficloud.interfaces'
import { traced } from './modules/shared/decorators/trace-decorator'
import { tracked } from './modules/shared/decorators/track-decorator'
import { ClassDoc } from './modules/shared/services/tracing/tracing.interfaces'
import { TracingService } from './modules/shared/services/tracing/tracing.service'

@Injectable({
  providedIn: 'root',
})
export class AppService {
  classDoc: ClassDoc = {
    name: 'AppService',
    location: '/src/app/modules/shared/services/app.service.ts',
  }

  environment: {
    backend: Environments
    frontend: Environments
  }

  application: {
    name: 'proficloud' | 'chargeRepay'
    keycloakClientId: 'kaa-frontend' | 'chargerepay-frontend'
  } = {
    name: 'proficloud',
    keycloakClientId: 'kaa-frontend',
  }

  // landing page, to redirect to after logout. set during detect environment
  landingPage: string

  /**
   *      Data
   */
  @tracked({
    what: 'All data related to app. The outer-most entity.',
    persist: {
      precedence: { dev: 'code', prod: 'code' },
    },
  })
  appData = appData

  constructor(
    public tracing: TracingService,
    public content: ContentService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.constructAppService()
  }

  constructAppService() {
    this.tracing.registerInstance(this)
    this.detectEnvironment()
    this.checkForQueryParams()
  }

  checkForQueryParams() {
    const urlParams = new URLSearchParams(window.location.search)
    const addDeviceUUID = urlParams.get('addDevice')
    if (addDeviceUUID) {
      localStorage.setItem('addDevice', addDeviceUUID)
    }
  }

  getQueryParam(param: string) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
  }

  deleteQueryParam(param: string) {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete(param)
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: urlParams,
      queryParamsHandling: 'merge',
      // skipLocationChange: true, // Note: This doesn't work, sadly
    })
  }

  setQueryParam(key: string, value: string | string[] | number[] | null) {
    const queryParams: Record<string, string | string[] | number[] | null> = {}
    queryParams[key] = value

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      // skipLocationChange: true, // Note: This doesn't work, sadly
    })
  }

  setQueryParams(queryParams: Record<string, string | string[] | number[] | null>) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      // skipLocationChange: true, // Note: This doesn't work, sadly
    })
  }

  getPartnerChargeRepayUrl(env: 'local' | 'dev' | 'staging' | 'production') {
    const map = {
      local: 'http://localhost:3180',
      dev: 'https://app.charge-repay-development.de',
      staging: 'https://app.charge-repay-staging.de',
      production: 'https://app.charge-repay.io',
    }
    return map[env]
  }

  @traced()
  detectEnvironment() {
    const landingPages = {
      proficloud: {
        local: 'http://localhost:4200/authenticate',
        dev: 'https://proficloud-dev.io',
        staging: 'https://proficloud-staging.io',
        production: 'https://proficloud.io',
      },
      chargeRepay: {
        local: 'http://localhost:3180/authenticate',
        dev: 'https://charge-repay-development.de',
        staging: 'https://charge-repay-staging.de',
        production: 'https://charge-repay.io',
      },
    }

    const backendMap: Record<string, Environments> = {
      // localhost: 'local',
      localhost: 'dev',
      'app.proficloud-dev.io': 'dev',
      'app.proficloud-staging.io': 'staging',
      'app.proficloud.io': 'production',
      // charge repay
      'app.charge-repay-development.de': 'dev',
      'app.charge-repay-staging.de': 'staging',
      'app.charge-repay.io': 'production',
    }
    const frontendMap: Record<string, Environments> = {
      localhost: 'dev',
      'app.proficloud-dev.io': 'dev',
      'app.proficloud-staging.io': 'staging',
      'app.proficloud.io': 'production',
      // charge repay
      'app.charge-repay-development.de': 'dev',
      'app.charge-repay-staging.de': 'staging',
      'app.charge-repay.io': 'production',
    }

    // fallback to dev in case we don't have a match
    let backendEnv: Environments = backendMap[window.location.hostname] || 'dev'
    let frontendEnv: Environments = frontendMap[window.location.hostname] || 'dev'

    // environments can get overwritten by have an entry (forceEnv) in localstorage
    const localStorageEnv = localStorage.getItem('forceEnv') as 'staging' | 'dev' | 'production' | 'local'
    if (localStorageEnv && ['local', 'dev', 'staging', 'production'].includes(localStorageEnv)) {
      backendEnv = localStorageEnv
      frontendEnv = localStorageEnv
    }

    // If we are on a Netlify PR preview branch then point to staging
    if (window.location.host.includes('proficloud-pr-preview.netlify.app')) {
      backendEnv = 'staging'
      frontendEnv = 'staging'
    }

    this.environment = {
      backend: backendEnv,
      frontend: frontendEnv,
    }

    this.landingPage = landingPages.proficloud[backendEnv]
    if (window.location.host.includes('localhost')) {
      this.landingPage = landingPages.proficloud.local
    }

    // overwrite if we are on chargeRepay
    if (window.location.host.includes('charge-repay') || window.location.host.includes('localhost:3180')) {
      this.application = {
        name: 'chargeRepay',
        keycloakClientId: 'chargerepay-frontend',
      }
      this.landingPage = landingPages.chargeRepay[frontendEnv]
      if (window.location.host.includes('localhost')) {
        this.landingPage = landingPages.chargeRepay.local
      }
    }
  }

  @traced({
    why: `fullscreen is much more immersive`,
  })
  fullscreen() {
    const el: HTMLElement = document.documentElement
    el.requestFullscreen()
  }
}
