import { ExtendedWindow } from '../shared.interfaces'
import { environment } from './../../../../environments/environment'
import { DecoratedClass } from './../services/tracing/tracing.interfaces'
import { TracingService } from './../services/tracing/tracing.service'

export class TracedFunctionInfo {
  propertyName: string

  originalMethodString: string

  options: TracedFunctionOptions
}

export interface TracedFunctionOptions {
  meta?: true
  why?: string
  usage?: string
  todo?: string
  improve?: string
  deprecated?: true
  [key: string]: any
}

export function traced(options?: TracedFunctionOptions) {
  return function trace(target: DecoratedClass, propertyKey: string, descriptor: PropertyDescriptor) {
    if (environment.production || true) {
      // always off for now
      return descriptor
    }

    // for development, re-write the descriptor to include tracing

    const originalMethodString = descriptor.value

    if (!target.tracedFunctions) {
      Reflect.defineProperty(target, 'tracedFunctions', { value: [] })
    }

    // Uncomment if we want to do this
    // target.tracedFunctions.push({fetchDeviceLogs
    //   originalMethodString,
    //   propertyName: propertyKey,
    //   options,
    // })

    /**
     * Re-write the original function so that
     *
     *   -
     *   - the function is run in a traced manner
     *   - a null execution context is broadcasted
     */
    descriptor.value = function (this: DecoratedClass, ...args: any[]) {
      // get access to the debug service
      const tracingService = (window as unknown as ExtendedWindow).tracingService as TracingService

      let result
      if (!tracingService) {
        alert('traced method called before tracing service has been registered globally')
        result = originalMethodString.apply(this, args)
        return result
      }

      // broadcast function context
      tracingService.functionContextStart$.next({
        type: 'contextStart',
        functionName: propertyKey,
        context: this,
        definition: originalMethodString,
        arguments: args,
      })

      // broadcast function start
      tracingService.functionStart$.next({
        type: 'start',
        functionName: propertyKey,
        context: this,
        definition: originalMethodString,
        arguments: args,
      })

      // executed the traced function
      result = tracingService.runFunction(propertyKey, originalMethodString, args, this)

      // broadcast function end (picked by logging)
      tracingService.functionEnd$.next({
        type: 'end',
        context: this,
        functionName: propertyKey,
        result,
      })

      // cleanup: go up one level in the stack
      tracingService.functionContextEnd$.next({
        type: 'contextEnd',
        context: this,
        functionName: propertyKey,
        result,
      })

      //

      // pass on the result to the caller
      return result
    }

    // return modified definition
    return descriptor
  }
}
