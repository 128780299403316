<flex-col class="container">
  <div class="topBar">
    <!-- Logo -->
    <div class="topBarLeft">
      <app-proficloud-logo></app-proficloud-logo>
    </div>
  </div>

  <div class="bottom">
    <flex-col class="form">
      <div class="title">{{ 'SHARED.EMAIL_CHANGE_TITLE' | translate }}</div>
      <div class="subTitle">{{ 'SHARED.EMAIL_CHANGE_SUBTITLE' | translate }}</div>
      <flex-col class="inputs">
        <form [formGroup]="changeEmailForm">
          <pc-input-textfield [control]="changeEmailControl" placeholder="Email" variant="fit-width"></pc-input-textfield>
          <div style="margin-top: 10px">
            <pc-button [wide]="true" [text]="'SHARED.EMAIL_CHANGE_ACTION' | translate" (btnClick)="submit()"></pc-button>
          </div>
        </form>
      </flex-col>
    </flex-col>
  </div>
</flex-col>
