import { Component, ElementRef, Input, ViewChild } from '@angular/core'

@Component({
  selector: 'app-quantity-select',
  styles: [
    `
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    `,
  ],
  template: `
    <flex-row-between>
      <pc-button size="large" variant="secondary" [text]="'STORE.MINUS' | translate" (btnClick)="onQuantityInput(quantity - 1)"></pc-button>

      <input
        #quantityInput
        step="1"
        style="text-align:center; flex-grow: 1; max-width: 80px; font-size: 16px;"
        type="number"
        placeholder="quantity"
        (input)="onQuantityInput(+$event.target.value)"
        [value]="quantity"
        onClick="this.select();" />
      <pc-button size="large" variant="secondary" [text]="'STORE.PLUS' | translate" (btnClick)="onQuantityInput(quantity + 1)"></pc-button>
    </flex-row-between>
  `,
})
export class QuantitySelectComponent {
  @ViewChild('quantityInput') quantityInput: ElementRef

  @Input() quantity = 1

  onQuantityInput(quantity: number) {
    this.quantity = Math.max(quantity, 1)
    this.quantityInput.nativeElement.value = this.quantity
  }
}
