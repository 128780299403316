import { inject, Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { isString } from 'lodash'
import { StatusOverlayService } from '../../../proficloud/modules/device-management/services/status-overlay.service'
import { PCStatusOverlayConfig } from '../../components/pc-status-overlay/pc-status-overlay.component'

@Injectable({
  providedIn: 'root',
})
export class PcStatusOverlayService {
  constructor(private translationService: TranslateService) {}

  private statusBin = inject(StatusOverlayService)

  public config?: PCStatusOverlayConfig

  // Presents the status based on the provided configuration
  public showStatus(config: PCStatusOverlayConfig | string) {
    if (isString(config)) {
      this.config = this.statusBin.statusOverlays[config]
    } else {
      this.config = config
    }

    // check timeout for success messages only
    if (this.config?.type === 'success') {
      this.timeout(this.config.timeout)
    }
  }

  public showStatusTranslate(configInc: PCStatusOverlayConfig, overlay?: string) {
    this.translationService.get(configInc.message).subscribe((trnsl) => {
      if (!trnsl) {
        throw new Error('Translation failed. Not found.')
      }

      const translatedConfig: PCStatusOverlayConfig = configInc
      translatedConfig.message = trnsl

      this.config = translatedConfig

      // check timeout for success messages only
      if (this.config.type === 'success') {
        this.timeout(this.config.timeout)
      }
    })
  }

  // Resets the status.
  public resetStatus() {
    delete this.config
  }

  // closes the status by resetting the config after a period of time
  // default 2 Seconds
  private timeout(ms: number = 2000) {
    setTimeout(() => {
      delete this.config
    }, ms)
  }
}
