@if (form | inputIsActive: config) {
  <mat-form-field class="input-password" [class.input-password--fit-width]="variant === 'fit-width'" [class.input-password--small]="size === 'small'">
    <mat-label>{{ placeholder }}</mat-label>
    <input type="password" matInput [placeholder]="placeholder" [id]="inputID" (input)="inputFieldChanged($event)" [formControl]="control" />
    @if (control?.errors && control?.touched) {
      <app-icon class="input-password__icon" [class.input-password__icon--small]="size === 'small'" [color]="'var(--error-red)'" name="warning" matSuffix>
      </app-icon>
    }
    @if (!control?.errors && control?.touched) {
      <app-icon
        class="input-password__icon input-password__icon--reset"
        [class.input-password__icon--small]="size === 'small'"
        color="var(--primary)"
        name="cross"
        matSuffix
        (click)="resetInput()"></app-icon>
    }
  </mat-form-field>
}
