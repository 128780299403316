// angular
import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'

// rxjs
import { Subject, Subscription, fromEvent } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

// app
import { UiService } from './../../proficloud/services/ui/ui.service'
import { AnimationService } from './../services/animation/animation.service'
import { HyperService } from './../services/hyper/hyper.service'
import { BoundingBox, ClassDoc, DataMessage, LiveCSS } from './../services/tracing/tracing.interfaces'
import { TracingService } from './../services/tracing/tracing.service'
import { UtilityService as utility } from './../services/utility/utility.service'

// decorators
import { CssDefinition } from '../../proficloud/services/proficloud.interfaces'
import { doc } from '../decorators/doc-decorator'
import { traced } from '../decorators/trace-decorator'
import { watched } from '../decorators/watch-stream-decorator'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[css]',
})
export class CssDirective implements OnInit, OnDestroy, OnChanges {
  classDoc: ClassDoc = {
    name: 'CssDirective',
    location: '/src/app/modules/shared/directives/css.directive.ts',
    why: `We want to have an immediate connection between what we're doing and what we're seeing.`,
  }

  @Input() css: CssDefinition[] | CssDefinition = [
    {
      // error indicating css
      path: ['missing', 'css'],
      background: 'darkred',
      wording: 'no css received :(',
    },
  ]

  @Output() init = new EventEmitter()

  constructor(
    // angular
    public el: ElementRef,
    // app
    public tracing: TracingService,
    public ui: UiService,
    public animation: AnimationService,
    public hyper: HyperService
  ) {
    this.tracing.registerInstance(this)
    this.constructCssDirective()
  }

  // debug
  debug = false

  // The elements classNames as derived form the applied css paths
  // tracking this is expensive. why exaclty?
  appliedClassNames: string[] = []

  // this currently takes all sorts of type-in nonsense
  appliedCss: any = {}

  // all the short-lived animations we subscribed to
  animationSubscriptions: Subscription[] = []

  // all animation subscriptions  that fire on trigger
  triggerSubscriptions: any = {}

  // text node for rendering text
  textNode: Text

  /**
   *       Streams
   */

  @watched({
    when: '(one of) our css objects changed',
  })
  cssChanged$ = new Subject<DataMessage>()

  @watched({
    when: 'when css changed -> applying inputs',
  })
  cssChangedTap$ = this.cssChanged$.pipe(tap(() => this.applyInputs()))

  @watched({
    when: 'Either a real or a fake control-click comes in',
  })
  controlClick$ = new Subject()

  /**
   *      LIFECYCLE
   */

  @traced({
    why: `CSS directive got constructed`,
    disabled: true,
  })
  constructCssDirective() {
    this.tracing.info(this, 'css directive contructed')
  }

  @traced({
    why: `We set ourselves up for success :)`,
    disabled: true,
  })
  ngOnInit() {
    // tell your parent that you now exist (if they care)
    this.init.emit(this)

    // set up control-clicks, for live css
    this.attachControlClickStream()

    // set up css change subscription
    this.subscribeToObjectChanges()
  }

  @traced({
    why: `This gets called when (one of our) inputs has changed (including onInit)`,
    disabled: true,
  })
  ngOnChanges(changes: SimpleChanges) {
    this.inputCssToArray()
    this.importCss()
    this.checkIfDebug()
    this.applyInputs()
  }

  @traced({
    why: `we need to un-subscribe so that we don't try to update non-existing elements`,
    disabled: true,
  })
  ngOnDestroy() {
    this.tracing.info(this, 'This beautiful css directive got destroyed')
  }

  @traced({
    why: `We want to be able to selectively debug css directives. For that, we simply have to add
     debug: true to the css definition. The presence of this property will be checked here, and mark this
     instance as 'to be debugged'.
    `,
  })
  checkIfDebug() {
    ;(this.css as CssDefinition[]).forEach((css) => {
      if (css.debug) {
        this.classDoc.debug = true
      }
    })
  }

  /**
   *      FUNCTIONS
   */

  @traced({
    why: `For developer ergonomigs, we allow css input to be a object, e.g. [css]="heroStyle"
          or an array of objects, e.g. [css]="[button, button.primary]". In case we receive a single object,
          we stick it into an array, for consistency for the rest of our code.`,
    disabled: true,
  })
  inputCssToArray() {
    if (!this.css) {
      this.css = []
      return
    }
    if (!utility.isArray(this.css)) {
      this.css = [this.css as CssDefinition]
    }
  }

  @traced({
    why: `We want the convenience of reusing styles from another definition. Here, we add any imported definitions`,
  })
  importCss() {
    ;(this.css as CssDefinition[]).forEach((css) => {
      // if (css.wording === 'right') {alert('jo')}
      if (css.import) {
        const importedDefinition = this.hyper.newParse(css.import).value
        if (importedDefinition) {
          ;(this.css as CssDefinition[]).unshift(importedDefinition)
        }
      }
    })
  }

  @traced({
    why: `Here is a central piece of reactivity. We want to be informed when (one of) our css object, or a child, changed
          We simply check if the full path starts with one of our paths. that is actually dangerous. Or lead to too much checking
          ... think about that...
          `,
    idea: `instead of the css doing all the filtering, we just given them a stream to subscribe to.
      that stream would be used for all css using that stream.
      should improve performance a lot
    `,
  })
  subscribeToObjectChanges() {
    this.tracing.data$.pipe(filter((dataEvent) => (this.css as CssDefinition[]).includes(dataEvent.parent))).subscribe(this.cssChanged$)
  }

  @traced({
    why: `Either we have been instantiated, or our inputs changed.
    In either case, we want to apply inputs for styling.
    `,
    disabled: true,
  })
  applyInputs() {
    if (!this.css) {
      console.warn('css directive with undefined css')
      return
    }

    if (!this.tracing.isArray(this.css)) {
      alert('css has not been transformed to array')
      return
    }

    // attempt at unsubscribing
    this.animationSubscriptions.forEach((subscription) => subscription.unsubscribe())

    // remove class names
    this.el.nativeElement.classList.remove(...this.appliedClassNames)
    this.appliedClassNames = []

    // remove applied styles
    Object.keys(this.appliedCss).forEach((key) => {
      this.el.nativeElement.style[key] = 'unset'
    })
    this.appliedCss = {}

    // style native element
    ;(this.css as CssDefinition[]).forEach((css) => {
      // add class from path
      if (css.path) {
        const pathClass = css.path.join('-')
        this.el.nativeElement.classList.add(pathClass)
        this.appliedClassNames.push(pathClass)
      }
      if (!css.path) {
        // console.warn('this css has no path', css)
      }

      // MONEY SHOT: apply styles
      const formatted: any = this.formatCSS(css)
      if (!formatted) {
        console.warn('could not format css')
        return
      }
      for (const key of Object.keys(formatted)) {
        try {
          this.el.nativeElement.style[key] = formatted[key]
          this.appliedCss[key] = formatted[key]
        } catch (e) {
          console.warn('error with formatted', formatted)
          console.warn('error applying key', key, formatted[key])
        }
      }

      // wording if there!
      if (css.wording) {
        if (!this.textNode) {
          this.textNode = document.createTextNode(css.wording)
          this.el.nativeElement.prepend(this.textNode)
        } else {
          this.textNode.textContent = css.wording
        }
      }

      // animation subscription
      if (css.animate) {
        this.tracing.info(this, 'setting up animation for', css.path.join(' '))

        // todo: also allow dot.notation
        const animationDefintion = css.animate
        if (!animationDefintion.on) {
          return
        }
        // check if the current trigger value is one of the animations cases
        let triggerValue = this.hyper.newParse(animationDefintion.on).value
        if (triggerValue === undefined) {
          this.tracing.info(this, 'could not read trigger value')
          return
        }
        // stringify true and fals
        if ([true, false].includes(triggerValue)) {
          triggerValue = triggerValue.toString()
        }
        // get the css for the current state from animation definition
        const targetCssAtInit = animationDefintion.case[triggerValue]

        if (!targetCssAtInit) {
          this.tracing.info(this, 'COULD NOT find initial css for animation')
          return
        }
        // format
        const formattedCssAtInit: any = this.formatCSS(targetCssAtInit)
        // is so, apply that case css!
        /**
         * Refactor. We want this to go into [css] and be applied as normal. so that class names are there etc.
         */
        if (formattedCssAtInit) {
          for (const key of Object.keys(formattedCssAtInit)) {
            this.el.nativeElement.style[key] = formattedCssAtInit[key]
          }
          this.tracing.info(this, `applied animation state css for case ${triggerValue}`)
        }

        // subscribe to trigger, only if we havn't yet
        if (this.triggerSubscriptions[css.animate.on]) {
          this.tracing.info(this, `already subscribed to trigger ${css.animate.on}`)
          return
        }

        const triggerSubscription = this.tracing.data$
          .pipe(
            tap((data) => {
              console.log('compare', data.fullPath, animationDefintion.on)
            }),
            filter((data) => data.fullPath === animationDefintion.on)
          )
          .subscribe((dataEvent) => {
            this.tracing.info(this, `an animation trigger ${animationDefintion.on} changed to ${dataEvent.newValue}`)

            // in case new value is a boolean, convert it to 'true' or 'false' strings
            let newValue = dataEvent.newValue
            // stringify true and false
            if ([true, false].includes(dataEvent.newValue)) {
              newValue = dataEvent.newValue.toString()
            }
            // look up the corresponding css
            if (animationDefintion.case[newValue]) {
              console.log('animate to!', animationDefintion.case[newValue])
              const targetCssDef = animationDefintion.case[newValue]
              if (!targetCssDef) {
                alert('no css')
              }

              // create starter (rendered) and target (defined) css
              const starterCss: any = {}
              const targetCss: any = {}
              Object.keys(targetCssDef)
                .filter((key) => key !== 'path')
                .forEach((key) => {
                  // break of code_zero notation
                  const [property, unit] = key.split('_')

                  // get currently rendered value for this iteration key (width: 500px)
                  // this.el.nativeElement.getAttribute(property) || window.getComputedStyle(this.el.nativeElement)[property]
                  // new way to get starter values: just read (inline) style
                  const starterValue = this.el.nativeElement.style[property]
                  // break it into readable format [500, px]
                  const [starterValueNumber, starterValueUnit] = this.parseUnit(starterValue)

                  // create starter definition
                  starterCss[property] = {
                    value: starterValueNumber,
                    unit: starterValueUnit,
                  }
                  targetCss[property] = {
                    value: targetCssDef[key],
                    unit: unit,
                  }
                })

              const easingName = animationDefintion.transition.easing
              const duration = +animationDefintion.transition.duration_ms

              // create a stream
              const animationStream$ = this.animation.animateEasing(easingName, duration)

              // register this animation
              this.tracing.registerAnimation({
                stream$: animationStream$,
                trigger: dataEvent.fullPath,
                definition: animationDefintion,
                context: this.animation,
              })

              // animate yourself
              const animationSubscription = animationStream$.subscribe((animationProgress) => {
                Object.keys(targetCssDef)
                  .filter((key) => key !== 'path')
                  .forEach((key) => {
                    const [property, unit] = key.split('_')
                    if (!targetCss[property]) {
                      console.warn('no thing', targetCss, property)
                      return
                    }
                    const delta = targetCss[property].value - starterCss[property].value
                    const progress = delta * animationProgress.easingProgressDecimal
                    const stepValue = starterCss[property].value + progress
                    // apply style (css or svg)
                    this.el.nativeElement.style[property] = stepValue + (unit ? unit : '')
                  })
              })
              // record youself
              this.animationSubscriptions.push(animationSubscription)
            }
          })

        // record this trigger subscription
        this.triggerSubscriptions[css.animate.on] = triggerSubscription
      }
    })
  }

  @traced({
    why: 'we write css rules in a different way that we can apply them',
    how: 'formats style dictionary',
    disabled: true,
  })
  formatCSS(css: any) {
    if (!utility.isObject(css)) {
      console.warn('this css is not an object', this.css)
      const parsed = this.hyper.newParse(css)
      css = parsed.value
      console.log('tried to parse', parsed)
      if (!utility.isObject(css)) {
        console.warn('cannot format css non-object', css)
        return
      }
    }
    const formattedCSS: any = {}
    for (const key of Object.keys(css)) {
      let value = css[key]
      if (utility.isString(value)) {
        if (this.hyper.isDotstring(value)) {
          value = this.hyper.newParse(value).value
          // also subscribe to this dotstring TODO
        }
        const unitlessValue = value
        const unit = key.split('_')[1] || ''
        const newKey = key.split('_')[0] || key
        const newValue = unitlessValue + unit
        formattedCSS[newKey] = newValue
      }
    }
    return formattedCSS
  }

  @traced({
    why: `We want to have a direct connection to our style. This allows us to cmd click on an element,
      the effect being that our css' and info like box position get send to tracing service, liveCssData
    `,
  })
  attachControlClickStream() {
    const nativeEvent$ = fromEvent(this.el.nativeElement, 'click').pipe(filter((event: KeyboardEvent) => event.metaKey || event.ctrlKey))
    nativeEvent$.subscribe(this.controlClick$)
    this.controlClick$.subscribe((event: KeyboardEvent) => {
      this.onControlClick(event)
    })
  }

  @traced({
    why: `We want an intimate connection between the developer and the system. Here we react to
          a mouse click with the cmd key down. We find the `,
  })
  onControlClick(event: KeyboardEvent) {
    const box = this.el.nativeElement.getBoundingClientRect() as BoundingBox
    ;(this.css as CssDefinition[]).forEach((css) => {
      const liveCSS: LiveCSS = {
        css,
        box,
        editorPosition: this.ui.getGoodEditorPosition(box),
        boxPosition: this.ui.getBoxPositions(box),
        active: false,
      }
      this.tracing.liveCssData.push(liveCSS)
    })
  }

  @doc({
    why: `we get a css string back like '500px, we return [500, 'px']`,
  })
  parseUnit(str: string, out = [0, '']) {
    str = String(str)
    const match = str.match(/[\d.\-\+]*\s*(.*)/)
    const num = parseFloat(str)
    out[0] = num
    out[1] = match ? match[1] : ''

    return out
  }
}
