<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.47208 29.03H7.23608V31.266H9.47208V29.03Z" [class]="'palette-1-' + env" />
  <path d="M7.236 29.03H5V31.266H7.236V29.03Z" [class]="'palette-2-' + env" />
  <path d="M11.706 29.03H9.46997V31.266H11.706V29.03Z" [class]="'palette-3-' + env" />
  <path d="M13.9421 29.03H11.7061V31.266H13.9421V29.03Z" [class]="'palette-4-' + env" />
  <path d="M11.706 33.5H9.46997V35.736H11.706V33.5Z" [class]="'palette-1-' + env" />
  <path d="M9.47208 33.5H7.23608V35.736H9.47208V33.5Z" [class]="'palette-2-' + env" />
  <path d="M13.9421 33.5H11.7061V35.736H13.9421V33.5Z" [class]="'palette-3-' + env" />
  <path d="M11.706 24.5601H9.46997V26.7961H11.706V24.5601Z" [class]="'palette-1-' + env" />
  <path d="M9.47208 24.5601H7.23608V26.7961H9.47208V24.5601Z" [class]="'palette-2-' + env" />
  <path d="M13.9421 24.5601H11.7061V26.7961H13.9421V24.5601Z" [class]="'palette-3-' + env" />
  <path d="M16.1779 24.5601H13.9419V26.7961H16.1779V24.5601Z" [class]="'palette-4-' + env" />
  <path
    d="M36.2939 22.3259H36.2379C35.6169 16.1859 30.1359 11.7109 23.9949 12.3309L23.9889 12.3319C18.7079 12.8679 14.5309 17.0439 13.9959 22.3259H16.2459C16.8709 17.4269 21.3489 13.9629 26.2479 14.5879H26.2499C30.7079 15.1569 34.0509 18.9469 34.0599 23.4419V24.5599H36.2949C38.7639 24.5589 40.7659 26.5579 40.7679 29.0269V29.0279C40.7679 31.4969 38.7679 33.4989 36.2989 33.4999H13.9419V35.7359H36.2919C39.9949 35.7999 43.0479 32.8499 43.1119 29.1469V29.1459C43.1769 25.4439 40.2269 22.3899 36.5249 22.3259H36.5229C36.4479 22.3239 36.3709 22.3239 36.2939 22.3259Z"
    [class]="'palette-5-' + env" />
</svg>
