<flex-col class="container">
  @if (proficloud.app.application.name === 'proficloud') {
    <div class="topBar">
      <!-- Logo -->
      <div class="topBarLeft">
        <app-proficloud-logo></app-proficloud-logo>
      </div>
    </div>
  }

  @if (proficloud.app.application.name === 'chargeRepay') {
    <flex-row-center style="background: white; padding: 20px">
      <img style="width: 500px" src="/assets/charge-repay/images/charge-repay-logo.svg" />
    </flex-row-center>
  }

  <div class="bottom">
    <flex-col class="form">
      <div class="title">{{ 'SHARED.REGISTRAION_TITLE' | translate }}</div>
      @if (proficloud.app.application.name === 'proficloud') {
        <div class="subTitle">
          {{ 'SHARED.REGISTRATION_SUBTITLE' | translate }}
        </div>
      }
      <flex-col class="inputs">
        <form [formGroup]="registerForm">
          @for (field of registerFields; track field) {
            <div class="input">
              @if (field.type === 'select') {
                <pc-input-select [config]="field" [form]="registerForm"></pc-input-select>
              }
              @if (field.type === 'text') {
                <pc-input-textfield [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-textfield>
              }
              @if (field.type === 'password') {
                <pc-input-password [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-password>
              }
            </div>
          }
          @if (registerForm.get('password')?.errors || registerForm.get('confirmPassword')?.errors) {
            @if (registerForm.get('password')?.errors?.lowerCaseError) {
              <div class="password-message-error">
                {{ registerForm.get('password')?.errors?.lowerCaseError }}
              </div>
            }
            @if (registerForm.get('password')?.errors?.upperCaseError) {
              <div class="password-message-error">
                {{ registerForm.get('password')?.errors?.upperCaseError }}
              </div>
            }
            @if (registerForm.get('password')?.errors?.numberError) {
              <div class="password-message-error">
                {{ registerForm.get('password')?.errors.numberError }}
              </div>
            }
            @if (registerForm.get('password')?.errors?.specialCharacterError) {
              <div class="password-message-error">
                {{ registerForm.get('password')?.errors?.specialCharacterError }}
              </div>
            }
            @if (registerForm.get('password')?.errors?.minlength) {
              <div class="password-message-error">
                {{ 'SHARED.PASSWORD_POLICY_TOO_SHORT' | translate }}
              </div>
            }
            @if (registerForm.get('password')?.errors?.maxlength) {
              <div class="password-message-error">
                {{ 'SHARED.PASSWORD_POLICY_TOO_LONG' | translate }}
              </div>
            }
            @if (registerForm.get('confirmPassword')?.errors.passwordNotMatchingError) {
              <div class="password-message-error">
                {{ registerForm.get('confirmPassword')?.errors.passwordNotMatchingError }}
              </div>
            }
          }
          <div>
            <mat-checkbox class="checkbox" #termsAndConditions [formGroup]="registerForm" formControlName="termsAndConditions"> </mat-checkbox>
            &nbsp;<span [innerHTML]="'SHARED.REGISTRATION_TERMS_AND_CONDITIONS' | translate"></span>
          </div>
          <div>
            <pc-button [disabled]="!registerForm.valid" [wide]="true" [text]="'SHARED.REGISTRATION_ACTION' | translate" (btnClick)="register()"></pc-button>
          </div>
        </form>
      </flex-col>
    </flex-col>
  </div>
</flex-col>
