import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpBaseService } from '@services/http-base.service'
import {
  CreateDevicePostData,
  Device,
  DeviceInfo,
  DeviceListResponse,
  DeviceMetaData,
  LastTrafficlightsResponse,
  LogsResponse,
  MetadataResponse,
} from '@services/proficloud.interfaces'
import { AppService } from 'src/app/app.service'
import { DeviceTypeInformationResponse, ServiceInformationResponse } from '../services/device.service'

@Injectable({
  providedIn: 'root',
})
export class DeviceManagementHttpService extends HttpBaseService {
  constructor(
    protected override app: AppService,
    private httpClient: HttpClient
  ) {
    super(app)
  }

  public getOrganisationDevices(organisationId: string) {
    const url = `${this.backendUrls.deviceLifecycleUrl}/endpoints?limit=0&include=metadata&metadataFilter=${encodeURIComponent(
      '{"organizationId":"' + organisationId + '"}'
    )}`

    return this.httpClient.get<DeviceListResponse>(url)
  }

  public fetchDeviceLogs(fromDate: Date, toDate: Date, device: DeviceInfo) {
    const metricName = 'con_logs'
    const fromDate_str = fromDate.toISOString()
    const toDate_str = toDate.toISOString()

    if (!device.customLogs || !device.customLogs.range) {
      console.warn('Cust logs not present')
      return
    }
    device.customLogs.range.last_end = new Date(device.customLogs.range.end)
    device.customLogs.range.last_start = new Date(device.customLogs.range.start)

    // eslint-disable-next-line max-len
    const url = `${this.backendUrls.apiTsdBaseUrl}/applications/proficloud/time-series/data?endpointId=${device.endpointId}&timeSeriesName=${metricName}&fromDate=${fromDate_str}&toDate=${toDate_str}`

    return this.httpClient.get<LogsResponse>(url)
  }

  public addDeviceLogEntry(device: DeviceInfo, entry: { message: string } = { message: 'how u doing?' }) {
    const endpointId = device.endpointId
    const payload = {
      [endpointId]: {
        con_logs: [
          {
            timestamp: new Date().toISOString(),
            values: {
              con_LogLevel: 1,
              con_LogMessage: entry.message,
              con_LogTags: 'User.submitted.entry',
            },
          },
        ],
      },
    }
    const url = `${this.backendUrls.apiTsdBaseUrl}/applications/proficloud/time-series/data`

    return this.httpClient.put(url, payload)
  }

  public createDevice(createData: CreateDevicePostData, virtual: boolean) {
    let deviceUrl = `${this.backendUrls.middlewareUrl}/device`

    if (virtual) {
      deviceUrl = `${this.backendUrls.middlewareUrl}/device/virtual-device`
    }

    return this.httpClient.post<HttpResponse<{ data: any }>>(
      deviceUrl,
      {
        organizationId: createData.organizationId,
        appVersion: {
          name: 'proficloud-v1',
        },
        metadata: {
          deviceName: createData.deviceName,
          comment: createData.comment,
          uuid: createData.uuid,
          lat: createData.lat,
          lng: createData.lng,
          address: createData.address,
          tags: createData.tags,
        },
      },
      { observe: 'response' }
    )
  }

  public deleteDevice(device: Device) {
    const url = `${this.backendUrls.middlewareUrl}/device`

    const body = {
      endpointId: device.endpointId,
      uuid: device.metadata.uuid,
    }

    return this.httpClient.delete(url, { body: body })
  }

  public updateDeviceDetail(endpointId: string, deviceFields: DeviceMetaData) {
    const url = `${this.backendUrls.middlewareUrl}/device`
    const payload = {
      endpointId,
      fields: deviceFields,
    }
    return this.httpClient.put<null>(url, payload)
  }

  public getAllDevicesMetaData() {
    const url = `${this.backendUrls.deviceLifecycleUrl}/endpoints?include=metadata&limit=0`
    return this.httpClient.get<MetadataResponse>(url)
  }

  public getDeviceServicesInfo() {
    return this.httpClient.get<ServiceInformationResponse>(this.backendUrls.DeviceTypeInformationUrl + '/services')
  }

  public getDeviceTypeInfo() {
    return this.httpClient.get<DeviceTypeInformationResponse>(this.backendUrls.DeviceTypeInformationUrl + '/device-information')
  }

  public getLastDeviceHealthStatus(endpointIds: string, metricName: string) {
    const url = `${this.backendUrls.apiTsdBaseUrl}/applications/proficloud/time-series/last?endpointId=${endpointIds}&timeSeriesName=${metricName}`
    return this.httpClient.get<LastTrafficlightsResponse>(url)
  }
}
