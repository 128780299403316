import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ProficloudService } from '@services/proficloud.service'

@Component({
  selector: 'app-tester-login',
  template: `
    <flex-row-center-center id="loginMessage" style="width: 100vw; height: 100vh; background: white;">
      <div id="message">{{ message }}</div>
    </flex-row-center-center>
  `,
  styleUrls: ['./tester-login.component.scss'],
})
export class TesterLoginComponent implements OnInit {
  message = 'hello tester'

  constructor(
    private route: ActivatedRoute,
    public proficloud: ProficloudService
  ) {}

  ngOnInit() {
    const email = this.route.snapshot.queryParamMap.get('email')
    const password = this.route.snapshot.queryParamMap.get('password')

    if (!email || !password) {
      return
    }

    this.message = 'logging you in'
    this.proficloud.login(email, password).subscribe(() => {
      this.message = 'you are now logged in'
    })
  }
}
