import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-proficloud-notification-bar',
  templateUrl: './proficloud-notification-bar.component.html',
  styleUrls: ['./proficloud-notification-bar.component.scss'],
})
export class ProficloudNotificationBarComponent {
  @Input() level: string
}
