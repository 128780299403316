import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PCButtonComponent } from '../pc-button/pc-button.component'
import { DropDownAnimation, RotateAnimation, RotateAnimationUpward } from './pc-icon-dropdown.animation'

export interface IPCIconListGroup {
  id?: string
  label?: string
  items: IPCIconListItem[]
}

export interface IPCIconListItem {
  value: string
  name: string
  selectable?: boolean
  selected?: boolean
}

export interface SelectionEvent {
  item: IPCIconListItem
  descending?: boolean
}

@Component({
  selector: 'pc-icon-dropdown',
  templateUrl: './pc-icon-dropdown.component.html',
  styleUrls: ['./pc-icon-dropdown.component.scss'],
  animations: [DropDownAnimation, RotateAnimation, RotateAnimationUpward],
})
export class PCIconDropdownComponent extends PCButtonComponent implements OnInit {
  @Output() selection: EventEmitter<SelectionEvent> = new EventEmitter<SelectionEvent>()

  // Icon Settings of the first icon (left side)
  @Input() iconName = 'arrowDown'

  // There is no initial icon before the text
  @Input() noPrefix = false

  // Icon only mode hides the text and the postfix icon
  @Input() override iconOnly = false

  @Input() items: IPCIconListGroup[] = []

  @Input() selectedItem: IPCIconListItem

  isOpen = false

  descending = true

  ngOnInit(): void {
    if (!this.items || this.items.length < 1) {
      this.disabled = true
    }
  }

  toggle = () => {
    this.isOpen = !this.isOpen
  }

  selectItem(item: IPCIconListItem) {
    // close menu after selecting item
    this.toggle()

    // check if the same value got selected twice in order to change the descending state
    if (this.selectedItem === item) {
      this.descending = !this.descending
    }

    // selection item for view and trigger selection action for listener
    this.selectedItem = item

    const selectionEvent: SelectionEvent = {
      item: item,
      descending: this.descending,
    }

    this.selection.emit(selectionEvent)
  }

  checkedItem(item: IPCIconListItem) {
    this.selection.emit({ item: item })
  }
}
