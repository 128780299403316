import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { DropDownItem } from '@services/proficloud.interfaces'
import { UiService } from '@services/ui/ui.service'

// TODO: This needs to be deprecated! Perhaps in favour of one of the PC components like pc-icon-dropdown
@Component({
  selector: 'app-proficloud-drop-down',
  templateUrl: './proficloud-drop-down.component.html',
  styleUrls: ['./proficloud-drop-down.component.scss'],
})
export class ProficloudDropDownComponent implements OnInit {
  @Input() items: DropDownItem[]

  @Input()
  size: 'regular' | 'minimal' = 'regular'

  @Input()
  left: boolean

  @Input()
  wideRight: boolean

  @ViewChild('subMenu')
  subMenu: ElementRef

  subMenuItems: DropDownItem[]
  // needs to be replaced once the burger menu is introduced
  subMenusExist: boolean

  topOffset: number

  @Output() onItemClicked: EventEmitter<any> = new EventEmitter()

  constructor(public ui: UiService) {}

  public onMenuIconClick(event: MouseEvent, item: DropDownItem) {
    if (item.subItems) {
      const target = event.target as HTMLElement
      const menuOffset = document.getElementsByClassName('sort-options')[0].getBoundingClientRect().y
      const itemOffset = target.getBoundingClientRect().y
      this.subMenuItems = item.subItems
      event.stopPropagation()
      setTimeout(() => {
        this.topOffset = itemOffset - menuOffset
      })
      return
    }

    if (!item.disabled) {
      this.onItemClicked.emit(item)
    }

    event.stopPropagation()
  }

  ngOnInit(): void {
    // Checks if there are submenus inside the items which can be removed once a separate menu exists
    // for simple menu cases.
    let submenusExist = false
    this.items.forEach((i) => {
      if (i.subItems) {
        submenusExist = true
      }
    })

    this.subMenusExist = submenusExist
  }
}
