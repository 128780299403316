import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-checkbox-toggle',
  templateUrl: './checkbox-toggle.component.html',
  styleUrls: ['./checkbox-toggle.component.scss'],
})
export class CheckboxToggleComponent {
  @Input()
  colour: string

  @Input()
  originalColour: string

  @Input()
  toggleHandler: (index: number, checked: boolean, key: string | number) => void

  @Input()
  checked = true

  @Input()
  disabled = false

  @Input()
  className: string

  @Input()
  index: number

  @Input()
  key: number | string
}
