import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-colour-picker',
  templateUrl: './pc-input-colour-picker.component.html',
  styleUrls: ['./pc-input-colour-picker.component.scss'],
})
export class PCInputColourPickerComponent extends PcInputBaseComponent {
  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  @Input() value: string

  @Input() control: UntypedFormControl

  @Input() inputID: string

  inputFieldChanged(event: Event) {
    const value = (event.target as unknown as HTMLInputElement).value
    this.valueChanged.emit(value)
  }
}
