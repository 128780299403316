// angular
import { Pipe, PipeTransform } from '@angular/core'
// marked
import * as marked from 'marked'

@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  transform(value: string): unknown {
    if (value && value.length > 0) {
      return marked.parse(value)
    }
    return value
  }
}
