import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { TracingService } from 'src/app/modules/shared/services/tracing/tracing.service'
import { traced } from '../decorators/trace-decorator'
import { ClassDoc } from './../services/tracing/tracing.interfaces'

@Component({
  selector: 'app-reactive-element',
  template: `
    <!-- container case: div with children -->
    {{ data.propertyName }}
    @for (child of children; track child) {
      <app-reactive-element [css]="child.parent[child.propertyName]" [data]="child"></app-reactive-element>
    }
  `,
})
export class ReactiveElementComponent implements OnInit, AfterViewInit {
  classDoc: ClassDoc = {
    name: 'ReactiveElementComponent',
    location: '/src/app/modules/shared/reactive-element/reactive-element.component.ts',
    why: 'Data is boss. Components get rendered at datas command.',
    how: `
      - we need to know our property name
    `,
  }

  // @tracked()
  @Input() data: {
    parent: any
    propertyName: string
  }

  // @tracked()
  children: any = []

  // @tracked()
  definition: any // your own css and wording

  constructor(
    // angular
    public cd: ChangeDetectorRef,
    // app
    public tracing: TracingService
  ) {
    this.constructReactiveElementComponent()
  }

  /* @watched()
  suchChange = this.tracing.data$.pipe(
    filter((data) => data.parent === this.definition),
    tap(data => {
      this.getChildren()
    })
  ) */

  @traced()
  constructReactiveElementComponent() {
    this.tracing.registerInstance(this)
  }

  @traced()
  ngOnInit() {
    this.definition = this.data.parent[this.data.propertyName]
    this.getChildren()
    this.tracing.info(this, 'inited with children', this.children)
  }

  @traced()
  ngAfterViewInit() {
    this.reactToDataChange()
  }

  @traced()
  getChildren() {
    this.children = []
    Object.keys(this.definition).forEach((key) => {
      const item = this.definition[key]
      if (this.tracing.isObject(item)) {
        this.children.push({
          parent: this.definition,
          propertyName: key,
        })
      }
    })
    return this.children
  }

  @traced()
  reactToDataChange() {
    this.tracing.data$.subscribe((dataMessage) => {
      console.log('--- check if change is for me', this.data.propertyName, dataMessage.parent)
      if (dataMessage.parent === this.data.parent) {
        console.log('--- it is!')

        this.tracing.info(this, 'this reactive element reacts:', this.data.propertyName)
        this.getChildren()
        this.detect()
      }
    })
  }

  @traced()
  detect() {
    this.cd.detectChanges()
  }
}
