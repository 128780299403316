@if (form | inputIsActive: config) {
  <mat-form-field class="input-chiplist" [class.input-chiplist--small]="size === 'small'" [class.input-chiplist--fit-width]="variant === 'fit-width'">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid #chipGrid aria-label="{{ placeholder }}">
      @for (tag of inputValue; track tag) {
        <mat-chip-row (removed)="removeTag(tag)">
          {{ tag }}
          <button matChipRemove [attr.aria-label]="'remove ' + tag">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        [formControl]="control"
        [placeholder]="placeholder"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addTag($event.target, $event.target.value)" />
    </mat-chip-grid>
  </mat-form-field>
}
