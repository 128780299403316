@if (!isMulti(config) && (form | inputIsActive: config)) {
  <mat-form-field [class.input-select--standalone]="standAlone" [subscriptSizing]="standAlone ? 'dynamic' : 'fixed'" style="width: 100%">
    <mat-label>{{ config?.placeholder ?? 'placeholder' }}</mat-label>
    <mat-select
      [placeholder]="config?.placeholder ?? 'placeholder'"
      [formControl]="config?.control"
      (focus)="hasFocus = true"
      (blur)="hasFocus = false"
      (selectionChange)="config.value = $event.value; fieldInput.next($event.value); config.changed ? config.changed($event.value) : null"
      [id]="config.inputId">
      @for (item of visibleSelectOptions(config?.selectValues); track item) {
        <mat-option [value]="item.key">
          {{ item.value }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
}
