export const hyper = {
  key: 'hyper',
  location: '/src/app/modules/shared/services/hyper/hyper.data.ts',
  styles: {
    path: ['hyper', 'styles'],
    container__state: 'expanded',
    inputText: {
      path: ['hyper', 'styles', 'inputText'],
      fontSize_px: '14',
      background: 'transparent',
      padding: '5px 0px',
      whiteSpace: 'nowrap',
    },
    inputText__state: 'expanded',
    inputInput: {
      path: ['hyper', 'styles', 'inputInput'],
      outline: 'none',
      position: 'absolute',
      top: '0px',
      left: '1px',
      border: '0',
      zIndex: '2',
      color: 'transparent',
      caretColor: 'black',
      width: '100%',
      paddingLeft_px: '10',
    },
    inputInput__state: 'expanded',
    component__state: 'expanded',
    suggestions: {
      path: ['hyper', 'styles', 'suggestions'],
      selected__state: 'expanded',
      selected: {
        path: ['hyper', 'styles', 'suggestions', 'selected'],
        fontWeight: '400',
        background: 'skyblue',
        color: 'white',
        border: '1px solid skyblue',
        padding: '4px',
      },
      unselected: {
        path: ['hyper', 'styles', 'suggestions', 'unselected'],
        fontWeight: 'normal',
        border: '1px solid #ddd',
        padding: '4px',
        color: '#333',
      },
      unselected__state: 'expanded',
    },
    suggestions__state: 'collapsed',
    previewValue: {
      path: ['hyper', 'styles', 'previewValue'],
      color: 'gray',
      marginLeft: '10px',
      fontStyle: 'italic',
      fontSize_px: '13',
    },
    previewValue__state: 'collapsed',
    fragment: {
      path: ['hyper', 'styles', 'fragment'],
      color: 'skyblue',
    },
    fragment__state: 'collapsed',
    parsedValue: {
      path: ['hyper', 'styles', 'parsedValue'],
      fontStyle: 'normal',
      color: '#333',
      marginLeft: '10px',
      fontSize_px: '13',
    },
    parsedValue__state: 'expanded',
    dotLink: {
      path: ['hyper', 'styles', 'dotLink'],
      background: '#eee',
      padding: '5px',
      color: 'navy',
      textDecoration: 'none',
    },
    dotLink__state: 'collapsed',
    inputCanvas: {
      path: ['hyper', 'styles', 'inputCanvas'],
      background: '#f5f5f5',
      paddingLeft_px: '10',
      paddingRight_px: '10',
      display: 'flex',
      position: 'relative',
    },
    inputCanvas__state: 'expanded',
    expander: {
      minWidth: '120px',
      path: ['hyper', 'styles', 'expander'],
      display: 'flex',
    },
    expander__state: 'collapsed',
    test__state: 'expanded',
  },
  styles__state: 'expanded',
}
