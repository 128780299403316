export const tracingData = {
  key: 'tracingData',
  location: '/src/app/modules/shared/services/tracing/tracing.data.ts',
  classColors: {
    'Atomic Graph Component': {
      background: '#3cb44b',
      color: 'white',
      name: 'green',
      path: ['tracingData', 'classColors', 'Atomic Graph Component'],
    },
    'Shared UI Service': {
      background: '#4363d8',
      color: 'white',
      name: 'blue',
      path: ['tracingData', 'classColors', 'Shared UI Service'],
    },
    'Object Service': {
      background: '#333',
      color: 'white',
      name: 'dark gray',
      path: ['tracingData', 'classColors', 'Object Service'],
    },
    'ESZ Data Service': {
      background: '#800000',
      color: 'white',
      name: 'maroon',
      path: ['tracingData', 'classColors', 'ESZ Data Service'],
    },
    'API sink component': {
      background: '#469990',
      color: 'white',
      name: 'Teal',
      path: ['tracingData', 'classColors', 'API sink component'],
    },
    'Object Internals Components': {
      background: '#f58231',
      color: 'white',
      name: 'orange',
      path: ['tracingData', 'classColors', 'Object Internals Components'],
    },
    'Dynamic Data Service': {
      background: '#911eb4',
      color: 'white',
      name: 'purple',
      path: ['tracingData', 'classColors', 'Dynamic Data Service'],
    },
    'App Component': {
      path: ['tracingData', 'classColors', 'App Component'],
      background: '#333',
      color: 'white',
    },
    'App Component__state': 'expanded',
    path: ['tracingData', 'classColors'],
  },
  lane: {
    background: 'hotpink',
    width: '100vw',
    display: 'flex',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '150px',
    path: ['tracingData', 'lane'],
  },
  change: {
    container: {
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '12px',
      border: '10px solid lightblue',
      height: '150px',
      width: '150px',
      minWidth: '150px',
      path: ['tracingData', 'change', 'container'],
    },
    nature: {
      fontWeight: '500',
      path: ['tracingData', 'change', 'nature'],
    },
    extraNature: {
      'font-style': 'italic',
      path: ['tracingData', 'change', 'extraNature'],
    },
    previousValue: {
      color: 'red',
      'text-decoration': 'line-through',
      path: ['tracingData', 'change', 'previousValue'],
    },
    newValue: {
      color: 'blue',
      path: ['tracingData', 'change', 'newValue'],
    },
    key: {
      color: 'orange',
      path: ['tracingData', 'change', 'key'],
    },
    propertyName: {
      color: '#333',
      path: ['tracingData', 'change', 'propertyName'],
    },
    path: ['tracingData', 'change'],
  },
  __state: 'expanded',
  classColors__state: 'collapsed',
}
