<div class="content__tabs" [class.sub-section]="subSection" [class.emma]="emma">
  @for (tab of tabs; track tab; let i = $index) {
    <div
      class="content__tab"
      [class.content__tab--emma]="emma"
      [class.content__tab--selected]="selectedTab === i"
      [class.sub-section]="subSection"
      (click)="selectTab(i)">
      {{ tab.name }}
    </div>
  }
</div>

<div class="content__information-content" [class.emma]="emma" [class.sub-section]="subSection">
  <ng-content select="[default]"></ng-content>
  @for (tmpl of templates; track tmpl; let i = $index) {
    @if (i === selectedTab) {
      <ng-container [ngTemplateOutlet]="tmpl"></ng-container>
    }
  }
</div>
