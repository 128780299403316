<pc-overlay [config]="{ title: content.title, closeable: false }">
  <ng-container body>
    <flex-col style="width: 100%; margin-top: -8px; margin-bottom: 8px">
      @for (statusEntry of content.status; track statusEntry) {
        <flex-row-center style="margin-top: 16px">
          <!-- success icon -->
          @if (statusEntry.icon === 'success') {
            <app-icon
              name="successCircle"
              color="var(--success-green)"
              style="max-width: 58.67px; max-height: 58.67px; min-width: 58.67px; min-height: 58.67px"
              data-analytics="analytics-consequence-modal-headline-success-icon analytics-succcess"></app-icon>
          }
          <!-- error icon -->
          @if (statusEntry.icon === 'error') {
            <app-icon
              name="error"
              color="var(--error-red)"
              style="
                max-width: 68px;
                max-height: 68px;
                min-width: 68px;
                min-height: 68px;
                margin-left: -5px;
                margin-right: -4px;
                margin-top: -5px;
                margin-bottom: -5px;
              "
              data-analytics="analytics-consequence-modal-headline-success-icon analytics-error"></app-icon>
          }
          <!-- warning icon -->
          @if (statusEntry.icon === 'warning') {
            <app-icon
              name="warning"
              color="var(--warning-yellow)"
              style="width: 58.67px; height: 58.67px"
              data-analytics="analytics-consequence-modal-headline-success-icon analytics-attention"></app-icon>
          }
          @if (statusEntry.icon === 'spinner') {
            <mat-spinner [diameter]="58" style="max-width: 58px; max-height: 58px; min-width: 58px; min-height: 58px"> </mat-spinner>
          }
          <!-- message -->
          <span style="margin-right: auto; margin-left: 10px; display: inline-flex; align-items: center">{{ statusEntry.message }}</span>
        </flex-row-center>
      }
    </flex-col>
  </ng-container>
</pc-overlay>
