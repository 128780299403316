import { Pipe, PipeTransform } from '@angular/core'
import { format } from 'date-fns'

@Pipe({
  name: 'pcDate',
  pure: false,
})
export class PcDatePipe implements PipeTransform {
  transform(value: Date, ...args: unknown[]): unknown {
    let formatString = 'dd/MM/yyyy'
    if (args[0]) {
      formatString += ' HH:mm'
    }
    return format(value, formatString)
  }
}
