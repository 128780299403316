export const proficloudData = {
  key: 'proficloudData',
  location: '/@services/proficloud.data.ts',
  currentVersion: 'A',
  overlays: {
    accountCreation: {
      message: 'Your account is being set up.',
      icon: 'spinner',
    },
    accountCreationSuccess: {
      message: 'Your account has been created.',
      icon: 'check',
    },
    authenticating: {
      message: 'logging you in...',
      icon: 'spinner',
    },
    authenticationError: {
      message: 'that did not work - sorry',
      icon: 'cross',
      closeable: true,
    },
    authenticationSuccess: {
      message: 'nice - you are now logged in.',
      icon: 'check',
    },
  },
  signupCredentials: {
    termsAccepted: false,
    email: '',
    password1: '',
    password2: '',
  },
  ui: {
    authType: 'login',
  },
  colors: {
    A: '#2eb573',
    B: '#f0c514',
    C: '#e94c3d',
  },
  settings: {
    toggles: {
      exchangeToken: true,
      signupEnabled: true,
    },
  },
}
