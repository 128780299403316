import { Component, Input } from '@angular/core'

@Component({
  selector: 'pc-section',
  templateUrl: './pc-section.component.html',
  styleUrls: ['./pc-section.component.scss'],
})
export class PCSectionComponent {
  @Input()
  header: string
}
