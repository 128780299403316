<flex-col>
  @if (disabled) {
    <mat-checkbox [formControl]="control" [disabled]="true">
      {{ label }}
    </mat-checkbox>
  }
  @if (!disabled) {
    <mat-checkbox [formControl]="control">
      {{ label }}
    </mat-checkbox>
  }
  <div class="hint">
    {{ hint }}
  </div>
</flex-col>
