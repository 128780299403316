import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { UiService } from '@services/ui/ui.service'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-search',
  templateUrl: './pc-input-search.component.html',
  styleUrls: ['./pc-input-search.component.scss'],
})
export class PcInputSearchComponent extends PcInputBaseComponent implements OnInit {
  @Output() valueSelected: EventEmitter<string> = new EventEmitter()
  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  @Input() size: 'regular' | 'small' = 'regular'

  @Input() variant: 'fit-width'

  @Input() density: 'small' | 'x-small' | 'regular' = 'small'

  @Input() control: UntypedFormControl = new UntypedFormControl()

  @Input() placeholder?: string

  @Input() inputID: string

  @Input() analyticsID: string

  @Input()
  filterableItems: { key: string; value: string; visible: boolean }[]

  expanded = false

  filterCriteria: string

  contentLeft = '0px'

  contentTop = '0px'

  contentWidth = '200px'

  @ViewChild('container')
  container: ElementRef

  @ViewChild('inner')
  containerInner: ElementRef

  preselected: number = 0

  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.filterableItems) {
      return
    }
    let posChanged = false
    if (event.key === 'ArrowUp') {
      this.preselected = Math.max(this.preselected - 1, 0)
      posChanged = true
    }
    if (event.key === 'ArrowDown') {
      this.preselected = Math.min(this.preselected + 1, this.filterableItems.filter((x) => x.visible).length - 1)
      posChanged = true
    }
    if (posChanged && this.containerInner && this.containerInner.nativeElement.children[this.preselected]) {
      this.containerInner.nativeElement.children[this.preselected].scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      })
    }
    if (event.key === 'Enter') {
      let visiblePos = 0
      for (let item of this.filterableItems) {
        if (item.visible) {
          if (visiblePos == this.preselected) {
            this.itemClicked(item)
          }
          visiblePos += 1
        }
      }
    }
  }

  constructor(private ui: UiService) {
    super()
  }

  ngOnInit(): void {
    this.placeholder = this.placeholder

    this.control.valueChanges.subscribe((value) => {
      this.valueChanged.emit(value)
    })
  }

  inputFieldChanged(event: Event) {
    const value = (event.target as unknown as HTMLInputElement).value
    if (value !== '') {
      this.updateFilterCriteria(value)
      this.expand()
    } else {
      this.collapse()
    }
  }

  resetInput() {
    this.control.setValue('')
  }

  public collapse() {
    this.expanded = false
  }

  public expand() {
    if (!this.filterableItems?.length) {
      return
    }
    this.expanded = true

    // Note: Couldn't figure out how to position the options with pure CSS
    setTimeout(() => {
      const rect = this.container.nativeElement.getBoundingClientRect()
      const paddingTop = 0
      const paddingLeft = 0
      const scrollTop = this.container.nativeElement.offsetParent.scrollTop
      this.contentTop = rect.y - this.container.nativeElement.offsetParent.getBoundingClientRect().y + paddingTop + scrollTop + 'px'
      this.contentLeft = rect.x - this.container.nativeElement.offsetParent.getBoundingClientRect().x - paddingLeft + 'px'
      this.contentWidth = rect.width + 30 + 'px'
    })
  }

  public itemClicked(item: { key: string; value: string; visible: boolean }) {
    this.valueSelected.emit(item.key)
    this.collapse()
  }

  public updateFilterCriteria(criteria: string) {
    this.filterCriteria = criteria
    if (criteria.length) {
      this.filterableItems?.forEach((subItem) => {
        subItem.visible = subItem.value.toLowerCase().indexOf(criteria.toLowerCase()) >= 0
      })
    } else {
      this.filterableItems?.forEach((subItem) => {
        subItem.visible = true
      })
    }
  }

  public onClickOutside() {
    this.collapse()
  }

  public onInputClick() {
    if (this.control.value) {
      this.expand()
    }
  }
}
