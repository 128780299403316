import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'pc-list-simple-item',
  templateUrl: './pc-list-simple-item.component.html',
  styleUrls: ['./pc-list-simple-item.component.scss'],
})
export class PCListSimpleItemComponent {
  @Output() selected: EventEmitter<Number> = new EventEmitter<Number>()

  @Input() title = 'XXXXXX'

  @Input() subTitle = 'XXXXXX'

  @Input() active = false

  @Output() activeChanged = new EventEmitter<any>()

  @Input() index = 0

  @Input() currentSelectedIndex = 0

  toggleActive(index: any) {
    // toggles with Radiobutton behaviour: it toggles not, if same list-item is clicked again
    if (this.currentSelectedIndex == index) return
    this.active = !this.active
    this.selected.emit(index)
  }
}
