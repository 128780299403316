import { Injectable } from '@angular/core'
import { ClassDoc } from '../../shared/services/tracing/tracing.interfaces'
import { Organisation, OrganisationRole } from './proficloud.interfaces'

@Injectable({
  providedIn: 'root',
})
export class AuthorisationService {
  classDoc: ClassDoc = {
    name: 'AuthorisationService',
    location: '/@services/authorisation.service.ts',
  }

  public roleLabels: Record<OrganisationRole, string> = {
    ROLE_ADMIN: 'Admin',
    ROLE_VIEWER: 'Viewer',
    ROLE_EDITOR: 'Editor',
  }

  public getOrganisationRole = (roleLable: string) => {
    switch (roleLable) {
      case 'viewer':
        return OrganisationRole.ROLE_VIEWER
      case 'editor':
        return OrganisationRole.ROLE_EDITOR
      case 'admin':
        return OrganisationRole.ROLE_ADMIN
    }
  }

  public isAdmin(organisation: Organisation) {
    return organisation?.userRole === OrganisationRole.ROLE_ADMIN
  }

  public isEditor(organisation: Organisation) {
    return organisation?.userRole === OrganisationRole.ROLE_EDITOR
  }

  public isViewer(organisation: Organisation) {
    return organisation?.userRole === OrganisationRole.ROLE_VIEWER
  }
}
