// angular
import { MapsAPILoader } from '@agm/core'
import { ElementRef, Injectable } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
// rxjs
import { fromEvent, Observable, Subject } from 'rxjs'
// app
import { filter, tap } from 'rxjs/operators'
import { tracked } from 'src/app/modules/shared/decorators/track-decorator'
import { watched } from 'src/app/modules/shared/decorators/watch-stream-decorator'
import { TracingService } from 'src/app/modules/shared/services/tracing/tracing.service'
import { AppService } from '../../../../app.service'
import { traced } from '../../../shared/decorators/trace-decorator'
import { BoundingBox, ClassDoc } from '../../../shared/services/tracing/tracing.interfaces'
import { DeviceInfo, KeyMapping, MacKeyCodes, Panels, ProficloudInputConfig } from '../proficloud.interfaces'
import { design } from './ui.design-data'

@Injectable({
  providedIn: 'root',
})
export class UiService {
  public humansBase = '/assets/proficloud/images/humans/'

  classDoc: ClassDoc = {
    name: 'UiService',
    short: 'ui',
    location: '/@services/ui/ui.service.ts',
  }

  dashboardScrollRequired$ = new Subject<boolean>()

  popupPositionRecalculationRequired$ = new Subject<boolean>()

  menuOpened$ = new Subject<ElementRef>()

  // ui data
  @tracked()
  uiData = {
    key: 'uiData',
    isMobile: false,
    mobileBelow: 960,
    showMobileNav: false,
  }

  colors = {
    persianGreen: '#0098A1',
    blackHaze: '#EBECEC',
    whiteSmoke: '#F5F5F5',
    jetBlack: '#343434',
  }

  @tracked({
    what: 'all things that need designing',
    persist: {
      precedence: { dev: 'code', prod: 'code' },
    },
  })
  design = design

  routeTitle: string

  routeSubTitle: string

  routeServiceId: string

  routeExtra?: string

  geoCoder: google.maps.Geocoder

  superscriptCharacters: Record<number, string> = {
    0: '⁰',
    1: '¹',
    2: '²',
    3: '³',
    4: '⁴',
  }

  constructor(
    public app: AppService,
    public tracing: TracingService,
    // maps
    public mapsAPILoader: MapsAPILoader
  ) {
    this.constructUiService()
  }

  @traced()
  constructUiService() {
    this.tracing.registerInstance(this)
  }

  /**
   * resize
   */
  @watched()
  resize$ = new Subject<void>()

  /**
   *    User Event Streams
   */
  crypto = false

  @watched({
    when: `When the user presses a key on the keyboard down`,
    silent: true,
  })
  keydown$ = fromEvent(window, 'keydown') as Observable<KeyboardEvent>

  private mapping: KeyMapping = {
    KeyL: 'leftPanel',
    KeyR: 'rightPanel',
    KeyB: 'bottomPanel',
    ArrowLeft: 'leftPanel',
    ArrowRight: 'rightPanel',
  }

  @watched({
    when: 'a panel shortcut is pressed',
  })
  panelShortcutTab$ = this.keydown$.pipe(
    filter((event) => {
      const macCodes = Array.from(Object.keys(this.mapping))
      const linuxCodes = ['KeyB', 'ArrowLeft', 'ArrowRight']
      return (event.ctrlKey && linuxCodes.includes(event.code)) || (event.ctrlKey && macCodes.includes(event.code))
    }),
    tap((event) => {
      this.togglePanel(this.mapping[event.code as MacKeyCodes])
    })
  )

  @watched({
    when: 'a panel shortcut is pressed',
  })
  cryptoShortcut$ = this.keydown$.pipe(
    filter((event) => event.code === 'KeyC' && event.ctrlKey),
    tap((event) => {
      this.crypto = !this.crypto
    })
  )

  /**
   *      Functions
   */
  @traced()
  togglePanel(name: Panels) {
    this.app.appData[name].state = this.app.appData[name].state === 'expanded' ? 'collapsed' : 'expanded'
  }

  @traced()
  getBoxPositions(box: BoundingBox) {
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight
    const leftFromRight = viewportWidth - box.left
    const topFromBottom = viewportHeight - box.top
    return { leftFromRight, topFromBottom }
  }

  @traced()
  goodEditorPosition(box: BoundingBox) {
    // editor layout, needs to be tied somewhere
    const editorWidth = 300
    const editorHeight = 300

    // window = viewport siz
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    // right (preferred)
    const rightSpace = viewportWidth - box.left - box.width
    if (rightSpace > editorWidth) {
      return 'right'
    }
    // left (2nd option)
    if (box.left > editorWidth) {
      return 'left'
    }
    // bottom (3rd option)
    const bottomSpace = viewportHeight - box.top - box.height
    if (bottomSpace > editorHeight) {
      return 'bottom'
    }
    // top (4th option)
    if (box.top > editorHeight) {
      return 'top'
    }
    return 'inside'
  }

  @traced()
  getGoodEditorPosition(box: BoundingBox) {
    // dup
    const editorWidth = 300
    const editorHeight = 300

    // window = viewport siz
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    const position = this.goodEditorPosition(box)
    let left
    let right
    let top
    if (position === 'right') {
      left = box.left + box.width
      top = box.top
    }
    if (position === 'left') {
      right = viewportWidth - box.left + 20
      top = box.top
    }
    if (position === 'inside') {
      left = box.left
      top = box.top
    }
    if (position === 'bottom') {
      top = box.top + box.height
      left = box.left
    }
    if (position === 'top') {
      top = box.top - editorHeight
    }
    return { left, right, top }
  }

  @traced({
    why: `fullscreen is much more immersive`,
  })
  fullscreen() {
    const el: HTMLElement = document.documentElement
    el.requestFullscreen()
  }

  public isTouchScreen() {
    return navigator.userAgent.match(
      /Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i
    )
  }

  @traced()
  public setupLocationSearch(context: any, elementId: string, callback: (input: google.maps.places.Autocomplete) => void) {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder()
      const locationInput = document.getElementById(elementId)

      const autocomplete = new google.maps.places.Autocomplete(locationInput as HTMLInputElement, {
        types: ['address'],
      })
      autocomplete.addListener('place_changed', () => {
        callback.call(context, autocomplete)
      })
    })
  }

  @traced({
    why: 'Convert an array of form fields which are in the correct format to be consumed by a ProficloudInput, into an object which can be passed to a new FormGroup.',
  })
  public formFieldsToObject(fieldsArray: ProficloudInputConfig[]) {
    const fieldsObject: Record<string, UntypedFormControl> = {}
    fieldsArray.forEach((field) => {
      fieldsObject[field.key] = field.control
    })
    return fieldsObject
  }

  @traced({
    why: 'Look for and scroll to an device in the device list.',
  })
  public scrollToElement(device: DeviceInfo) {
    // Note: This is clearly brittle but not sure of a better way of doing it
    // given that we can't get hold of the particular device item component
    // reference from the map component.
    const elId = 'device-list-item-' + device.metadata.uuid
    const el = document.getElementById(elId)
    el?.scrollIntoView()
  }

  public getSelectOptionsForCountries() {
    const countries = [
      'Germany',
      'Spain',
      'Italy',
      'Switzerland',
      'United Kingdom',
      'United States of America',
      'Afghanistan',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antigua & Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bonaire',
      'Bosnia & Herzegovina',
      'Botswana',
      'Brazil',
      'British Indian Ocean Ter',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Canary Islands',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Channel Islands',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos Island',
      'Colombia',
      'Comoros',
      'Congo',
      'Cook Islands',
      'Costa Rica',
      'Cote DIvoire',
      'Croatia',
      'Cuba',
      'Curacao',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'East Timor',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'French Guiana',
      'French Polynesia',
      'French Southern Ter',
      'Gabon',
      'Gambia',
      'Georgia',
      'Ghana',
      'Gibraltar',
      'Great Britain',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guinea',
      'Guyana',
      'Haiti',
      'Hawaii',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'Indonesia',
      'India',
      'Iran',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Korea North',
      'Korea South',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Macedonia',
      'Madagascar',
      'Malaysia',
      'Malawi',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Midway Islands',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Nambia',
      'Nauru',
      'Nepal',
      'Netherland Antilles',
      'Netherlands (Holland, Europe)',
      'Nevis',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau Island',
      'Palestine',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Pitcairn Island',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Republic of Montenegro',
      'Republic of Serbia',
      'Reunion',
      'Romania',
      'Russia',
      'Rwanda',
      'St Barthelemy',
      'St Eustatius',
      'St Helena',
      'St Kitts-Nevis',
      'St Lucia',
      'St Maarten',
      'St Pierre & Miquelon',
      'St Vincent & Grenadines',
      'Saipan',
      'Samoa',
      'Samoa American',
      'San Marino',
      'Sao Tome & Principe',
      'Saudi Arabia',
      'Senegal',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Swaziland',
      'Sweden',
      'Syria',
      'Tahiti',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad & Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks & Caicos Is',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Vatican City State',
      'Venezuela',
      'Vietnam',
      'Virgin Islands (Brit)',
      'Virgin Islands (USA)',
      'Wake Island',
      'Wallis & Futana Is',
      'Yemen',
      'Zaire',
      'Zambia',
      'Zimbabwe',
    ]

    return countries.map((c) => {
      return { key: c.toLowerCase(), value: c }
    })
  }

  public openURL(url: string) {
    window.open(url)
  }

  public capitalise(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
}
