import { Component, Input } from '@angular/core'

@Component({
  selector: 'pc-tag',
  templateUrl: './pc-tag.component.html',
  styleUrls: ['./pc-tag.component.scss'],
})
export class PCTagComponent {
  @Input() text = ''
}
