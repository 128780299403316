import { Pipe, PipeTransform } from '@angular/core'
import { UtilityService } from './../services/utility/utility.service'

@Pipe({
  name: 'publicKeyvalue',
})
export class PublicKeyvaluePipe implements PipeTransform {
  transform(object: object): any[] {
    if (!object) {
      return []
    }

    const entries = Object.entries(object)
      .filter(([key, val]) => UtilityService.isObject(val))
      .map(([key, val]) => {
        return {
          key,
          value: val,
        }
      })
    return entries
  }
}
