import { Component, Input, OnChanges } from '@angular/core'

@Component({
  selector: 'app-highlighted-text',
  templateUrl: './highlighted-text.component.html',
  styleUrls: ['./highlighted-text.component.scss'],
})
export class HighlightedTextComponent implements OnChanges {
  @Input()
  text: string

  @Input()
  highlighted: string

  firstBit: string

  middleBit?: string

  lastBit?: string

  ngOnChanges() {
    if (!this.text || !this.highlighted) {
      this.firstBit = this.text
      delete this.middleBit
      delete this.lastBit

      return
    }

    const i = this.text.toLowerCase().indexOf(this.highlighted.toLowerCase())
    if (i >= 0) {
      this.firstBit = this.text.slice(0, i)
      this.middleBit = this.text.slice(i, i + this.highlighted.length)
      this.lastBit = this.text.slice(i + this.highlighted.length)
    } else {
      this.firstBit = this.text
      delete this.middleBit
      delete this.lastBit
    }
  }
}
