<flex-col
  #selectContainer
  class="tagItem"
  [id]="options.elementID"
  *ngIf="!options?.dependsOn || (options?.dependsOn && form.controls[this.options?.dependsOn].valid)"
  [ngClass]="[hasFocus ? 'valid' : '', 'proficloud-select', options.cssClass ? options.cssClass : '']"
  (clickOutside)="clickedOutside()">
  <flex-row-grow>
    <flex-row *ngIf="options.showValue" (click)="expand(false, $event)" id="proficloud-select-value" class="selectable">
      <flex-row *ngFor="let value of selectedValue" [class]="options.multi ? 'tag' : ''">
        <div style="padding-top: 3px" [title]="value.value">{{ value.value | middleTruncate: [20] }}</div>
        <div>
          <app-icon
            *ngIf="options.multi"
            [name]="'cross'"
            color="var(--primary)"
            class="icon remove-selection"
            style="width: 20px; cursor: pointer; display: inline-block"
            (click)="remove(value, $event)"></app-icon>
        </div>
      </flex-row>
      <span
        *ngIf="
          (options.search && options.multi) ||
          (options.search && !options.multi && !selectedValue.length) ||
          (options.search && !options.multi && selectedValue.length && expanded)
        "
        (click)="expand(true, $event)">
        <input
          #filterInput
          [formControl]="filterControl"
          (focus)="hasFocus = true"
          (blur)="hasFocus = false"
          class="tagInput"
          (input)="updateFilterCriteria($event.target.value)"
          name="filterInput"
          id="filter-input"
          [placeholder]="options.placeholder || 'filter'"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false" />
      </span>
      <span *ngIf="!options.search && options.placeholder && !options.control.value && !selectedValue.length" class="placeholder" style="padding-top: 3px">
        {{ options.placeholder }}
      </span>
    </flex-row>
    <div *ngIf="!options.showValue" id="profi-select-placeholder" (click)="expand(true, $event)" class="selectable">
      {{ options.placeholder }}
    </div>
    <div style="min-width: 24px" class="profiSelectToggle" style="padding-top: 3px">
      <app-icon
        name="{{ expanded ? 'arrowDropUp' : 'arrowDropDown' }}"
        style="width: 24px; cursor: pointer"
        color="var(--primary)"
        (click)="expand(false, $event)"></app-icon>
    </div>
  </flex-row-grow>

  <div #container>
    <!-- Grouped -->
    <div
      class="content"
      *ngIf="expanded && hasGroups"
      [style.top]="contentTop"
      [style.left]="contentLeft"
      [style.minWidth]="contentWidth"
      [style.maxWidth]="contentWidth">
      <div class="inner">
        <flex-col *ngFor="let item of optionsAsGroup() | keyvalue">
          <flex-row class="item" *ngIf="hasVisibleSubItems(item)" (click)="groupItemClicked(item); $event.stopPropagation()">
            <div class="icon-container" *ngIf="options.multi">
              <app-icon *ngIf="groupItemStatus(item) === 1" [name]="'remove'" [color]="'#FFFFFF'" class="check"> </app-icon>
              <app-icon *ngIf="groupItemStatus(item) === 2" [name]="'check'" [color]="'#FFFFFF'" class="check"> </app-icon>
            </div>
            <span class="group-header">{{ item.value.label }}</span>
          </flex-row>

          <flex-row
            *ngFor="let subItem of item.value.values | filter: ['isTrue', 'visible'] : ['isFalse', 'excluded']"
            class="item"
            (click)="subItemClicked(subItem); $event.stopPropagation()">
            <flex-row class="level-1 sub-item">
              <div class="icon-container" *ngIf="options.multi">
                <app-icon *ngIf="subItem.checked" [name]="'check'" [color]="'#FFFFFF'" class="check"> </app-icon>
              </div>
              <app-highlighted-text [text]="subItem.value" [highlighted]="filterCriteria"></app-highlighted-text>
              <div *ngIf="subItem.item && subItem.item[subItem.tagProperty]" class="item-tag">
                {{ subItem.item[subItem.tagProperty] }}
              </div>
            </flex-row>
          </flex-row>
        </flex-col>
      </div>

      <div class="done" *ngIf="options.multi">
        <pc-button
          [disabled]="!selectedValue.length"
          size="small"
          [wide]="true"
          [text]="'SHARED.DONE' | translate"
          (btnClick)="clickedOutside()"
          inputID="select-done"></pc-button>
      </div>
    </div>

    <!-- Not grouped (just a flat list) -->
    <flex-col
      class="content"
      [style.top]="contentTop"
      [style.left]="contentLeft"
      [style.minWidth]="contentWidth"
      [style.maxWidth]="contentWidth"
      *ngIf="expanded && !hasGroups">
      <div class="inner">
        <ng-container *ngFor="let item of itemsAsArray() | filter: ['isTrue', 'visible'] : ['isFalse', 'excluded']">
          <div class="item">
            <flex-row>
              <div class="icon-container" *ngIf="options.multi">
                <app-icon *ngIf="item.checked" [name]="'check'" [color]="'#FFFFFF'" class="check"> </app-icon>
              </div>

              <app-highlighted-text
                (click)="subItemClicked(item); $event.stopPropagation()"
                style="flex-grow: 1"
                [text]="item.value"
                [highlighted]="filterCriteria"></app-highlighted-text>

              @if (options.showInfoText && item.infoText) {
                <app-icon name="infoInverted" color="var(--primary)" class="info-icon" [matTooltip]="item.infoText"></app-icon>
              }
            </flex-row>
          </div>
        </ng-container>
      </div>
    </flex-col>
  </div>
</flex-col>
