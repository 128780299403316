@if (!isLoading) {
  <div class="pc-list-item">
    <div
      class="pc-list-item__header"
      [class.pc-list-item__header--shadowed]="shadowed"
      (click)="toggleContentMenu()"
      [attr.data-analytics]="'list-item-' + title">
      <div class="pc-list-item__info" [class.pc-list-item__info--shadowed]="shadowed">
        <div class="pc-list-item__info-text">{{ title }}</div>
        <div class="pc-list-item__info-subtext">{{ subTitle }}</div>
      </div>
      <div class="pc-list-item__hint" [class.pc-list-item__hint--shadowed]="shadowed">
        {{ hint }}
      </div>
      <div class="pc-list-item__details">
        <div class="pc-list-item__tags">
          @for (tag of tags; track tag) {
            <pc-tag [text]="tag"></pc-tag>
          }
        </div>
        <div>
          @if (message) {
            <div class="pc-list-item__message">
              {{ message }}
            </div>
          }
          <div class="pc-list-item__type" [class.pc-list-item__type--shadowed]="shadowed">{{ type }}</div>
        </div>
      </div>
      <div class="pc-list-item__actions" (clickOutside)="actionMenuIsOpen = false">
        <app-icon
          class="pc-list-item__action-button"
          [name]="'more'"
          [color]="'var(--jet-black)'"
          (click)="toggleActionMenu(); $event.stopPropagation()"></app-icon>
        @if (actionMenuIsOpen) {
          <div class="pc-list-item__action-list" [@actionMenu]="actionMenuIsOpen ? ':enter' : ':leave'">
            @for (item of actionMenuItems; track item) {
              <div class="pc-list-item__action-item" (click)="actionItemClick(item, $event)">
                <div class="pc-list-item__action-text" [class.pc-list-item__action-text--disabled]="item.disabled">
                  {{ item.name }}
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
    @if (extended) {
      <ng-container [ngTemplateOutlet]="dynamicContent"></ng-container>
    }
  </div>
}

@if (isLoading) {
  <div class="pc-list-item--loading">
    <div class="pc-list-item__header"></div>
  </div>
}
