import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export type DragContext = {
  context: string
  x?: number
  y?: number
}

@Injectable({
  providedIn: 'root',
})
export class SharedUIService {
  elementDragged$: Subject<DragContext> = new Subject<DragContext>()

  constructor() {}
}
