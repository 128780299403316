import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core'
import { Subscription } from 'rxjs'
import { ActionToggleAnimation } from './pc-list-item.animations'
import { PcListItemService } from './pc-list-item.service'

export interface PCListActionItem {
  name: string
  value: string
  disabled: boolean
}

@Component({
  selector: 'pc-list-item',
  templateUrl: './pc-list-item.component.html',
  styleUrls: ['./pc-list-item.component.scss'],
  animations: [ActionToggleAnimation],
})
export class PCListItemComponent implements OnInit, OnDestroy {
  pcListItemService = inject(PcListItemService)

  @Output() toggled: EventEmitter<string> = new EventEmitter<string>()

  @Output() actionItemClicked: EventEmitter<PCListActionItem> = new EventEmitter<PCListActionItem>()

  @Input() id: string

  @Input() dynamicContent: TemplateRef<any>

  @Input() isLoading = false

  @Input() title = 'XXXXXX'

  @Input() subTitle = 'XXXXXX'

  @Input() type = 'XXXXXX'

  @Input() message = ''

  @Input() tags: string[] = []

  @Input() shadowed = false

  @Input() hint = ''

  @Input() extended = false

  @Input() actionMenuItems: PCListActionItem[] = []

  actionMenuIsOpen = false

  pcListItemServiceSub: Subscription

  ngOnInit(): void {
    this.pcListItemServiceSub = this.pcListItemService.toggled$.subscribe({
      next: (id) => {
        if (id === this.id) {
          this.actionMenuIsOpen = !this.actionMenuIsOpen
        } else {
          this.actionMenuIsOpen = false
        }
      },
    })
  }

  toggleActionMenu = () => {
    this.pcListItemService.toggled$.next(this.id)
  }

  toggleContentMenu = () => {
    this.extended = !this.extended
    this.toggled.emit(this.id)
  }

  actionItemClick(item: PCListActionItem, $event: MouseEvent) {
    this.actionMenuIsOpen = false
    $event.stopPropagation()
    if (!item.disabled) {
      this.actionItemClicked.emit(item)
    }
  }

  ngOnDestroy(): void {
    this.pcListItemServiceSub?.unsubscribe()
  }
}
