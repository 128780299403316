import { Pipe, PipeTransform } from '@angular/core'
import { orderBy } from 'lodash'

// Sorting pipe which uses basic array sorting mechanisms and lodash helper function for sorting by a certain column (iteratee)
// @params: array: the list of values, direction: ascending or descending, iteratee: property/column of array items you want to sort by
@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(array: any[], direction: 'asc' | 'desc', iteratee: string = ''): any[] {
    if (!array) {
      return array
    }
    if (array.length <= 1) {
      return array
    }
    if (!iteratee || iteratee === '') {
      if (direction === 'asc') {
        return array.sort()
      } else {
        return array.sort().reverse()
      }
    }
    return orderBy(array, [iteratee], [direction])
  }
}
