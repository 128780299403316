/**
 * taken from the excellent https://github.com/sindresorhus/on-change
 */

import { ExtendedWindow } from './../shared.interfaces'
export interface ChangeEvent {
  path: string
  propertyName: string
  previousValue: any
  newValue: any
  nature: 'key deleted' | 'key added' | string
}

const PATH_SEPARATOR = '.'

const isPrimitive = (value: any) => value === null || (typeof value !== 'object' && typeof value !== 'function')

const isBuiltinWithoutMutableMethods = (value: any) => value instanceof RegExp || value instanceof Number

const isBuiltinWithMutableMethods = (value: any) => value instanceof Date

const concatPath = (path: any, property: string) => {
  if (property && property.toString) {
    if (path) {
      path += PATH_SEPARATOR
    }

    path += property.toString()
  }

  return path
}

const walkPath = (path: string, callback: (s: string) => void) => {
  // console.log('onchanges walk path')

  let index

  while (path) {
    index = path.indexOf(PATH_SEPARATOR)

    if (index === -1) {
      index = path.length
    }

    callback(path.slice(0, index))

    path = path.slice(index + 1)
  }
}

const shallowClone = (value: any) => {
  // console.log('onchanges shallow clone')

  if (Array.isArray(value)) {
    return value.slice()
  }

  return Object.assign({}, value)
}

const proxyTarget = Symbol('ProxyTarget')

export const onChange = (object: any, changeCallback: (e: ChangeEvent) => void) => {
  if (!object) {
    alert('cannot track an undefined object')
  }

  const getCount = 0

  let inApply = false
  let changed = false
  let applyPath: any
  let applyPrevious: any
  const propCache = new WeakMap()
  const pathCache = new WeakMap()
  const proxyCache = new WeakMap()

  const handleChange = (changeEvent: ChangeEvent) => {
    // console.log('on-data-change', changeEvent)

    if (!inApply) {
      changeEvent.path = concatPath(changeEvent.path, changeEvent.propertyName)
      changeCallback(changeEvent)
      return
    }

    if (inApply && changeEvent.previousValue !== undefined && changeEvent.newValue !== undefined && changeEvent.propertyName !== 'length') {
      let item = applyPrevious

      if (changeEvent.path !== applyPath) {
        changeEvent.path = changeEvent.path.replace(applyPath, '').slice(1)

        walkPath(changeEvent.path, (key: string) => {
          item[key] = shallowClone(item[key])
          item = item[key]
        })
      }

      item[changeEvent.propertyName] = changeEvent.previousValue
    }

    changed = true
  }

  const getOwnPropertyDescriptor = (target: any, property: any) => {
    let props = propCache.get(target)

    if (props) {
      return props
    }

    props = new Map()
    propCache.set(target, props)

    let prop = props.get(property)
    if (!prop) {
      prop = Reflect.getOwnPropertyDescriptor(target, property)
      props.set(property, prop)
    }

    return prop
  }

  const invalidateCachedDescriptor = (target: any, property: any) => {
    const props = propCache.get(target)

    if (props) {
      props.delete(property)
    }
  }

  const handler = {
    get(target: any, property: any, receiver: any) {
      // console.log('onchange gettter called', getCount)

      if (property === proxyTarget) {
        return target
      }

      const value = Reflect.get(target, property, receiver)
      if (isPrimitive(value) || isBuiltinWithoutMutableMethods(value) || property === 'constructor') {
        return value
      }

      // Preserve invariants
      const descriptor = getOwnPropertyDescriptor(target, property)
      if (descriptor && !descriptor.configurable) {
        if (descriptor.set && !descriptor.get) {
          return undefined
        }

        if (!descriptor.writable) {
          return value
        }
      }

      pathCache.set(value, concatPath(pathCache.get(target), property))
      let prxy = proxyCache.get(value)
      if (prxy === undefined) {
        prxy = new Proxy(value, handler)
        proxyCache.set(value, prxy)
      }

      return prxy
    },

    set(target: any, property: any, value: any, receiver: any) {
      const tracingService = (window as unknown as ExtendedWindow).tracingService

      /**
       *
       * META Broadcast >> Object SET
       *
       *
       */

      if (tracingService) {
        tracingService.objectSet$.next({
          target,
          property,
          value,
          receiver,
        })
      }

      if (value && value[proxyTarget] !== undefined) {
        value = value[proxyTarget]
      }

      const previous = Reflect.get(target, property, receiver)
      const result = Reflect.set(target[proxyTarget] || target, property, value)

      if (previous !== value) {
        const changeEvent: ChangeEvent = {
          path: pathCache.get(target),
          propertyName: property,
          previousValue: previous,
          newValue: value,
          nature: 'value changed',
        }
        handleChange(changeEvent)
      }

      return result
    },

    defineProperty(target: any, property: any, descriptor: any) {
      const result = Reflect.defineProperty(target, property, descriptor)
      invalidateCachedDescriptor(target, property)

      const changeEvent: ChangeEvent = {
        path: pathCache.get(target),
        propertyName: property,
        previousValue: undefined,
        newValue: descriptor.value,
        nature: 'key added',
      }
      // call callback
      handleChange(changeEvent)
      return result
    },

    deleteProperty(target: any, property: string) {
      const previous = Reflect.get(target, property)
      const result = Reflect.deleteProperty(target, property)
      invalidateCachedDescriptor(target, property)

      const changeEvent: ChangeEvent = {
        path: pathCache.get(target),
        propertyName: property,
        previousValue: previous,
        newValue: undefined,
        nature: 'key deleted',
      }

      // call callback
      handleChange(changeEvent)
      return result
    },

    apply(target: any, thisArg: any, argumentsList: any) {
      // console.log('initial? apply called', target)

      const compare = isBuiltinWithMutableMethods(thisArg)

      if (compare) {
        thisArg = thisArg[proxyTarget]
      }

      if (!inApply) {
        inApply = true

        if (compare) {
          applyPrevious = thisArg.valueOf()
        }

        if (Array.isArray(thisArg)) {
          applyPrevious = shallowClone(thisArg[proxyTarget as any])
        }

        applyPath = pathCache.get(target)
        applyPath = applyPath.slice(0, applyPath.lastIndexOf(PATH_SEPARATOR))
        const result = Reflect.apply(target, thisArg, argumentsList)

        inApply = false
        if (changed || (compare && applyPrevious !== thisArg.valueOf())) {
          const changeEvent: ChangeEvent = {
            path: pathCache.get(target),
            propertyName: '',
            previousValue: applyPrevious,
            newValue: argumentsList,
            nature: 'apply',
          }
          // call callback (not sure if this is ever firing)
          handleChange(changeEvent)

          applyPrevious = null
          changed = false
        }

        return result
      }

      return Reflect.apply(target, thisArg, argumentsList)
    },
  }

  pathCache.set(object, '')
  const proxy = new Proxy(object, handler)

  changeCallback = changeCallback.bind(proxy)

  return proxy
}
