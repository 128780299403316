import { DecoratedClass } from './../services/tracing/tracing.interfaces'

export class TrackInfo {
  propertyName: string
  options?: TrackOptions
}

export class TrackOptions {
  what: string

  persist?: {
    precedence: {
      dev: 'code' | 'localstorage'
      prod: 'code' | 'localstorage'
    }
  };

  [key: string]: any
}

/**
 *      @TRACKED   decorator - for all your data tracking needs.
 *
 *      Writes an object with {propertyName, options} to the class' trackedData[] array.
 *
 *      It will be picked up by tracing.registerClass, which
 *      replace the original data with an observed proxy.
 *
 */
export function tracked(options?: TrackOptions) {
  return function innerTracked(target: DecoratedClass, propertyName: string) {
    if (!target.trackedData) {
      target.trackedData = []
    }
    target.trackedData.push({ propertyName, options })
  }
}
