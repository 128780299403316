import { AfterContentInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { HoverAnimation, VerifyAnimation } from './pc-button.animation'

@Component({
  selector: 'pc-button',
  templateUrl: './pc-button.component.html',
  styleUrls: ['./pc-button.component.scss'],
  animations: [HoverAnimation, VerifyAnimation],
})
export class PCButtonComponent implements AfterContentInit {
  @ViewChild('innerButton') innerButton: ElementRef

  // I would like to use the click event emitter, but it is not possible to overwrite them yet (angular 15)
  @Output() btnClick = new EventEmitter()

  @Input() text = ''

  @Input() size: 'small' | 'regular' | 'large' | 'narrow' | 'minimal' = 'regular'

  @Input() wide = false

  @Input() variant: 'primary' | 'secondary' | 'deletion' = 'primary'

  @Input() disabled = false

  @Input() active = true

  @Input() verifiable = false

  @Input() inputID: string

  @Input() analyticsID: string

  @Input() minimalSize: boolean

  buttonWidth: string

  // We need to make sure, that the initial button doesn't get smaller if the loading animation starts
  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value
    if (value) {
      this.buttonWidth = this.innerButton.nativeElement.offsetWidth + 'px'
    }
  }
  get isLoading(): boolean {
    return this._isLoading
  }
  private _isLoading = false

  // Icon only mode hides the text but shows an icon
  @Input() iconOnly = false

  @Input() icon: string = 'info'

  @Input() noUppercase: boolean

  hover = false

  verificationVisible = false

  ngAfterContentInit(): void {
    this.buttonWidth = this.innerButton?.nativeElement?.offsetWidth + 'px'
  }

  buttonClicked(event: MouseEvent) {
    if (this.verifiable) {
      this.verificationVisible = true
      setTimeout(() => {
        this.verificationVisible = false
        this.btnClick.emit(event)
      }, 2000)
    } else {
      this.btnClick.emit(event)
    }
  }

  // changes the hove state on mouseenter and mouseleave in order to control animations
  hoverChanged(event: MouseEvent) {
    if (event.type === 'mouseenter') {
      this.hover = true
    } else if (event.type === 'mouseleave') {
      this.hover = false
    }
  }
}
