import { Pipe, PipeTransform } from '@angular/core'
import { DateRange } from '@angular/material/datepicker'
import { ProficloudService } from '@services/proficloud.service'
import { format } from 'date-fns'

@Pipe({
  name: 'materialDateRangeDisplay',
  pure: false,
})
export class MaterialDateRangeDisplayPipe implements PipeTransform {
  constructor(private proficloud: ProficloudService) {}

  transform(value: DateRange<Date> | string, ...args: unknown[]): unknown {
    if (typeof value === 'string') {
      return value.length ? this.proficloud.presetDateRanges[value] : 'Select range'
    } else {
      let formatString = 'dd/MM/yyyy'
      if (!(value?.start?.getMinutes() === 0 && value?.start?.getHours() === 0 && value?.end?.getMinutes() === 59 && value?.end?.getHours() === 23)) {
        formatString += ' HH:mm'
      }
      return value?.start && value?.end ? format(value.start, formatString) + ' - ' + format(value.end, formatString) : 'Select range'
    }
  }
}
