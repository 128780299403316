import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IPCIconListGroup, SelectionEvent } from '../buttons/pc-icon-dropdown/pc-icon-dropdown.component'

@Component({
  selector: 'pc-filter-bar',
  templateUrl: './pc-filter-bar.component.html',
  styleUrls: ['./pc-filter-bar.component.scss'],
})
export class PCFilterBarComponent {
  @Output() searchExecuted: EventEmitter<string> = new EventEmitter()

  @Output() sortByExecuted: EventEmitter<SelectionEvent> = new EventEmitter()

  @Output() filterChangedExecuted: EventEmitter<SelectionEvent> = new EventEmitter()

  @Output() dynamicActionExecuted: EventEmitter<any> = new EventEmitter()

  @Input() sortValues: IPCIconListGroup[]

  @Input() dynamicActionText: string

  @Input() dynamicActionAnalytics: string = ''

  @Input() filterValues: IPCIconListGroup[]

  sortByChanged(event: SelectionEvent) {
    this.sortByExecuted.emit(event)
  }

  filterChanged(event: SelectionEvent) {
    event.item.selected = !event.item.selected
    this.filterChangedExecuted.emit(event)
  }

  dynamicActionTriggered() {
    this.dynamicActionExecuted.emit()
  }
}
