import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { IamService } from '@services/iam.service'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { AppService } from 'src/app/app.service'
import { PcStatusOverlayService } from '../../services/pc-status-overlay/pc-status-overlay.service'

@Component({
  selector: 'app-email-change',
  templateUrl: './email-change.component.html',
  styleUrls: ['./email-change.component.scss'],
})
export class EmailChangeComponent implements OnInit {
  invitationToken: string

  changeEmailControl: UntypedFormControl

  changeEmailForm: UntypedFormGroup

  securityToken?: string

  email?: string

  constructor(
    public app: AppService,
    public proficloud: ProficloudService,
    private iam: IamService,
    public router: Router,
    private statusOverlay: PcStatusOverlayService,
    public ui: UiService
  ) {}

  ngOnInit(): void {
    this.securityToken = this.app.getQueryParam('securityToken') || undefined
    this.email = this.app.getQueryParam('email') || undefined

    this.changeEmailControl = new UntypedFormControl(this.email, [Validators.required])
    this.changeEmailForm = new UntypedFormGroup({ email: this.changeEmailControl })
  }

  submit() {
    if (!this.securityToken) {
      return
    }

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.changeEmailBusy)

    this.iam.setNewEmail(this.securityToken).subscribe({
      next: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.changeEmailSuccess)
        setTimeout(() => {
          this.statusOverlay.resetStatus()
          this.router.navigate(['/'])
        }, 2000)
      },
      error: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.changeEmailError)
      },
    })
  }
}
