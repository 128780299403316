import { animate, state, style, transition, trigger } from '@angular/animations'

export const sharedAnimations = {
  // expandableDevice
  deviceExpansion: trigger('deviceExpansion', [
    state('void', style({ height: 0, opacity: 0, overflow: 'hidden' })),
    state('collapsed', style({ height: 0, opacity: 0, overflow: 'hidden' })),
    state('expanded', style({ height: '*', opacity: 1, overflow: 'hidden' })),
    transition('collapsed <=> expanded', animate('200ms ease-in-out')),
    // transition('void <=> expanded', animate('200ms ease-in-out')),
  ]),

  // expandable anomaly
  anomalyExpansion: trigger('anomalyExpansion', [
    state('void', style({ height: 0, opacity: 0, overflow: 'hidden' })),
    state('collapsed', style({ height: 0, opacity: 0, overflow: 'hidden' })),
    state('expanded', style({ height: '*', opacity: 1, overflow: 'hidden' })),
    transition('collapsed <=> expanded', animate('200ms ease-in-out')),
    transition('void <=> expanded', animate('200ms ease-in-out')),
  ]),

  // expandable alert
  alertExpansion: trigger('alertExpansion', [
    state('void', style({ height: 0, opacity: 0, overflow: 'hidden' })),
    state('collapsed', style({ height: 0, opacity: 0, overflow: 'hidden' })),
    state('expanded', style({ height: '*', opacity: 1, overflow: 'hidden' })),
    transition('collapsed <=> expanded', animate('200ms ease-in-out')),
    transition('void <=> expanded', animate('200ms ease-in-out')),
  ]),

  // chevron turning down on expansion
  chevronRotation: trigger('chevronRotation', [
    state('1', style({ transform: 'rotate(90deg)' })),
    state('0', style({ transform: 'rotate(0deg)' })),
    transition('* <=> 1', animate('200ms ease-in')),
    transition('* <=> 0', animate('150ms ease-in')),
  ]),

  // device content expansion
  heightSlideEnterLeave: trigger('heightSlideEnterLeave', [
    transition(':enter', [
      style({
        height: '0',
        opacity: 0,
        // 'transform': 'translateX(-200px)',
      }),
      animate(
        '200ms ease-in',
        style({
          height: '30vh',
          opacity: 1,
          // 'transform': 'translateX(0%)',
        })
      ),
    ]),
    transition(':leave', [
      animate(
        '150ms ease-in',
        style({
          height: 0,
          opacity: '0',
        })
      ),
    ]),
  ]),

  // device move
  deviceMove: trigger('deviceMove', [
    transition(':enter', [
      style({
        height: '0',
        // 'transform': 'translateX(-200px)',
      }),
      animate(
        '100ms linear',
        style({
          height: '*',
          // 'transform': 'translateX(0%)',
        })
      ),
    ]),
    transition(':leave', [
      animate(
        '100ms linear',
        style({
          height: 0,
          opacity: '0',
        })
      ),
    ]),
  ]),
  // auth slide proficloud
  authSlide: trigger('authSlide', [
    transition(':enter', [
      style({
        opacity: '0',
        // 'transform': 'translateX(-200px)',
      }),
      animate(
        '300ms linear',
        style({
          opacity: '1',
          // 'transform': 'translateX(0%)',
        })
      ),
    ]),
    transition(':leave', [
      animate(
        '200ms linear',
        style({
          height: 0,
          opacity: '0',
        })
      ),
    ]),
  ]),
  // device
  deviceInOut: trigger('deviceInOut', [
    transition(':enter', [
      style({
        opacity: '0',
        transform: 'translateX(-200px)',
      }),
      animate(
        '300ms linear',
        style({
          opacity: '1',
          transform: 'translateX(0%)',
        })
      ),
    ]),
    /* transition(':leave', [
            animate('1000ms linear', style({
                'opacity': 0,
                'transform': 'translateX(-20px)',
            }))
        ]), */
  ]),
  // tickets
  heightSlide: trigger('heightSlide', [
    state('collapsed', style({ height: 0, opacity: 0 })),
    state('expanded', style({ height: '*', opacity: 1 })),
    transition('collapsed <=> expanded', animate('200ms linear')),
  ]),
  widthSlide: trigger('widthSlide', [
    state('collapsed', style({ width: 0, minWidth: 0, opacity: 0 })),
    state('expanded', style({ width: '400px', minWidth: '400px', opacity: 1 })),
    transition('collapsed <=> expanded', animate('200ms linear')),
  ]),
  widthSlideTrueFalse: trigger('widthSlideTrueFalse', [
    state('false', style({ width: 0, minWidth: 0, opacity: 0 })),
    state('true', style({ width: '300px', minWidth: '300px', opacity: 1 })),
    transition('true <=> false', animate('200ms linear')),
  ]),
  rotateExpansion: trigger('rotateExpansion', [
    state('collapsed', style({ transform: 'rotate(0deg)' })),
    state('expanded', style({ transform: 'rotate(90deg)' })),
    transition('collapsed <=> expanded', animate('200ms linear')),
  ]),
  scaleIn: trigger('scaleIn', [
    transition(':enter', [
      style({
        transform: 'scale(0)',
      }),
      animate(
        '100ms linear',
        style({
          transform: 'scale(1)',
        })
      ),
    ]),
  ]),
  scaleOut: trigger('scaleOut', [
    transition(':leave', [
      style({
        // 'transform': 'scale(0)',
      }),
      animate(
        '100ms linear',
        style({
          transform: 'scale(0)',
        })
      ),
    ]),
  ]),
  fadeIn: trigger('fadeIn', [
    transition(':enter', [
      style({
        opacity: 0,
      }),
      animate(
        '200ms linear',
        style({
          opacity: 1,
        })
      ),
    ]),
  ]),
  fadeOut: trigger('fadeOut', [
    transition(':leave', [
      animate(
        '100ms linear',
        style({
          opacity: 0,
        })
      ),
    ]),
  ]),
  message: trigger('message', [
    transition(':enter', [
      style({
        left: '-100%',
        opacity: 1,
      }),
      animate(
        '200ms ease-in',
        style({
          left: '0',
          background: 'blue',
        })
      ),
    ]),
    transition(':leave', [
      animate(
        '200ms ease-in',
        style({
          width: '0',
        })
      ),
    ]),
  ]),
  sidebar: trigger('sidebar', [
    transition(':enter', [
      style({
        width: '0',
      }),
      animate(
        '200ms ease-in',
        style({
          width: '*',
        })
      ),
    ]),
    transition(':leave', [
      animate(
        '200ms ease-in',
        style({
          width: '0',
        })
      ),
    ]),
  ]),
  appear: trigger('appear', [
    transition(':enter', [
      style({
        height: 0,
        opacity: 0,
        transform: 'translateX(100%)',
      }),
      animate(
        '200ms ease-in',
        style({
          height: '*',
          opacity: 1,
          transform: 'translateX(0%)',
        })
      ),
    ]),
    transition(':leave', [
      animate(
        '500ms ease-in',
        style({
          /* 'height': 0,
                    opacity: 0, */
        })
      ),
    ]),
  ]),
}
