<flex-col
  id="options"
  class="paper-2 dropdownMenu"
  [class.minimal]="size === 'minimal' && !left && !wideRight"
  [class.regular]="size === 'regular' && !left && !wideRight"
  [class.left]="left"
  [class.wide-right]="wideRight">
  @if (subMenusExist) {
    <ng-scrollbar [autoHeightDisabled]="false">
      <ng-container *ngTemplateOutlet="menuItems"></ng-container>
    </ng-scrollbar>
  } @else {
    <div>
      <ng-container *ngTemplateOutlet="menuItems"></ng-container>
    </div>
  }

  <ng-template #menuItems>
    @for (option of items; track option) {
      @if (!option.hidden) {
        <flex-row (click)="onMenuIconClick($event, option)" class="dropdownMenuItem" [class.disabled]="option.disabled">
          @if (option.icon) {
            <app-icon
              class="icon"
              style="margin-right: 8px; height: 24px; width: 24px"
              [name]="option.icon"
              [color]="option.disabled ? 'var(--regent-gray)' : 'var(--primary)'"></app-icon>
          }
          <div style="flex-grow: 1">
            {{ option.label | translate }}
          </div>
          @if (option.subItems) {
            <app-icon name="chevronRight" color="var(--primary)" style="width: 24px"></app-icon>
          }
        </flex-row>
      }
    }
  </ng-template>

  @if (subMenuItems) {
    <flex-col #subMenu class="sub-items paper-2" [style.top]="topOffset + 'px'" [style.display]="topOffset ? 'flex' : 'none'">
      @for (item of subMenuItems; track item) {
        <flex-row class="dropdownMenuItem" (click)="onMenuIconClick($event, item)">
          {{ item.label }}
        </flex-row>
      }
    </flex-col>
  }
</flex-col>
