import { animate, state, style, transition, trigger } from '@angular/animations'

export const HoverAnimation = trigger('hoverState', [
  state('default', style({ background: '{{bgColor}}' }), { params: { bgColor: 'var(--primary)' } }),
  state('hovered', style({ background: '{{bgColorHovered}}' }), { params: { bgColorHovered: 'var(--secondary)' } }),
  transition('default => hovered', animate('200ms ease-in')),
])

export const VerifyAnimation = trigger('verifyAnimation', [
  transition(':enter', [style({ transform: 'translateY(50%)', opacity: 0 }), animate('200ms', style({ transform: 'translateY(0)', opacity: 1 }))]),
])
