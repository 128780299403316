export const design = {
  location: '/src/app/modules/shared/services/ui/ui.design-data.ts',
  key: 'design',
  logos: {
    hello: {
      container: {
        backgroundImage:
          "url('https://images.unsplash.com/photo-1488229297570-58520851e868?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1949&q=80')",
        path: ['design', 'logos', 'hello', 'container'],
        width: '500px',
        height: '500px',
        logo: {
          'width_%': '100',
          path: ['design', 'logos', 'hello', 'container', 'logo'],
          display: 'inline-block',
          'height_%': '100',
        },
        logo__state: 'collapsed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      path: ['design', 'logos', 'hello'],
      container__state: 'expanded',
    },
    path: ['design', 'logos'],
  },
  images: {
    manhattonTopview: {
      url: 'https://images.unsplash.com/photo-1446776899648-aa78eefe8ed0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1952&q=80',
      tags: 'sunny golden',
      path: ['design', 'images', 'manhattonTopview'],
    },
    manhattonTopview__state: 'expanded',
    path: ['design', 'images'],
  },
  images__state: 'expanded',
  number: '42',
  bool: true,
}
