import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { IamService } from '@services/iam.service'
import { ProficloudInputConfig } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { AppService } from 'src/app/app.service'
import { PcStatusOverlayService } from '../../services/pc-status-overlay/pc-status-overlay.service'
import {
  numberValidator,
  passwordConfirmationValidator,
  smallCharacterValidator,
  specialCharacterValidator,
  upperCharacterValidator,
} from '../../validators/custom-validators'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  invitationToken: string

  resetPasswordFields: ProficloudInputConfig[] = [
    {
      type: 'password',
      placeholder: 'New Password',
      control: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(72),
        smallCharacterValidator(),
        upperCharacterValidator(),
        numberValidator(),
        specialCharacterValidator(),
      ]),
      key: 'new-password',
      inputId: 'new-password',
    },
    {
      type: 'password',
      placeholder: 'Confirmation',
      control: new UntypedFormControl('', [Validators.required]),
      key: 'confirm-password',
      inputId: 'confirm-password',
    },
  ]

  resetPasswordForm: UntypedFormGroup

  securityToken?: string

  email?: string

  constructor(
    public app: AppService,
    public proficloud: ProficloudService,
    private iam: IamService,
    public router: Router,
    private statusOverlay: PcStatusOverlayService,
    public ui: UiService
  ) {}

  ngOnInit(): void {
    this.securityToken = this.app.getQueryParam('securityToken') || undefined
    this.email = this.app.getQueryParam('email') || undefined

    this.resetPasswordFields.unshift({
      type: 'text',
      placeholder: 'Email',
      value: this.email,
      control: new UntypedFormControl(this.email, [Validators.required]),
      key: 'email',
      disabled: true,
    })
    const controlsObject = this.ui.formFieldsToObject(this.resetPasswordFields)
    this.resetPasswordForm = new UntypedFormGroup(controlsObject)

    // set validator for the password confirmation check
    this.resetPasswordForm
      .get('confirm-password')
      ?.setValidators([Validators.required, passwordConfirmationValidator(this.resetPasswordForm, 'new-password', 'confirm-password')])
  }

  submit() {
    if (!this.email || !this.securityToken) {
      return
    }

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.resetPasswordBusy)

    this.iam.setNewPassword(this.email, this.resetPasswordForm.controls['new-password'].value, this.securityToken).subscribe({
      next: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.resetPasswordSuccess)
        setTimeout(() => {
          this.statusOverlay.resetStatus()
          this.router.navigate(['/'])
        }, 2000)
      },
      error: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.resetPasswordError)
      },
    })
  }
}
