import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core'

@Directive({
  standalone: true,
  selector: '[customMatSpinner]',
})
export class CustomMatSpinnerDirective implements AfterViewInit {
  @Input() cuzColor: string

  constructor(
    // the material element
    private spinner: ElementRef
  ) {}

  ngAfterViewInit() {
    if (!!this.cuzColor) {
      const element = this.spinner.nativeElement
      const circles = element.querySelectorAll('circle')

      // this stuff works, don't fight me
      for (let i = 0; i < circles.length; i++) {
        circles[i].style.stroke = this.cuzColor
      }
    }
  }
}
