<div id="container">
  <pc-tab-bar [tabs]="sampleTabs">
    <!-- Sections-->
    <div *templateMarker>
      <pc-section [header]="'This is a section'"> This is the content of the section </pc-section>
    </div>

    <!-- Buttons -->
    <div *templateMarker>
      <pc-section [header]="'Large primary button'">
        <pc-button size="large">Click me!</pc-button>
      </pc-section>
      <pc-section [header]="'Regular primary button'">
        <pc-button>Click me!</pc-button>
      </pc-section>
      <pc-section [header]="'Small primary button'">
        <pc-button size="small">Click me!</pc-button>
      </pc-section>
      <pc-section [header]="'Large secondary button'">
        <pc-button size="large" variant="secondary">Click me!</pc-button>
      </pc-section>
      <pc-section [header]="'Regular secondary button'">
        <pc-button variant="secondary">Click me!</pc-button>
      </pc-section>
      <pc-section [header]="'Small secondary button'">
        <pc-button size="small" variant="secondary">Click me!</pc-button>
      </pc-section>
      <pc-section [header]="'Large deletion button'">
        <pc-button size="large" variant="deletion">Click me!</pc-button>
      </pc-section>
      <pc-section [header]="'Regular deletion button'">
        <pc-button variant="deletion">Click me!</pc-button>
      </pc-section>
      <pc-section [header]="'Small deletion button'">
        <pc-button size="small" variant="deletion">Click me!</pc-button>
      </pc-section>
    </div>

    <!-- Text inputs -->
    <div *templateMarker>
      <pc-section [header]="'Text field input'">
        <pc-input-textfield placeholder="Placeholder text" label="Label" [control]="testControl"></pc-input-textfield>
      </pc-section>
      <pc-section [header]="'Small text field input'">
        <pc-input-textfield placeholder="Placeholder text" label="Label" size="small" [control]="testControl"></pc-input-textfield>
      </pc-section>
      <pc-section [header]="'Text area'">
        <pc-input-textarea placeholder="Placeholder text" label="Label" [control]="testControl"></pc-input-textarea>
      </pc-section>
      <pc-section [header]="'Password input'">
        <pc-input-password placeholder="Placeholder text" label="Label" [control]="testControl"></pc-input-password>
      </pc-section>
      <pc-section [header]="'Number input'">
        <pc-input-number placeholder="Placeholder text" label="Label" [control]="testControl"></pc-input-number>
      </pc-section>
      <pc-section [header]="'Chiplist input'">
        <pc-input-chiplist placeholder="Placeholder text" label="Label" [control]="testControl"></pc-input-chiplist>
      </pc-section>
      <pc-section [header]="'Search text field input'">
        <pc-input-search placeholder="Placeholder text" [control]="testControl"></pc-input-search>
      </pc-section>
    </div>

    <!-- Dropdown Select -->
    <div *templateMarker>
      <pc-section header="Button Dropdown">
        <pc-button-dropdown
          [dropdownOptions]="dropdownSelectOptions"
          [control]="dropdownSelectControl"
          [initialSelectedValue]="dropdownSelectControl.value"></pc-button-dropdown>
      </pc-section>
    </div>

    <!-- Date Pickers -->
    <div *templateMarker>
      <pc-section header="Date Range">
        <pc-input-date-range (rangeSelected)="dateRangeSelected($event)" [control]="dateRangeControl"></pc-input-date-range>
      </pc-section>
      <pc-section header="Single date">
        <pc-input-date-range (rangeSelected)="dateRangeSelected($event)" [control]="dateControl" [single]="true" [time]="false"></pc-input-date-range>
      </pc-section>
      <pc-section header="Single date with time">
        <pc-input-date-range (rangeSelected)="dateRangeSelected($event)" [control]="dateControl" [single]="true" [time]="true"></pc-input-date-range>
      </pc-section>
    </div>

    <!-- Overlays -->
    <div *templateMarker>
      <pc-section [header]="'Standard overlay'">
        <pc-button (btnClick)="showStandardOverlay = true">Show standard overlay!</pc-button>
      </pc-section>
      <pc-section [header]="'Status overlays'">
        <flex-col>
          <div class="button-container">
            <pc-button (click)="statusOverlay.showStatus(exampleLoadingBusy)">Show busy status!</pc-button>
          </div>
          <div class="button-container">
            <pc-button (click)="statusOverlay.showStatus(exampleLoadingSuccess)">Show success status!</pc-button>
          </div>
          <div class="button-container">
            <pc-button (click)="statusOverlay.showStatus(exampleLoadingError)">Show error status!</pc-button>
          </div>
        </flex-col>
      </pc-section>
    </div>

    <!-- Scroll -->
    <div *templateMarker>
      <pc-section header="Infinite Scroll">
        <flex-col style="height: 400px; overflow: auto" infinite-scroll (scrolled)="onScrollDown()" [scrollWindow]="false">
          @for (item of scrollItems; track item) {
            <div>{{ item }}</div>
          }
        </flex-col>
      </pc-section>
    </div>
  </pc-tab-bar>
</div>

@if (showStandardOverlay) {
  <pc-overlay [config]="standardOverlayConfig" (closeClick)="showStandardOverlay = false">
    <ng-container body>
      <div style="width: 100%; margin-top: 20px">This is the body of the overlay</div>
    </ng-container>
    <ng-container buttons>
      <pc-button [text]="'Do something'"></pc-button>
    </ng-container>
  </pc-overlay>
}
