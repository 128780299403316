<div class="container" #pickerContainer (clickOutside)="expanded = false">
  <!-- Show date button -->
  <div class="custom-density-2 input" style="margin-bottom: 2px">
    <pc-button variant="secondary" [size]="size === 'minimal' ? 'minimal' : 'small'" (btnClick)="expand()">
      <div before style="margin-right: 8px">
        <app-icon name="calendar" style="width: 20px" color="var(--primary)"></app-icon>
      </div>
      @if (!single) {
        <div text [id]="elementID || 'dateRangePicker'">
          {{ control?.value | materialDateRangeDisplay }}
        </div>
      }
      @if (single && time) {
        <div>{{ control?.value | pcDate: true }}</div>
      }
      @if (single && !time) {
        <div>{{ control?.value | pcDate }}</div>
      }
      <div>
        <app-icon [name]="expanded ? 'chevronUp' : 'chevronDown'" style="width: 20px; margin-left: 4px" color="var(--primary)"></app-icon>
      </div>
    </pc-button>
  </div>

  <!-- Picker -->
  @if (expanded) {
    <flex-row
      class="selection"
      [style.position]="fixed ? 'fixed' : 'absolute'"
      [style.left]="fixed ? leftOffset : isTooFarRight ? '' : '0px'"
      [class.right-aligned]="isTooFarRight"
      [class.left-aligned]="!isTooFarRight"
      [style.width]="calendarExpanded ? '556px' : '248px'"
      #selectionContainer>
      <!-- Predefined Dynamic ranges -->
      @if (!single) {
        <flex-col class="pre-defined">
          <pc-input-search placeholder="Dynamic date" (valueChanged)="filterPresets($event)"></pc-input-search>
          <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="false" style="max-height: 230px; min-height: 230px">
            @for (item of filteredPresets; track item) {
              <div class="range-item" (click)="selectByToken(item.key)">
                {{ item.value }}
              </div>
            }
          </ng-scrollbar>
          <flex-row class="range-item" (click)="calendarExpanded = !calendarExpanded; setMenuPosition()">
            <div-grow>Fixed date</div-grow>
            <div>
              <app-icon [name]="calendarExpanded ? 'chevronLeft' : 'chevronRight'" style="width: 20px; margin-left: 4px" color="var(--primary)"></app-icon>
            </div>
          </flex-row>
          <flex-row>
            <p class="timezone-info">Timezone: {{ proficloud.userTimezone }}</p>
          </flex-row>
        </flex-col>
      }
      @if (calendarExpanded || single) {
        <flex-col class="picker">
          <!-- Date Range -->
          @if (!single) {
            <mat-calendar [selected]="actualDateRange" (selectedChange)="rangeChanged($event)" [maxDate]="today"></mat-calendar>
          }
          <!-- Single Date -->
          @if (single) {
            <mat-calendar [selected]="selectedDate" (selectedChange)="dateChanged($event)" [maxDate]="today"></mat-calendar>
          }
          <!-- Time picker -->
          @if (time) {
            <flex-row>
              <div style="margin-left: 8px; margin-right: 4px; flex-grow: 1">
                <pc-input-time-picker (timeChanged)="handleStartTimeChange($event)" [currentTime]="selectedStartTime"></pc-input-time-picker>
              </div>
              @if (!single) {
                <div style="flex-grow: 1; margin-right: 4px">
                  <pc-input-time-picker (timeChanged)="handleEndTimeChange($event)" [currentTime]="selectedEndTime"></pc-input-time-picker>
                </div>
              }
              <div><pc-button size="small" (btnClick)="applySelection()">Apply</pc-button></div>
            </flex-row>
          }
          <!-- DEBUG -->
          <!-- <div>
          {{ dateRange | materialDateRangeDisplay }}
        </div> -->
        </flex-col>
      }
    </flex-row>
  }
</div>
