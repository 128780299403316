{
  "isoLangs": {
    "en-GB": {
      "name": "English",
      "nativeName": "English"
    },
    "de-DE": {
      "name": "German",
      "nativeName": "Deutsch"
    }
  }
}
