import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Subject, debounceTime, tap } from 'rxjs'
import { SharedUIService } from '../../services/shared-ui/shared-ui.service'

@Component({
  selector: 'pc-scroll',
  templateUrl: './pc-scroll.component.html',
  styleUrl: './pc-scroll.component.scss',
})
export class PcScrollComponent {
  @Input()
  vertical: boolean = true

  @Input()
  horizontal: boolean = false

  @Input()
  autoScroll: string

  @Output()
  onScrollBottom: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('container')
  container: ElementRef

  scrolled$ = new Subject<Event>()

  constructor(private sharedUI: SharedUIService) {
    // Fire scrolled event
    this.scrolled$
      .pipe(
        debounceTime(300),
        tap((event) => {
          this.onScroll(event.target as HTMLElement)
        })
      )
      .subscribe()

    // Listen to shared service dragged event and trigger auto scrolling if necessary
    this.sharedUI.elementDragged$.subscribe((context) => {
      if (context.context === this.autoScroll) {
        const rect = this.container.nativeElement.getBoundingClientRect()

        if (context.y) {
          // console.log(context.y)
          // console.log(rect)

          // Scroll up if near top
          if (context.y <= rect.top + rect.height * 0.1) {
            // console.log('scroll up')
            this.container.nativeElement.scrollTop -= 40
          }

          // Scroll down if near bottom
          if (context.y >= rect.bottom * 0.9) {
            // console.log('scroll down')
            this.container.nativeElement.scrollTop += 40
          }
        }
      }
    })
  }

  // Trigger scrolled to bottom event if we're near the bottom of the scrollable area (for infinite scrolling)
  public onScroll(element: HTMLElement) {
    if ((element.getBoundingClientRect().height + element.scrollTop) / element.scrollHeight > 0.9) {
      this.onScrollBottom.emit()
    }
  }
}
