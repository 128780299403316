import { Component, Input, OnInit } from '@angular/core'
import { StatusOverlayService } from 'src/app/modules/proficloud/modules/device-management/services/status-overlay.service'
import { MultiStatusOverlayContent } from 'src/app/modules/proficloud/services/proficloud.interfaces'
import { ProficloudService } from 'src/app/modules/proficloud/services/proficloud.service'
import { PcStatusOverlayService } from '../../services/pc-status-overlay/pc-status-overlay.service'

@Component({
  selector: 'app-proficloud-multi-status-overlay',
  templateUrl: './proficloud-multi-status-overlay.component.html',
  styleUrls: ['./proficloud-multi-status-overlay.component.scss'],
})
export class ProficloudMultiStatusOverlayComponent implements OnInit {
  @Input() content: MultiStatusOverlayContent

  constructor(
    public proficloud: ProficloudService,
    public statusOverlay: StatusOverlayService,
    private pcStatus: PcStatusOverlayService
  ) {}

  ngOnInit(): void {
    this.statusOverlay.multiStatusOverlay$.subscribe({
      next: (status) => {
        if (status && status.status.filter((entry) => entry.icon == 'spinner').length == 0) {
          setTimeout(() => {
            // auto_close mechanism
            if (status.auto_close) {
              this.statusOverlay.multiStatusOverlay$.next(false)
            }
            if (status.status[0].icon == 'success') {
              // show success overlay
              this.pcStatus.showStatus(this.statusOverlay.statusOverlays.showDeviceTokenResetSuccess)
              setTimeout(() => {
                // clear success overlay
                this.pcStatus.resetStatus()
              }, 3000)
            } else {
              // show error overlay
              this.pcStatus.showStatus(this.statusOverlay.statusOverlays.showDeviceTokenResetError)
              setTimeout(() => {
                // clear error overlay
                this.pcStatus.resetStatus()
              }, 3000)
            }
          }, 2000)
        }
      },
    })
  }
}
