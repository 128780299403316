import { PCStatusOverlayConfig } from '../../shared/components/pc-status-overlay/pc-status-overlay.component'

export const statusOverlays: Record<string, PCStatusOverlayConfig> = {
  // cancel firmware update
  cancelFirmwareUpdateBusy: {
    type: 'spinner',
    message: 'Sending cancellation request.',
  },
  cancelFirmwareUpdateSuccess: {
    type: 'success',
    message: 'The cancellation request has been sent successfully.',
  },
  cancelFirmwareUpdateError: {
    type: 'error',
    message: 'An error occured while sending the cancellation request.',
    closeable: true,
  },
  // saving log entry
  saveLogBusy: {
    type: 'spinner',
    message: 'Saving log entry.',
  },
  saveLogSuccess: {
    type: 'success',
    message: 'The log entry has been saved successfully.',
  },
  saveLogError: {
    type: 'error',
    message: 'An error occured while saving the log entry.',
    closeable: true,
  },
  // subscription downgrade
  downgradeSubscriptionBusy: {
    type: 'spinner',
    message: 'Your subscription is being downgraded.',
  },
  downgradeSubscriptionSuccess: {
    type: 'success',
    message: 'Your subscription has been successfully downgraded.',
  },
  downgradeSubscriptionError: {
    type: 'error',
    message: 'An error occured while downgrading your subscription.',
    closeable: true,
  },
  // subscription upgrade
  upgradeSubscriptionBusy: {
    type: 'spinner',
    message: 'Your subscription is being upgraded.',
  },
  upgradeSubscriptionSuccess: {
    type: 'success',
    message: 'Your subscription has been successfully upgraded.',
  },
  upgradeSubscriptionError: {
    type: 'error',
    message: 'An error occured while upgrading your subscription.',
    closeable: true,
  },
  // subscription cancellation
  cancelSubscriptionBusy: {
    type: 'spinner',
    message: 'Your subscription is being cancelled.',
  },
  cancelSubscriptionSuccess: {
    type: 'success',
    message: 'Your subscription has been successfully cancelled.',
  },
  cancelSubscriptionError: {
    type: 'error',
    message: 'An error occured while cancelling your subscription',
    closeable: true,
  },
  // devices
  assignDevicesBusy: {
    type: 'spinner',
    message: 'Assigning devices',
  },
  assignDevicesError: {
    type: 'error',
    message: 'An error occured while assigning devices.',
    closeable: true,
  },
  assignDevicesSuccess: {
    type: 'success',
    message: 'Successfully assigned devices.',
    closeable: true,
  },
  fetchDevicesError: {
    type: 'error',
    message: 'An error occured while fetching devices.',
    closeable: true,
  },
  // Register
  registerSuccess: {
    type: 'success',
    message: 'Your account has been created. Please check your email for a confirmation link.',
    closeable: true,
  },
  registerBusy: {
    type: 'spinner',
    message: 'Creating account',
  },
  registerError: {
    type: 'error',
    message: 'There was a problem creating your account.',
    closeable: true,
  },
  registerUserExistsError: {
    type: 'error',
    message: 'You are already registered. Please check the inbox of your given email address. If you experience any trouble, please contact our support.',
    closeable: true,
  },
  // Request password reset
  requestPasswordResetSuccess: {
    type: 'success',
    message: 'Please check your email for a link to reset your password.',
    closeable: true,
  },
  requestPasswordResetBusy: {
    type: 'spinner',
    message: 'Requesting password reset',
  },
  requestPasswordResetError: {
    type: 'error',
    message: 'There was a problem requesting a password reset.',
    closeable: true,
  },
  // Reset password
  resetPasswordSuccess: {
    type: 'success',
    message: 'Password has been reset. Please log in again with your new credentials.',
    closeable: true,
  },
  resetPasswordBusy: {
    type: 'spinner',
    message: 'Resetting password',
  },
  resetPasswordError: {
    type: 'error',
    message: 'There was a problem resetting your password.',
    closeable: true,
  },
  // Change Email
  changeEmailSuccess: {
    type: 'success',
    message: 'Email has been changed. Please log in again with your new email.',
    closeable: true,
  },
  changeEmailBusy: {
    type: 'spinner',
    message: 'Changing email',
  },
  changeEmailError: {
    type: 'error',
    message: 'There was a problem changing your email.',
    closeable: true,
  },
  // Invite
  inviteSuccess: {
    type: 'success',
    message: 'User has been invited',
    closeable: true,
  },
  inviteBusy: {
    type: 'spinner',
    message: 'Inviting user',
  },
  inviteError: {
    type: 'error',
    message: 'There was a problem inviting the user',
    closeable: true,
  },
  // Export
  exportBusy: {
    type: 'spinner',
    message: 'Exporting metric data',
  },
  exportError: {
    type: 'error',
    message: 'An error occured while exporting metric data.',
    closeable: true,
  },
  // Import
  importBusy: {
    type: 'spinner',
    message: 'Importing metrics',
  },
  importSuccess: {
    type: 'success',
    message: 'Successfully imported CSV as metrics.',
    closeable: true,
  },
  importError: {
    type: 'error',
    message: 'An error occured while importing metrics.',
    closeable: true,
  },
  importTooLargeError: {
    type: 'error',
    message: 'The data file you tried to upload was too large.',
    closeable: true,
  },
  importCancelled: {
    type: 'error',
    message: 'The CSV upload was cancelled.',
    closeable: true,
  },

  createCalculationBusy: {
    type: 'spinner',
    message: 'Creating calculation',
  },
  createCalculationSuccess: {
    type: 'success',
    message: 'Succesfully created calculation.',
    closeable: true,
  },
  createCalculationError: {
    type: 'error',
    message: 'An error occured while creating calculation.',
    closeable: true,
  },

  editCalculationBusy: {
    type: 'spinner',
    message: 'Updating calculation',
  },
  editCalculationSuccess: {
    type: 'success',
    message: 'Succesfully updated calculation.',
    closeable: true,
  },
  editCalculationError: {
    type: 'error',
    message: 'An error occured while updating calculation.',
    closeable: true,
  },

  // Create virtual device
  createVirtualDeviceBusy: {
    type: 'spinner',
    message: 'Creating virtual device',
  },
  createVirtualDeviceSuccess: {
    type: 'success',
    message: 'Succesfully created virtual device.',
    closeable: true,
  },
  createVirtualDeviceError: {
    type: 'error',
    message: 'An error occured while creating virtual device.',
    closeable: true,
  },
  // Create virtual device
  importMetersBusy: {
    type: 'spinner',
    message: 'Importing meters from external database',
  },
  importMetersSuccess: {
    type: 'success',
    message: 'Succesfully imported meters from external databse.',
    closeable: true,
  },
  importMetersError: {
    type: 'error',
    message: 'An error occured while importing meters from external database.',
    closeable: true,
  },
  // Import DMS Meters
  importDMSMetersBusy: {
    type: 'spinner',
    message: 'Importing meters from Device Management Service',
  },
  importDMSMetersSuccess: {
    type: 'success',
    message: 'Succesfully imported meters from Device Management Service.',
    closeable: true,
  },
  importDMSMetersError: {
    type: 'error',
    message: 'An error occured while importing meters from Device Management Service.',
    closeable: true,
  },
  // Import from inbox
  importFromInboxError: {
    type: 'error',
    message: 'An error occured while importing metric from Metrics Inbox.',
    closeable: true,
  },
  // Import from inbox
  importFromCSVError: {
    type: 'error',
    message: 'An error occured while importing metric from CSV.',
    closeable: true,
  },
  // metrics
  updateMetricsBusy: {
    type: 'spinner',
    message: 'Updating used metrics',
  },
  updateMetricsSuccess: {
    type: 'success',
    message: 'Metrics updated successfully.',
  },
  updateMetricsError: {
    type: 'error',
    message: 'An error occured while updating metrics.',
    closeable: true,
  },
  // subscriptions
  createSubscriptionBusy: {
    type: 'spinner',
    message: 'Creating your subscription.',
  },
  createSubscriptionSuccess: {
    type: 'success',
    message: 'The subscription has been created.',
  },
  createSubscriptionError: {
    type: 'error',
    message: 'An error occured while creating your subscription.',
    closeable: true,
  },
  // create billing account
  createBillingAccountBusy: {
    type: 'spinner',
    message: 'Creating your billing account.',
  },
  createBillingAccountSuccess: {
    type: 'success',
    message: 'The billing account has been created.',
  },
  createBillingAccountError: {
    type: 'error',
    message: 'An error occured while creating your billing account. Please check your VAT number for correctness.',
    closeable: true,
  },
  createBillingAccountTimeout: {
    type: 'error',
    message: 'The request has timed out. Please try again or contact our support at info@phoenixcontact-sb.io.',
    closeable: true,
  },
  // Create Organization
  createOrganizationSuccess: {
    type: 'success',
    message: 'Organization has been created',
    closeable: true,
  },
  createOrganizationBusy: {
    type: 'spinner',
    message: 'Creating organization',
  },
  createOrganizationError: {
    type: 'error',
    message: 'There was a problem creating the organization',
    closeable: true,
  },
  leaveOrganizationBusy: {
    type: 'spinner',
    message: 'Leaving organization',
  },
  leaveOrganizationSuccess: {
    type: 'success',
    message: 'You left the organization successfully.',
  },
  leaveOrganizationError: {
    type: 'error',
    message: 'There was a problem leaving the organization',
    closeable: true,
  },
  removeUserFromOrganizationSuccess: {
    type: 'success',
    message: 'You removed the user from the organization successfully.',
  },
  removeUserFromOrganizationError: {
    type: 'error',
    message: 'There was a problem removing the user from the organization',
    closeable: true,
  },
  leaveOrganizationLMError: {
    type: 'error',
    message:
      'There was a problem leaving the organization. You are the last administrator. If you want to delete your organization, please get in contact with us and we will help you.',
    closeable: true,
  },
  editOrganizationBusy: {
    type: 'spinner',
    message: 'Edit organization data',
  },
  editOrganizationSuccess: {
    type: 'success',
    message: 'You changed the organization name successfully.',
  },
  editOrganizationError: {
    type: 'error',
    message: 'There was a problem editing the organization name',
    closeable: true,
  },
  acceptInvitationSuccess: {
    type: 'success',
    message: 'The invitation was successfully accepted.',
    closeable: true,
  },
  acceptInvitationError: {
    type: 'error',
    message: 'There was a problem accepting the invitation.',
    closeable: true,
  },
  // delete billing account
  deleteBillingAccountBusy: {
    type: 'spinner',
    message: 'Deleting your billing account.',
  },
  deleteBillingAccountSuccess: {
    type: 'success',
    message: 'The billing account has been deleted.',
  },
  deleteBillingAccountError: {
    type: 'error',
    message: 'An error occured while deleting your billing account.',
    closeable: true,
  },
  updateBillingAccountBusy: {
    type: 'spinner',
    message: 'Updating your billing account',
  },
  updateBillingAccountError: {
    type: 'error',
    message: 'An error occured while updating your billing account',
    closeable: true,
  },
  updateBillingAccountSuccess: {
    type: 'success',
    message: 'Your billing account has been updated.',
  },
  authError: {
    type: 'error',
    message: 'Your credentials are incorrect, or your account has not been verified yet.',
    closeable: true,
  },
  authSuccess: {
    type: 'success',
    message: 'Authentication successful',
  },
  authBusy: {
    type: 'spinner',
    message: 'Logging you in...',
  },
  signupBusy: {
    type: 'spinner',
    message: 'creating your account',
  },
  signupSuccess: {
    type: 'success',
    message: 'You account has been set up.',
  },
  signupError: {
    type: 'error',
    message: 'This email has already already been registered',
    closeable: true,
  },
  updateUserError: {
    message: 'The user profile data could not be updated.',
    type: 'error',
    closeable: true,
  },
  emailChangedSuccess: {
    message: 'Your email address will be updated once you confirm the verification email we sent you. You will be logged out automatically now.',
    type: 'success',
    closeable: true,
  },
  deleteUserBusy: {
    type: 'spinner',
    message: 'Deleting Your Account.',
  },
  deleteUserError: {
    message: 'It was not possible to delete your account, please contact our support.',
    type: 'error',
    closeable: true,
  },
  deleteUserWrongEmailError: {
    message: 'It was not possible to delete your account. The email you provided was incorrect.',
    type: 'error',
    closeable: true,
  },
  tokenResetSuccess: {
    message: 'The device token has been reset.',
    type: 'success',
    closeable: true,
  },
  tokenResetError: {
    message: 'The device token could not be reset.',
    type: 'error',
    closeable: true,
  },
  tokenResetProgress: {
    message: 'Resetting device token',
    type: 'spinner',
    closeable: false,
  },
  incompleteDataFutureDate: {
    message: 'At least one of the selected date ranges is in the future so the data will be incomplete.',
    type: 'error',
    closeable: true,
  },
  invalidDateRange: {
    message: 'The chart configuration has an invalid date range and could not be rendered.',
    type: 'error',
    closeable: true,
  },
  chartBuildFailed: {
    message: 'Chart construction failed.',
    type: 'error',
    closeable: true,
  },
  chartInvalidDates: {
    message: 'Chart start/end dates are invalid.',
    type: 'error',
    closeable: true,
  },
  // Members
  membersSuccess: {
    type: 'success',
    message: 'Members successfully fetched',
    closeable: true,
  },
  membersBusy: {
    type: 'spinner',
    message: 'Fetching organization members',
  },
  memberRoleChange: {
    type: 'spinner',
    message: 'Updating member role.',
  },
  memberRoleChangeSuccess: {
    type: 'success',
    message: `The member's role has been successfully updated.`,
    closeable: true,
  },
  memberRoleChangeError: {
    type: 'error',
    message: `The member's role could not be updated.`,
    closeable: true,
  },
  membersError: {
    type: 'error',
    message: 'There was a problem fetching the organization members',
    closeable: true,
  },
  // Members
  switchOrganisationSuccess: {
    type: 'success',
    message: 'Succesfully switched organization',
    closeable: true,
  },
  switchOrganisationBusy: {
    type: 'spinner',
    message: 'Switching organization',
  },
  switchOrganisationError: {
    type: 'error',
    message: 'There was a problem switching organization',
    closeable: true,
  },
  switchOrganisationStuckError: {
    type: 'error',
    message: 'There was a network problem while switching organization. You will be switched into the default organization after a reload.',
    closeable: true,
  },
  listOrganisationStuckError: {
    type: 'error',
    message:
      'There was a network problem while loading your organizations. You will be logged of automatically. Please log in again. If this will not work contact our support.',
    closeable: false,
  },
  // charge-repay
  addWallboxBusy: {
    message: 'Adding the wallbox and inviting the driver',
    type: 'spinner',
    closeable: false,
  },
  addWallboxSuccess: {
    message: 'The wallbox was successfully added and the driver has been invited.',
    type: 'success',
    closeable: true,
  },
  addWallboxError: {
    message: 'An error occored while adding the wallbox.',
    type: 'error',
    closeable: true,
  },
  // driver invitation
  inviteDriverBusy: {
    message: 'Inviting the driver',
    type: 'spinner',
    closeable: false,
  },
  inviteDriverSuccess: {
    message: 'The driver has been invited.',
    type: 'success',
    closeable: true,
  },
  inviteDriverError: {
    message: 'An error occored while inviting the driver.',
    type: 'error',
    closeable: true,
  },
  // seat assignment
  assignSeatBusy: {
    message: 'Seat is being assigned.',
    type: 'spinner',
    closeable: false,
  },
  assignSeatSuccess: {
    message: 'Seat has been successfully assigned.',
    type: 'success',
    closeable: true,
  },
  assignSeatError: {
    message: 'A seat could not be assigned.',
    type: 'error',
    closeable: true,
  },
  unassignSeatBusy: {
    message: 'Seat is being un-assigned.',
    type: 'spinner',
    closeable: false,
  },
  unassignSeatSuccess: {
    message: 'Seat has been successfully un-assigned.',
    type: 'success',
    closeable: true,
  },
  unassignSeatError: {
    message: 'A seat could not be un-assigned.',
    type: 'error',
    closeable: true,
  },
  // seat un-assignment
  setWallboxPriceBusy: {
    message: 'Übertrage Strompreis...',
    type: 'spinner',
    closeable: false,
  },
  setWallboxPriceSuccess: {
    message: 'Der Strompreis wurde erfolgreich übertragen',
    type: 'success',
    closeable: true,
  },
  setWallboxPriceError: {
    message: 'Ein Fehler ist beim übetragen des Strompreises aufgetreten.',
    type: 'error',
    closeable: true,
  },
  setMileageError: {
    message: 'Es ist ein Fehler beim Übertragen des Kilometerstandes aufgetreten.',
    type: 'error',
    closeable: true,
  },
  // contract upload
  uploadContractBusy: {
    message: 'Vertrag wird hochgeladen...',
    type: 'spinner',
    closeable: false,
  },
  uploadContractSuccess: {
    message: 'Der Vertrag wurde erfolgreich hochgeladen.',
    type: 'success',
    closeable: true,
  },
  // charge repay
  setManagerPriceBusy: {
    type: 'spinner',
    message: 'Saving settings.',
  },
  setManagerPriceSuccess: {
    type: 'success',
    message: 'You settings have been applied.',
    closeable: true,
  },
  setManagerPriceError: {
    type: 'error',
    message: 'An error occored while applying your settings.',
    closeable: true,
  },
  // set car type
  setCarTypeBusy: {
    message: 'Übertrage Fahrzeugart',
    type: 'spinner',
    closeable: false,
  },
  setCarTypeError: {
    message: 'Ein Fehler ist beim Übermitteln der Fahrzeugart aufgetreten.',
    type: 'error',
    closeable: true,
  },
  setCarTypeSuccess: {
    message: 'Die Fahrzeugart wurde erfolgreich übertragen',
    type: 'success',
    closeable: true,
  },
  // eichrecht
  setEichrechtBusy: {
    message: 'Übertrage Eichrecht-Einstellung...',
    type: 'spinner',
    closeable: false,
  },
  setEichrechtSuccess: {
    message: 'Ihre Einstellung wurde erfolgreich übertragen',
    type: 'success',
    closeable: true,
  },
  setEichrechtError: {
    message: 'Ein Fehler ist beim übetragen ihrer Einstellung aufgetreten.',
    type: 'error',
    closeable: true,
  },
  // Dashboards
  dashboardsLoadingBusy: {
    type: 'spinner',
    message: 'Loading dashboards.',
  },
  dashboardsLoadingSuccess: {
    type: 'success',
    message: 'Dashboards loaded successfully.',
  },
  dashboardsLoadingError: {
    type: 'error',
    message: 'An error occured while loading the dashboards.',
    closeable: true,
  },
  // Device Subscriptions
  deviceSubscriptionsLoadingBusy: {
    type: 'spinner',
    message: 'Loading device subscriptions.',
  },
  deviceSubscriptionsLoadingSuccess: {
    type: 'success',
    message: 'Device subscriptions loaded successfully.',
  },
  deviceSubscriptionsLoadingError: {
    type: 'error',
    message: 'An error occured while loading the device subscriptions.',
    closeable: true,
  },
  // Alert Rules
  alertsLoadingError: {
    type: 'error',
    message: 'An error occured while loading the alerts.',
    closeable: true,
  },
  alertRuleCreationBusy: {
    type: 'spinner',
    message: 'Creating alert rule.',
  },
  alertRuleCreationSuccess: {
    type: 'success',
    message: 'Alert rule created.',
  },
  alertRuleCreationError: {
    type: 'error',
    message: 'An error occured while creating the alert rule.',
    closeable: true,
  },
  alertRuleDeactivate: {
    type: 'success',
    message: 'Alert rule Deactivated',
  },
  alertRuleActivate: {
    type: 'success',
    message: 'Alert rule Activated',
  },
  alertRuleDisableError: {
    type: 'error',
    message: 'An error occured while disabling the alert rule.',
  },
  alertRuleEnableError: {
    type: 'error',
    message: 'An error occured while enabling the alert rule.',
  },
  alertRuleFetchBusy: {
    type: 'spinner',
    message: 'Fetching alert rules',
  },
  alertRuleFetchSuccess: {
    type: 'success',
    message: 'Alert rules fetched.',
  },
  alertRuleFetchError: {
    type: 'error',
    message: 'An error occured while fetching alert rules.',
    closeable: true,
  },
  cleanAlertLogSuccess: {
    type: 'success',
    message: 'Alert log has been cleaned',
    closeable: true,
  },
  cleanAlertLogBusy: {
    type: 'spinner',
    message: 'Cleaning alert log',
  },
  cleanAlertLogError: {
    type: 'error',
    message: 'There was a problem cleaning the alert log',
    closeable: true,
  },
  deleteInvitationBusy: {
    type: 'spinner',
    message: 'Deleting Invitation',
  },
  deleteInvitationSuccess: {
    type: 'success',
    message: 'Invitation successfully deleted.',
  },
  deleteInvitationError: {
    type: 'error',
    message: 'An error occured while deleting the invitation.',
    closeable: true,
  },
  emmaSubscriptionsFetchBusy: {
    type: 'spinner',
    message: 'Fetching EMMA subscriptions',
  },
  emmaSubscriptionsFetchSuccess: {
    type: 'success',
    message: 'EMMA subscriptions fetched.',
  },
  emmaSubscriptionsFetchError: {
    type: 'error',
    message: 'An error occured while fetching EMMA subscriptions.',
    closeable: true,
  },
  emmaDeleteMeterBusy: {
    type: 'spinner',
    message: 'Meter is being deleted.',
    closeable: true,
  },
  emmaDeleteMeterError: {
    type: 'error',
    message: 'An error occured while deleting the meter.',
    closeable: true,
  },
  emmaDeleteMeterSuccess: {
    type: 'success',
    message: 'Meter successfully deleted.',
    closeable: true,
  },
  emmaRenameMeterBusy: {
    type: 'spinner',
    message: 'Meter is being renamed.',
    closeable: true,
  },
  emmaRenameMeterError: {
    type: 'error',
    message: 'An error occured while renaming the meter.',
    closeable: true,
  },
  emmaRenameMeterSuccess: {
    type: 'success',
    message: 'Meter successfully renamed.',
    closeable: true,
  },
  emmaEditMetricBusy: {
    type: 'spinner',
    message: 'Metric is being edited.',
    closeable: true,
  },
  emmaEditMetricError: {
    type: 'error',
    message: 'An error occured while editing the metric.',
    closeable: true,
  },
  emmaEditMetricSuccess: {
    type: 'success',
    message: 'Metric successfully edited.',
    closeable: true,
  },
  emmaFetchUnitsError: {
    type: 'error',
    message: 'An error occured while fetching units and types.',
    closeable: true,
  },
  emmaFetchQueryablesError: {
    type: 'error',
    message: 'An error occured while fetching queryable data sources.',
    closeable: true,
  },
  emmaCreateMeteringPointError: {
    type: 'error',
    message: 'An error occured while creating the metering point.',
    closeable: true,
  },
  emmaUpdateMeteringPointError: {
    type: 'error',
    message: 'An error occured while updating the metering point.',
    closeable: true,
  },
  emmaUpdateMetricError: {
    type: 'error',
    message: 'An error occured while updating the metric point.',
    closeable: true,
  },
  emmaUpdateAggregatedMetricError: {
    type: 'error',
    message: 'An error occured while updating the aggregated metric point.',
    closeable: true,
  },
  emmaImportLargeFileError: {
    type: 'error',
    message: 'File size combined should be less than 50MB',
  },
  emmaExportEmailSuccess: {
    type: 'success',
    message: 'Export successfully started. You will retrieve an email in a few minutes.',
  },
  emmaMetricValueChangeError: {
    type: 'error',
    message: 'Updating metric value failed. This might be caused by multiple users changing the same value simultaneously.',
  },
  emmaChangeRevertError: {
    type: 'error',
    message: 'Failed to revert.',
  },
  servicePackageNoPermissionError: {
    type: 'error',
    message: 'You do not have the right permissions',
    extraInfo: 'You do not have the correct permissions to view the plans of this Smart Service',
    closeable: true,
    onCloseRoute: 'services/store',
  },
  changeOrganizationSettingsBusy: {
    type: 'spinner',
    message: 'Applying your changes.',
    closeable: true,
  },
  changeOrganizationSettingsSuccess: {
    type: 'success',
    message: 'Your changes have been applied successfully.',
    closeable: true,
  },
  changeOrganizationSettingsError: {
    type: 'error',
    message: 'It was not possible to apply your changes. Please contact our support: inbox@phoenixcontact-sb.io.',
    closeable: true,
  },
  assignRBACDevicesBusy: {
    type: 'spinner',
    message: 'Applying device access changes.',
    closeable: true,
  },
  assignRBACDevicesSuccess: {
    type: 'success',
    message: 'Your changes to the device settings have been applied successfully.',
    closeable: true,
  },
  assignRBACDevicesError: {
    type: 'error',
    message: 'It was not possible to apply the new device access settings. Please contact our support: inbox@phoenixcontact-sb.io.',
    closeable: true,
  },
  simulationEditNameError: {
    type: 'error',
    message: 'There was a problem editing the name of your simulation.',
    closeable: true,
  },
}
