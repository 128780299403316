<!-- Renders the PRIMARY variant of the button only -->
@if (variant === 'primary') {
  <div class="pc-icon-dropdown__container" (clickOutside)="isOpen = false">
    <button
      class="pc-icon-dropdown pc-icon-dropdown--primary"
      [class.pc-icon-dropdown--standard]="size === 'regular'"
      [class.pc-icon-dropdown--small]="size === 'small'"
      [class.pc-icon-dropdown--large]="size === 'large'"
      [class.pc-icon-dropdown--wide]="wide"
      [class.pc-icon-dropdown--icon-only]="iconOnly"
      [disabled]="disabled"
      (click)="toggle()"
      (mouseenter)="hoverChanged($event)"
      (mouseleave)="hoverChanged($event)"
      [@hoverState]="
        hover ? { value: 'hovered', params: { bgColorHovered: 'var(--secondary)' } } : { value: 'default', params: { bgColor: 'var(--primary)' } }
      ">
      <div class="pc-icon-dropdown__button-container">
        @if (!noPrefix) {
          <app-icon
            [class.pc-icon-dropdown__left-icon--small]="size === 'small'"
            [class.pc-icon-dropdown__left-icon]="size === 'regular'"
            [class.pc-icon-dropdown__left-icon--icon-only]="iconOnly"
            [name]="iconName"
            [color]="disabled ? 'var(--regent-gray)' : 'var(--white)'"
            [@rotatedUpwardState]="descending && !iconOnly ? 'rotated' : 'default'">
          </app-icon>
        }
        @if (!iconOnly) {
          <span class="pc-icon-dropdown__text" [class.pc-icon-dropdown__text--no-prefix]="noPrefix">{{ selectedItem ? selectedItem.name : text }}</span>
        }
        @if (!iconOnly) {
          <app-icon
            [@rotatedState]="isOpen ? 'rotated' : 'default'"
            [class.pc-icon-dropdown__right-icon--small]="size === 'small'"
            [class.pc-icon-dropdown__right-icon]="size === 'regular' || size === 'large'"
            [name]="'arrowDropDownV2'"
            [color]="disabled ? 'var(--regent-gray)' : 'var(--white)'">
          </app-icon>
        }
      </div>
    </button>
    @if (isOpen) {
      <div [@dropDownMenu]="isOpen ? 'open' : 'closed'" class="pc-icon-dropdown__selection" [class.pc-icon-dropdown__selection--icon-only]="iconOnly">
        @for (itemGroup of items; track itemGroup) {
          <div>
            @if (itemGroup.label) {
              <span>{{ itemGroup.label }}</span>
            }
            @for (item of itemGroup.items; track item) {
              <div
                [class.pc-icon-dropdown__option]="size === 'regular' || size === 'large'"
                [class.pc-icon-dropdown__option--small]="size === 'small'"
                [class.pc-icon-dropdown__option--no-prefix]="noPrefix"
                (click)="selectItem(item)">
                <span [class.pc-icon-dropdown__option-text--no-prefix]="noPrefix">{{ item.name }}</span>
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}

<!-- Renders the SECONDARY variant of the button only -->
@if (variant === 'secondary') {
  <div class="pc-icon-dropdown__container" (clickOutside)="isOpen = false">
    <button
      class="pc-icon-dropdown pc-icon-dropdown--secondary"
      [class.pc-icon-dropdown--standard]="size === 'regular'"
      [class.pc-icon-dropdown--small]="size === 'small'"
      [class.pc-icon-dropdown--large]="size === 'large'"
      [class.pc-icon-dropdown--wide]="wide"
      [class.pc-icon-dropdown--icon-only]="iconOnly"
      [disabled]="disabled"
      (click)="toggle()"
      (mouseenter)="hoverChanged($event)"
      (mouseleave)="hoverChanged($event)"
      [@hoverState]="
        hover ? { value: 'hovered', params: { bgColorHovered: 'var(--white-smoke)' } } : { value: 'default', params: { bgColor: 'var(--alabaster)' } }
      ">
      <div class="pc-icon-dropdown__button-container">
        @if (!noPrefix) {
          <app-icon
            [class.pc-icon-dropdown__left-icon--small]="size === 'small'"
            [class.pc-icon-dropdown__left-icon]="size === 'regular'"
            [class.pc-icon-dropdown__left-icon--icon-only]="iconOnly"
            [name]="iconName"
            [color]="disabled ? 'var(--regent-gray)' : 'var(--primary)'"
            [@rotatedUpwardState]="descending && !iconOnly ? 'rotated' : 'default'">
          </app-icon>
        }
        @if (!iconOnly) {
          <span class="pc-icon-dropdown__text" [class.pc-icon-dropdown__text--no-prefix]="noPrefix">{{ selectedItem ? selectedItem.name : text }}</span>
        }
        @if (!iconOnly) {
          <app-icon
            [@rotatedState]="isOpen ? 'rotated' : 'default'"
            [class.pc-icon-dropdown__right-icon--small]="size === 'small'"
            [class.pc-icon-dropdown__right-icon]="size === 'regular' || size === 'large'"
            [name]="'arrowDropDownV2'"
            [color]="disabled ? 'var(--regent-gray)' : 'var(--primary)'">
          </app-icon>
        }
      </div>
    </button>
    @if (isOpen) {
      <div [@dropDownMenu]="isOpen ? 'open' : 'closed'" class="pc-icon-dropdown__selection" [class.pc-icon-dropdown__selection--icon-only]="iconOnly">
        @for (itemGroup of items; track itemGroup) {
          <div>
            @if (itemGroup.label) {
              <span class="pc-icon-dropdown__label">{{ itemGroup.label }}</span>
            }
            @for (item of itemGroup.items; track item) {
              <div>
                @if (!item.selectable) {
                  <div
                    [class.pc-icon-dropdown__option]="size === 'regular' || size === 'large'"
                    [class.pc-icon-dropdown__option--small]="size === 'small'"
                    [class.pc-icon-dropdown__option--no-prefix]="noPrefix"
                    [class.pc-icon-dropdown__option--selectable]="item.selectable"
                    (click)="!item.selectable && selectItem(item)">
                    <span [class.pc-icon-dropdown__option-text--no-prefix]="noPrefix">{{ item.name }}</span>
                  </div>
                }
                @if (item.selectable) {
                  <div
                    [class.pc-icon-dropdown__option]="size === 'regular' || size === 'large'"
                    [class.pc-icon-dropdown__option--small]="size === 'small'"
                    [class.pc-icon-dropdown__option--no-prefix]="noPrefix"
                    [class.pc-icon-dropdown__option--selectable]="item.selectable">
                    @if (item.selectable) {
                      <mat-checkbox [checked]="item.selected" (change)="checkedItem(item)" [id]="item.value">
                        {{ item.name }}
                      </mat-checkbox>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}
