import { Pipe, PipeTransform } from '@angular/core'
@Pipe({
  name: 'deviceCssPrefix',
})
export class DeviceCssPrefixPipe implements PipeTransform {
  transform(trafficLightNumber: 0 | 1 | 2, connected: boolean | 'online'): string {
    const stateMap = {
      0: 'Ok',
      1: 'Warning',
      2: 'Error',
    }
    const connectedName = connected ? 'connected' : 'disconnected'
    const stateName = stateMap[trafficLightNumber]
    return connectedName + stateName + 'Device'
  }
}

@Pipe({
  name: 'stateIconName',
})
export class stateIconNamePipe implements PipeTransform {
  transform(trafficLightNumber: 0 | 1 | 2): string {
    switch (trafficLightNumber) {
      case 0:
        return 'check'
      case 1:
        return 'warning'
      case 2:
        return 'error'
    }
  }
}

@Pipe({
  name: 'stateIconColor',
})
export class stateIconColorPipe implements PipeTransform {
  transform(trafficLightNumber: 0 | 1 | 2, connected: boolean | 'online' | undefined) {
    // Don´t show icon if traffic light is ok
    if (trafficLightNumber == 0) {
      return 'transparent'
    }

    // In strong black if there is at least a warning and it is connected
    else if (connected) {
      return 'var(--jet-black)'
    }

    // In orange or darkred if there is a problem and it is not connected
    else if (trafficLightNumber == 1) {
      return 'orange'
    } else if (trafficLightNumber == 2) {
      return 'darkred'
    }

    return ''
  }
}

@Pipe({
  name: 'mapIconColor',
})
export class mapIconColorPipe implements PipeTransform {
  transform(connected: boolean | 'online'): string {
    return connected ? 'var(--jet-black)' : 'gray'
  }
}

@Pipe({
  name: 'lastOnlineText',
})
export class lastOnlineTextPipe implements PipeTransform {
  transform(connected_ts: string | undefined): string {
    // Fallback if never connected
    if (!connected_ts) {
      return 'never'
      // Return formatted string
    } else {
      let date = new Date(connected_ts)
      let now = new Date()
      let millidifference: number = now.getTime() - date.getTime()
      if (millidifference / 1000 <= 60) {
        return String(Math.round(millidifference / 1000)) + 's ago'
      }
      if (millidifference / 60000 <= 60) {
        return String(Math.round(millidifference / 60000)) + 'm ago'
      }
      if (millidifference / 3600000 <= 24) {
        return String(Math.round(millidifference / 3600000)) + 'h ago'
      }
      return String(Math.round(millidifference / 86400000)) + 'd ago'
    }
  }
}
