import { Component, ContentChildren, Input, OnInit, TemplateRef } from '@angular/core'
import { TemplateMarkerDirective } from '../../directives/template-marker.directive'

export interface ITab<T = string> {
  id: T
  name: string
  selected?: boolean
}

@Component({
  selector: 'pc-tab-bar',
  templateUrl: './pc-tab-bar.component.html',
  styleUrls: ['./pc-tab-bar.component.scss'],
})
export class PCTabBarComponent implements OnInit {
  @Input() tabs: ITab[]

  @Input() emma: boolean

  @Input() subSection: boolean

  selectedTab: number // TODO: DEBUG ONLY

  @ContentChildren(TemplateMarkerDirective, { read: TemplateRef }) templates: TemplateRef<any>[]

  ngOnInit(): void {
    this.tabs.forEach((tab, index) => {
      if (tab.selected) {
        this.selectedTab = index
      }
    })
  }

  public selectTab(index: number): void {
    this.selectedTab = index
  }

  protected readonly console = console
}
