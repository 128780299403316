import { DecoratedClass } from '../services/tracing/tracing.interfaces'

/**
 * The @doc decorator
 *
 * It take the method's documentation, and sticks it into
 * the class's dictionary (which it creates should it not exsit) called methodDocs
 */
export function doc(docDict: any) {
  return function (target: DecoratedClass, propertyKey: string, descriptor: PropertyDescriptor) {
    if (!target.methodDocs) {
      target.methodDocs = []
    }

    // consider push to an array like all other decorators
    target.methodDocs[propertyKey] = docDict

    return descriptor
  }
}
