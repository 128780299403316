import { DotPart, DotPartResult, ParseResult } from './../tracing/tracing.interfaces'
// angular
import { Injectable } from '@angular/core'

// app
import { TracingService } from 'src/app/modules/shared/services/tracing/tracing.service'
import { doc } from '../../decorators/doc-decorator'
import { traced } from '../../decorators/trace-decorator'
import { tracked } from '../../decorators/track-decorator'
import { ClassDoc } from './../tracing/tracing.interfaces'
import { hyper } from './hyper.data'

@Injectable({
  providedIn: 'root',
})
export class HyperService {
  classDoc: ClassDoc = {
    name: 'HyperService',
    location: '/src/app/modules/shared/services/hyper/hyper.service.ts',
  }

  @tracked({
    what: 'All the good data for the hyper input',
    persist: {
      precedence: {
        dev: 'localstorage',
        prod: 'code',
      },
    },
  })
  hyper = hyper

  constructor(public tracing: TracingService) {
    this.tracing.registerInstance(this)
  }

  @traced({
    why: 'We have a dot.string and what to resolve it',
  })
  newParse(
    dotString: string,
    searchObject = this.tracing.namedData,
    rootObject = this.tracing.namedData,
    results: DotPartResult = {},
    visitedLinks = [] as any
  ): ParseResult {
    this.tracing.info(this, `parsing ${dotString}`)

    // let's say they entered 'sty' (not a dot string per )
    const isNamedDataMatch = this.getMatchingKeys(dotString, searchObject).length > 0

    if (!this.isDotstring(dotString) && !isNamedDataMatch) {
      this.tracing.info(this, `got neither a dotString nor lookup result`)
    }

    // create a new result object for this dotString
    const dotStringResult = {
      dotString: dotString,
      parts: [] as any,
    }
    // append the given results object with the new one for this dotString
    results = { ...results, ...dotStringResult }
    // break the dotString into parts array
    const parts = dotString.split('.')
    // the current object to use for the next part value lookup. is updated during parts loop
    let lookupObject = searchObject

    // the value each part is going to take
    let partValue
    // the result from looking up a dot string (returns target object with links)
    let targetResult

    parts.forEach((partName) => {
      const partResult: DotPart = {
        partName: partName,
        value: undefined,
      }
      dotStringResult.parts.push(partResult)

      // look up the part's value (either string, object, or dotString!)
      partValue = lookupObject[partName]

      // if it's a dotString, run it through this very function
      if (this.isDotstring(partValue)) {
        visitedLinks.push(partValue)

        this.tracing.info(this, `found a dotString at part '${partName}: ${partValue}`)

        // get the the bottom of our dotString, by running through again, starting from root
        targetResult = this.newParse(partValue, rootObject, rootObject, partResult.results, visitedLinks)
        // set the part value to the resulting value
        partValue = targetResult.value
        // record the part result in the partResult.results object
        partResult.results = targetResult.results
        // record the part value
        partResult.value = partValue
      } else {
        partResult.value = partValue
      }

      // if we didn't get a part value (data.demo.activeUs   er is missing)
      // make suggestions
      if (partResult.value === undefined) {
        const matchingKeys = this.getMatchingKeys(partName, lookupObject)
        const suggestions = matchingKeys.map((key) => {
          return {
            key: key,
            value: lookupObject[key],
            selected: false,
            fragment: key.slice(partName.length),
          }
        })
        partResult.suggestions = suggestions
      }

      // switch up to the next part's object as lookup object
      // here could be error
      lookupObject = partValue
    })
    this.tracing.info(this, `finished parsing, result is ${typeof partValue}`)
    return { value: partValue, results, visitedLinks }
  }

  getMatchingKeys(partial: string, lookupObject: any) {
    return Object.keys(lookupObject)
      .filter((k) => k.toLowerCase().startsWith(partial.toLowerCase()))
      .filter((k) => !k.includes('__'))
      .filter((k) => k !== 'path')
  }

  @doc({
    why: `we have something in our hands and want to know if it's a dot.string`,
    cavas: `only cares if beginning piece is correct`,
  })
  isDotstring(value: string) {
    return /[a-zA-Z]+\.[a-zA-Z]+/.test(value) && !value.includes('/')
  }
}
