import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-textfield',
  templateUrl: './pc-input-textfield.component.html',
  styleUrls: ['./pc-input-textfield.component.scss'],
})
export class PCInputTextfieldComponent extends PcInputBaseComponent {
  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  // Required
  @Input() control: UntypedFormControl = new UntypedFormControl()

  // Required unless it will look crap
  @Input({ required: true }) placeholder: string

  @Input() size: 'regular' | 'small' = 'regular'

  @Input() variant: 'fit-width'

  @Input() inputID: string

  @Input() initialFocus: boolean

  @Input() disabled: boolean = false

  // TODO: Implement this in the template
  @Input() errorHint: string

  // TODO: Implement this in the template
  @Input() hint: string

  @Input() numbersOnly: boolean = false

  touched: boolean

  inputFieldChanged(event: Event) {
    const value = (event.target as unknown as HTMLInputElement).value
    this.valueChanged.emit(value)
  }

  resetInput() {
    this.control.setValue('')
  }
}
