<flex-row class="filterbar">
  @if (dynamicActionText) {
    <pc-button
      variant="primary"
      [verifiable]="false"
      (btnClick)="dynamicActionTriggered()"
      [text]="dynamicActionText"
      [attr.data-analytics]="dynamicActionAnalytics"></pc-button>
  }

  <div class="filterbar__filter-options">
    <pc-input-search
      placeholder="Search"
      label="Search for members..."
      variant="fit-width"
      (valueChanged)="this.searchExecuted.emit($event)"
      class="filterbar__search"
      data-analytics="search"></pc-input-search>
    <div class="filterbar__filter">
      <pc-icon-dropdown
        variant="secondary"
        iconName="filterV2"
        iconOnly="true"
        [items]="filterValues"
        (selection)="filterChanged($event)"
        data-analytics="filter"></pc-icon-dropdown>
    </div>
    <div class="filterbar__sort">
      <pc-icon-dropdown
        [text]="'sort by'"
        variant="secondary"
        [items]="sortValues"
        (selection)="sortByChanged($event)"
        [iconName]="'arrowDown'"
        data-analytics="sort-by">
      </pc-icon-dropdown>
    </div>
  </div>
</flex-row>
