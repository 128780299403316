<!--
The switch case for the logo here is necessary, because svg viewboxes can require css adaptations.
Please do not just define a path in the environment and use it here,
it makes customization in sizing impossible.
-->

<container-element>
  @switch (env.name) {
    @case ('wl-christmas') {
      <img ngSrc="/assets/white-labels/christmas-edition/proficloud-logo-christmas.svg" style="width: 100%; height: 100%" height="16" width="16" alt="" />
    }
    @case ('uta') {
      <img ngSrc="/assets/white-labels/uta/uta.logo.svg" style="width: 80%; height: 50%" height="auto" width="auto" alt="" />
    }
    @case ('metrowest') {
      <img ngSrc="/assets/white-labels/metrowest/logo_v4.svg" style="width: 90%; height: 100%; padding-left: 5%" height="auto" width="auto" alt="" />
    }
    @default {
      <img ngSrc="/assets/icons/proficloud-logo-ds.svg" width="235" height="31" priority alt="" />
    }
  }
</container-element>
