import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { PcInputBaseComponent } from '../../base-classes/pc-input-base.component'

@Component({
  selector: 'pc-input-checkbox',
  templateUrl: './pc-input-checkbox.component.html',
  styleUrls: ['./pc-input-checkbox.component.scss'],
})
export class PcInputCheckboxComponent extends PcInputBaseComponent {
  @Output() valueChanged: EventEmitter<string> = new EventEmitter()

  @Input() control: UntypedFormControl

  @Input() placeholder: string

  @Input({ required: true }) label: string

  @Input() inputID: string

  @Input() hint: string

  @Input() disabled: boolean

  // TODO: pass these on to mat-checkbox
  // [indeterminate]
  // [checked]
  // (change)
}
