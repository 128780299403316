import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-device-online-icon',
  templateUrl: './device-online-icon.component.html',
  styleUrls: ['./device-online-icon.component.scss'],
})
export class DeviceOnlineIconComponent {
  /**Indicating Online Status. Binding to variable starting with on is not allowed in angular */
  @Input({ required: true }) liveStatus: boolean
}
