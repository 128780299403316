import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core'
import { ChosenTime } from '@services/proficloud.interfaces'

@Component({
  selector: 'pc-input-time-picker',
  templateUrl: './pc-input-time-picker.component.html',
  styleUrls: ['./pc-input-time-picker.component.scss'],
})
export class PcInputTimePickerComponent {
  @Output()
  timeChanged = new EventEmitter<ChosenTime>()

  hoursHighlighted: boolean = false
  minutesHighlighted: boolean = false
  waitingForDigits: boolean = false

  @Input()
  currentTime: ChosenTime

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      if (this.hoursHighlighted) {
        this.hoursHighlighted = false
        this.minutesHighlighted = true
      } else if (this.minutesHighlighted) {
        this.minutesHighlighted = false
      }
      event.preventDefault()
      this.timeChanged.emit(this.currentTime)
      return
    }

    if (event.key === 'ArrowLeft') {
      if (this.minutesHighlighted) {
        this.minutesHighlighted = false
        this.hoursHighlighted = true
      }
      event.preventDefault()
      this.timeChanged.emit(this.currentTime)
      return
    }

    if (this.hoursHighlighted) {
      if (event.key === 'ArrowUp') {
        if (this.currentTime.hours < 23) {
          this.currentTime.hours += 1
        }
        event.preventDefault()
        this.timeChanged.emit(this.currentTime)
        return
      }

      if (event.key === 'ArrowDown') {
        if (this.currentTime.hours > 0) {
          this.currentTime.hours -= 1
        }
        event.preventDefault()
        this.timeChanged.emit(this.currentTime)
        return
      }

      // It's a number
      const number = parseInt(event.key)
      if (!isNaN(number)) {
        if (this.waitingForDigits) {
          if (number < 3) {
            this.currentTime.hours += number
            this.waitingForDigits = false
            this.hoursHighlighted = false
            this.minutesHighlighted = true
          }
        } else {
          if (number > 1) {
            this.currentTime.hours = number
            this.hoursHighlighted = false
            this.minutesHighlighted = true
          } else {
            this.currentTime.hours = number * 10
            this.waitingForDigits = true
          }
        }
      }

      event.preventDefault()
      this.timeChanged.emit(this.currentTime)
      return
    }

    if (this.minutesHighlighted) {
      if (event.key === 'ArrowUp') {
        if (this.currentTime.minutes < 59) {
          this.currentTime.minutes += 1
        }
        event.preventDefault()
        this.timeChanged.emit(this.currentTime)
        return
      }

      if (event.key === 'ArrowDown') {
        if (this.currentTime.minutes > 0) {
          this.currentTime.minutes -= 1
        }
        event.preventDefault()
        this.timeChanged.emit(this.currentTime)
        return
      }

      // It's a number
      const number = parseInt(event.key)
      if (!isNaN(number)) {
        if (this.waitingForDigits) {
          this.currentTime.minutes += number
          this.waitingForDigits = false
          this.minutesHighlighted = false
        } else {
          if (number > 5) {
            this.currentTime.minutes = number
            this.minutesHighlighted = false
          } else {
            this.currentTime.minutes = number * 10
            this.waitingForDigits = true
          }
        }
      }

      event.preventDefault()
      this.timeChanged.emit(this.currentTime)
      return
    }

    return event
  }
}
