@if (!icons[name]) {
  <div>no such icon: "{{ name }}"</div>
}

@if (icons[name]) {
  <svg
    style="width: 100%; height: 100%"
    [attr.viewBox]="icons[name].viewBox || '0 0 24 24'"
    [attr.stroke-width]="icons[name].strokeWidth ? icons[name].strokeWidth : 2"
    [attr.fill]="icons[name].fill ? icons[name].fill : 'none'"
    [attr.stroke]="color">
    @if (icons[name].path) {
      <path [attr.d]="icons[name].path" [attr.stroke]="color" [attr.fill]="color"></path>
    }
    @if (icons[name].polygon) {
      <polygon [attr.points]="icons[name].polygon" [attr.stroke]="color" [attr.fill]="color" stroke-width="0"></polygon>
    }
    @for (polyPoints of icons[name].polylines; track polyPoints) {
      <polyline [attr.points]="polyPoints"></polyline>
    }
    <!-- multi paths -->
    <g [attr.transform]="icons[name].rotate ? 'translate(24 24) rotate(' + icons[name].rotate + ')' : ''">
      @for (path of icons[name].paths; track path) {
        <path
          [attr.d]="path.d"
          [attr.stroke]="path.stroke"
          [attr.stroke-width]="path.strokeWidth || 0"
          [attr.fill]="color"
          [attr.transform]="path.transform"></path>
      }
    </g>
    @if (icons[name].lines; as lines) {
      <g>
        @for (line of lines; track line) {
          <line
            [attr.x1]="line.x1"
            [attr.x2]="line.x2"
            [attr.y1]="line.y1"
            [attr.y2]="line.y2"
            [attr.stroke]="line.stroke || color"
            [attr.stroke-width]="line.strokeWidth"
            [attr.fill]="color"></line>
        }
      </g>
    }
    @if (icons[name].circles; as circles) {
      <g>
        @for (circle of circles; track circle) {
          <circle [attr.cx]="circle.cx || 0" [attr.cy]="circle.cy || 0" [attr.r]="circle.r || 50" [attr.fill]="circle.fill || color"></circle>
        }
      </g>
    }
  </svg>
}
