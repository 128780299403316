// angular
import { Component, Input } from '@angular/core'

// app
import { environment } from '@environments/environment'
import { EnvironmentInterface } from '@environments/environment.interface'
import { input } from 'src/app/modules/shared/decorators/input-decorator'
import { traced } from './../../../shared/decorators/trace-decorator'
import { ClassDoc } from './../../../shared/services/tracing/tracing.interfaces'
import { TracingService } from './../../../shared/services/tracing/tracing.service'

@Component({
  selector: 'app-proficloud-logo',
  templateUrl: './proficloud-logo.component.html',
  styleUrls: ['./proficloud-logo.component.scss'],
})
export class ProficloudLogoComponent {
  classDoc: ClassDoc = {
    name: 'ProficloudLogoComponent',
    location: '/src/app/modules/proficloud/components/proficloud-logo/proficloud-logo.component.ts',
  }

  @Input()
  @input({
    what: 'The style of logo',
  })
  variant: 'knockout' | 'knockout_old' | 'regular' | 'pixels' = 'knockout'

  @Input()
  @input({
    what: 'The amout of padding to be added around the logo, in percent (0-100)',
  })
  padding_pc = 20

  @Input()
  @input({
    what: 'The color of the cloud shape (only for regular style)',
  })
  color = 'darkred'

  @Input()
  @input({
    what: 'All additional styles.',
    todo: 'add type definitions',
  })
  style: string // put all the things here.

  // the proficloud shape
  cloudPath: string

  // the viewbox
  viewBox: string

  profiShape = {
    startX: 0,
    endX: 44,
    startY: 0,
    endY: 27,
  }

  dots = [
    {
      x: 14,
      y: 23,
    },
    {
      x: 21,
      y: 23,
    },
    {
      x: 28,
      y: 23,
    },
  ]

  cloud = [
    {
      x: 34,
      y: 23,
    },
    {
      x: 34,
      y: 22,
      height: 1,
    },
    {
      x: 34,
      y: 18,
    },
    {
      x: 38,
      y: 18,
      width: 2,
    },
    {
      x: 40,
      y: 18,
    },
    {
      x: 40,
      y: 16,
      height: 2,
    },
    {
      x: 40,
      y: 12,
    },
    {
      x: 40,
      y: 8,
    },
    {
      x: 38,
      y: 8,
      width: 2,
    },
    {
      x: 34,
      y: 8,
    },
    {
      x: 30,
      y: 8,
    },
    {
      x: 30,
      y: 4,
    },
    {
      x: 30,
      y: 0,
    },
    {
      x: 26,
      y: 0,
    },
    {
      x: 22,
      y: 0,
    },
    {
      x: 18,
      y: 0,
    },
    {
      x: 14,
      y: 0,
    },
    {
      x: 14,
      y: 4,
    },
    {
      x: 12,
      y: 4,
      width: 2,
    },
    {
      x: 8,
      y: 4,
    },
    {
      x: 8,
      y: 8,
      height: 2,
    },
    {
      x: 8,
      y: 10,
    },
    {
      x: 4,
      y: 10,
    },
    {
      x: 0,
      y: 10,
    },
    {
      x: 0,
      y: 14,
    },
    {
      x: 0,
      y: 18,
      height: 2,
    },
    {
      x: 0,
      y: 20,
    },
    {
      x: 4,
      y: 20,
    },
    {
      x: 8,
      y: 20,
    },
  ]

  corners: Record<string, { x: number; y: number }> = {
    a: { x: 12, y: 24 },
    b: { x: 0, y: 24 },
    c: { x: 0, y: 10 },
    d: { x: 8, y: 10 },
    e: { x: 8, y: 4 },
    f: { x: 14, y: 4 },
    g: { x: 14, y: 0 },
    h: { x: 34, y: 0 },
    i: { x: 34, y: 8 },
    j: { x: 44, y: 8 },
    k: { x: 44, y: 22 },
    l: { x: 38, y: 22 },
    m: { x: 38, y: 27 },
    n: { x: 34, y: 27 },
    o: { x: 34, y: 18 },
    p: { x: 40, y: 18 },
    q: { x: 40, y: 12 },
    r: { x: 30, y: 12 },
    s: { x: 30, y: 4 },
    t: { x: 18, y: 4 },
    u: { x: 18, y: 8 },
    v: { x: 12, y: 8 },
    w: { x: 12, y: 14 },
    x: { x: 4, y: 14 },
    y: { x: 4, y: 20 },
    z: { x: 12, y: 20 },
    aa: { x: 12, y: 24 },
  }

  env = environment as EnvironmentInterface

  constructor(public tracing: TracingService) {
    this.tracing.registerInstance(this)
    this.makeCloudPath()
    this.makeViewBox()
  }

  @traced({
    why: 'We want to set a good viewbox by cropping around the logo shape and applying user defined padding.',
  })
  makeViewBox() {
    const profiHeight = this.profiShape.endY - this.profiShape.startY
    const paddingV = profiHeight * (this.padding_pc / 100)

    const profiWidth = this.profiShape.endX - this.profiShape.startX
    const paddingH = profiWidth * (this.padding_pc / 100)

    const startX = this.profiShape.startX - paddingH
    const viewBoxWidth = profiWidth + paddingH * 2

    const startY = this.profiShape.startY - paddingV
    const viewBoxHeight = profiHeight + paddingV * 2

    const viewBox = `${startX} ${startY} ${viewBoxWidth} ${viewBoxHeight}`
    this.viewBox = viewBox
  }

  @traced({
    why: 'We want the proficloud logo shape as SVG path.',
  })
  makeCloudPath() {
    let path = ''
    Object.keys(this.corners).forEach((letter) => {
      const corner = this.corners[letter]
      if (letter === 'a') {
        path += `M ${corner.x},${corner.y} `
      } else {
        path += `L ${corner.x},${corner.y} `
      }
    })
    this.cloudPath = path
  }

  @traced({
    why: 'we might want need a random color.',
  })
  getRandomColor() {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }
}
