@if (overlayService.config) {
  <pc-overlay [config]="overlayService.config | mapOverlayConfig" (closeClick)="triggerCloseAction()" [id]="'pc-status-overlay-' + overlayService.config.type">
    <ng-container body>
      <div class="pc-status-overlay__body">
        <div class="pc-status-overlay__icon-container">
          @if (overlayService.config.type === 'success') {
            <app-icon
              class="pc-status-overlay__icon"
              name="successCircle"
              color="var(--success-green)"
              data-analytics="analytics-consequence-modal-headline-success-icon analytics-succcess"></app-icon>
          }
          @if (overlayService.config.type === 'error') {
            <app-icon
              class="pc-status-overlay__icon"
              name="error"
              color="var(--error-red)"
              data-analytics="analytics-consequence-modal-headline-success-icon analytics-error"></app-icon>
          }
          @if (overlayService.config.type === 'warning') {
            <app-icon
              class="pc-status-overlay__icon"
              name="warning"
              color="var(--warning-yellow)"
              data-analytics="analytics-consequence-modal-headline-success-icon analytics-attention"></app-icon>
          }
          @if (overlayService.config.type === 'spinner') {
            <mat-spinner [diameter]="58"> </mat-spinner>
          }
        </div>
        <span class="pc-status-overlay__message" [innerHTML]="overlayService.config.message"></span>
        @if (overlayService.config.extraInfo) {
          <span>{{ overlayService.config.extraInfo }}</span>
        }
      </div>
    </ng-container>
    <ng-container buttons>
      @if (overlayService.config.primaryAction || overlayService.config.secondaryAction) {
        <div class="pc-status-overlay__footer">
          @if (overlayService.config.secondaryAction) {
            <pc-button
              [text]="overlayService.config.secondaryAction.text"
              size="small"
              variant="secondary"
              [verifiable]="overlayService.config.secondaryAction.verifiable"
              (btnClick)="triggerSecondaryAction($event)"></pc-button>
          }
          @if (overlayService.config.primaryAction) {
            <pc-button
              [text]="overlayService.config.primaryAction.text"
              size="small"
              [verifiable]="overlayService.config.primaryAction.verifiable"
              (btnClick)="triggerPrimaryAction($event)"></pc-button>
          }
        </div>
      }
    </ng-container>
  </pc-overlay>
}
