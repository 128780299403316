<flex-col class="container">
  <div class="topBar">
    <!-- Logo -->
    <div class="topBarLeft">
      <app-proficloud-logo></app-proficloud-logo>
    </div>
  </div>

  <div class="bottom">
    <flex-col class="form">
      <div class="title">{{ 'SHARED.FORGOT_PASSWORD_TITLE' | translate }}</div>
      <div class="subTitle">{{ 'SHARED.FORGOT_PASSWORD_SUBTITLE' | translate }}</div>
      <flex-col class="inputs">
        <form [formGroup]="forgotPasswordForm">
          <div class="input">
            <pc-input-textfield [control]="forgotPasswordControl" [placeholder]="'Email'" variant="fit-width" [label]="'Email'"></pc-input-textfield>
          </div>
          <div>
            <pc-button
              [disabled]="!forgotPasswordForm.valid"
              [wide]="true"
              [text]="'SHARED.FORGOT_PASSWORD_ACTION' | translate"
              (btnClick)="submit()"></pc-button>
          </div>
        </form>
      </flex-col>
    </flex-col>
  </div>
</flex-col>
