<div [ngClass]="{ 'custom-density-2': density === 'small' }" (clickOutside)="onClickOutside()">
  @if (form | inputIsActive: config) {
    <mat-form-field
      subscriptSizing="dynamic"
      class="input-textfield input-textfield--search"
      [class.input-textfield--fit-width]="variant === 'fit-width'"
      [class.input-textfield--small]="size === 'small'">
      <mat-label>{{ placeholder }}</mat-label>
      <input
        matInput
        [id]="inputID"
        [attr.data-analytics]="analyticsID || 'text-field'"
        (input)="inputFieldChanged($event)"
        [formControl]="control"
        (click)="onInputClick()"
        (keydown)="handleKeyboardEvent($event)" />
      <app-icon class="input-textfield__icon" [class.input-textfield__icon--small]="size === 'small'" [color]="'var(--jet-black)'" name="search" matSuffix>
      </app-icon>
    </mat-form-field>
  }
</div>

<!-- Optional filterable dropdown select items -->
@if (filterableItems?.length) {
  <div #container>
    @if (filterableItems && expanded) {
      <flex-col class="content" [style.top]="contentTop" [style.left]="contentLeft" [style.minWidth]="contentWidth" [style.maxWidth]="contentWidth">
        <div #inner class="inner">
          @for (item of filterableItems | filter: ['isTrue', 'visible']; track item; let idx = $index) {
            <div class="item" (click)="itemClicked(item)" [class.preselected]="preselected === idx">
              <flex-row>
                <app-highlighted-text [text]="item.value" [highlighted]="filterCriteria"></app-highlighted-text>
              </flex-row>
            </div>
          }
        </div>
      </flex-col>
    }
  </div>
}
