import { Pipe, PipeTransform } from '@angular/core'
import { Locale, format } from 'date-fns'
import { de, enUS } from 'date-fns/locale'

@Pipe({
  name: 'dateFns',
})
export class DateFnsPipe implements PipeTransform {
  transform(value: Date, ...args: string[]): string {
    type AccetableLocales = 'en' | 'de'
    const formatString = args[0]
    const localString = args[1] as AccetableLocales
    const locales: Record<AccetableLocales, Locale> = {
      en: enUS,
      de: de,
    }
    return format(value, formatString, { locale: locales[localString] })
  }
}
